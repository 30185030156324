@mixin labels {
	font-size: 14px;
	letter-spacing: -0.28px;
}
.delivery-block-time-container {
	.delivery-block-time {
		width: 100%;
		padding: 24px 40px;
		display: flex;
		align-items: flex-end;
		color: var(--gray-600);
		border-top: 1px solid var(--gray-200);
		border-bottom: 1px solid var(--gray-200);

		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}

		.delivery-block-date,
		.delivery-block-hour-time {
			display: flex;
			flex-direction: column;
		}
		.delivery-block-date {
			.delivery-block-date-label {
				@include labels;
			}
			.delivery-block-date-input {
				height: 32px;
				width: 160px;
			}
		}

		.delivery-block-hour-time {
			margin-inline-start: 16px;

			.delivery-block-hour-time-label {
				@include labels;
			}
			.delivery-block-hour-time-input {
				height: 32px;
				width: 195px;
			}
		}

		.delivery-block-details {
			font-size: 12px;
			margin-bottom: 6px;
			.time-value {
				font-weight: 700;
				&.hours {
					margin-left: 8px;
				}
				&.minutes {
					margin-left: 4px;
				}
			}
			.time-text {
				margin-left: 2px;
			}
			.timezone-guess {
				border-left: 1px solid var(--gray-600);
				font-size: 12px;
				padding: 4px 8px;
				margin-left: 8px;
			}
		}
	}
	&.disabled {
		cursor: not-allowed;
	}
}
