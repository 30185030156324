@import '../../../../styles/globals.scss';

.delivery-block-popover {
	line-height: 1;
	z-index: 999;
	cursor: pointer;

	.ant-popover-content {
		.ant-popover-arrow {
			border-color: $black;
		}
	}

	.ant-popover-inner {
		background-image: linear-gradient(to top, $black, rgba(71, 71, 71, 1));
	}

	.ant-popover-inner-content {
		color: $white;
		padding: 5px 10px;
	}

	.capacity-counter {
		.capacity-counter-available {
			color: $white;
		}

		.capacity-counter-block-capacity,
		.capacity-counter-full {
			color: $light-blue;
		}
	}
}
