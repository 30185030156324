.delivery-blocks-toolbar {
	margin-bottom: 30px;

	.left-section {
		display: flex;
		margin-left: 67px;
		flex-direction: row;

		.day-view-filter {
			margin-right: 16px;
			width: 210px;
			margin-left: -36px;

			input {
				height: 32px;
				margin: 0;
			}
		}
	}

	.right-section {
		display: flex;
		margin-right: 16px;

		.view-mode-selection {
			margin-right: 16px;
			background: #ffffff;
			box-sizing: border-box;
			border-radius: 4px;
			width: 128px;
		}
	}
}
