.company-card-edit {
	.company-card-edit-button {
		text-align: right;

		button {
			&:first-child {
				margin-left: auto;
			}

			margin-left: 10px;
		}
	}

	.company-card-edit-input {
		margin-bottom: 15px;

		.company-card-edit-row {
			display: flex;
			align-items: center;

			.company-card-value {
				.with-loading,
				.ant-select {
					width: 100%;
				}
			}
		}
	}

	.status-icon {
		right: 10px;
		bottom: 3px;
	}
}
