.deliveryWindows {
	height: 95vh;
	font-family: 'Open Sans', sans-serif;
	background: #fff;
	padding: 0px 2px 0 25px;
}

.filtersPanel {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.editButton {
	color: var(--primary-500);
}
