.web-user-interface {
	margin-top: 35px;
	.controller-container {
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		&.align-start {
			align-items: flex-start;
			.bringg-checkbox {
				padding-left: 0;
			}
		}
		.controller-title {
			width: 280px;
			margin-right: 10px;
			&.align-center {
				display: flex;
				align-items: center;
			}
		}
		.controller-select {
			width: 200px;
		}
		.info-icon {
			margin-inline-start: 8px;
			font-size: 16px;
		}
	}
	.btn-update {
		margin-top: 30px;
	}
}

.hybrid-fleet-warning-modal {
	.modal-title {
		display: flex;
		align-items: center;

		.warning-icon {
			color: var(--warning-600);
			margin-inline-end: 8px;
			font-size: 25px;
		}
	}
	.warning-body {
		.warning-link {
			margin-inline-start: 6px;
		}
	}
}
