@import '../../styles/globals.scss';

.fleets-configuration {
	display: grid;
	grid-template-columns: minmax(0, auto);

	.rule-form-modal {
		background-color: $white-background-color;
	}

	.header {
		font-weight: 500;
		font-size: 24px;
		color: black;
	}

	.bringg-tabs {
		.fleets-tabs {
			& > .ant-tabs-nav {
				background: $white-background-color;
				padding: 0 40px;
				box-shadow: 0 0 1px rgba(0, 51, 80, 0.08), 0 2px 4px rgba(0, 51, 80, 0.16);
			}

			& > .ant-tabs-content-holder {
				padding: 16px;
			}
		}

		.ant-tabs-content-holder {
			max-height: inherit;
		}
	}
}

.rate-cards,
.conditions-set {
	.ant-collapse-item {
		.ant-collapse-expand-icon > span > svg {
			transform: rotate(90deg);
		}

		&.ant-collapse-item-active {
			.ant-collapse-expand-icon > span > svg {
				// !important used because element has inline styles(from lib js logic)
				transform: rotate(-90deg) !important;
			}
		}
	}
}
