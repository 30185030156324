@import '../../../styles/globals';

.conditions-set {
	padding: 24px;
	margin: 0 0 24px 0;
	width: 100%;
	display: flex;
	flex-direction: column;

	.title {
		font-size: 24px;
		font-weight: 500;

		.fleet-name {
			font-weight: 400;
		}
	}

	.header {
		font-weight: inherit;

		.title {
			font-size: 18px;
			font-weight: initial;
		}

		.editable-input {
			font-size: 18px;
		}
	}

	.description {
		color: $secondary-text;
		font-size: 14px;
	}

	.collapse-all-button {
		margin-left: auto;
		margin-bottom: -24px;
		padding-right: 0;
	}

	.conditions-rules-list {
		.rule-container {
			background: $white-background-color;
			border-radius: 5px;
			margin: 24px 0;
			box-shadow: 0 1px 2px 0 $box-shadow-color;

			input[type='number'] {
				margin-bottom: 0;
				height: 32px;
			}
		}
	}

	.conditions-footer {
		display: flex;
		flex-direction: row;
		padding: 40px 24px;

		.add-button {
			margin-right: auto;
			padding-left: 0;
		}

		.discard-button {
			margin-right: 8px;
		}
	}

	.non-collapsable {
		.header {
			cursor: auto;
		}
	}
}

.conditions-container {
	.ant-input {
		margin-bottom: 0;
	}

	.ant-input:disabled {
		background-color: $disabled-background;
	}
}
