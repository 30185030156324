.disableMultiSelect {
	:global(.ant-tree-checkbox) {
		pointer-events: none;
		&:global(.ant-tree-checkbox-indeterminate),
		&:global(.ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled)) {
			:global(.ant-tree-checkbox-inner) {
				background-color: var(--gray-500);
				border-color: var(--gray-500);
			}
		}
	}
}
