.overrides {
	:global {
		.ant-modal-header.ant-modal-header {
			padding: 24px;
		}

		.ant-modal-body.ant-modal-body {
			padding: 24px 24px 32px 24px;
		}

		.ant-modal-footer.ant-modal-footer {
			padding: 0 24px 24px 24px;

			.ant-btn.ant-btn-dangerous.ant-btn-primary {
				color: #fff;
				background-color: var(--danger-500);
			}
		}
	}
}

.title {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}

.subtitle {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.content {
	padding-top: 8px;
}
