@import '../../../../styles/globals';

.preview-rate-modal.ant-modal {
	height: 100%;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;

	.ant-modal-header {
		filter: drop-shadow(0px 1px 2px rgba(0, 51, 80, 0.16));
		padding: 16px 50px 16px 24px;
	}

	.preview-rate-modal-title {
		display: flex;
		align-items: center;
		color: $gray-800;
		font-size: $font-big;
		font-weight: $font-weight-semi-bold;
	}

	.ant-modal-body {
		flex-grow: 2;
		padding: 24px;
	}

	.preview-items-wrapper {
		// 85px Summary header
		// 24 + 8 = top + bottom padding of modal body
		max-height: calc(100vh - 85px - 25px - 8px);
		overflow-y: auto;
	}

	.bringg-collapse ~ .bringg-collapse {
		margin-top: 32px;
	}

	.bringg-collapse {
		border-radius: 5px;
		overflow: hidden;

		.ant-collapse-item .ant-collapse-header {
			border-radius: 5px 5px 0 0;
			padding: 16px 16px 16px 16px;
			background-color: #fff;
			font-size: $font-pre-medium;
			color: var(--gray-800);
			border-bottom: 1px solid var(--gray-300);

			sub {
				bottom: 0;
			}
		}

		.ant-collapse .ant-collapse-content {
			padding-top: 0;
			background-color: #fff;
			color: var(--gray-600);
			border-left: 5px solid var(--gray-600);
		}

		&.surcharge-fee-collapse .ant-collapse .ant-collapse-content {
			border-left-color: #a0aec0;
		}
	}

	.ant-modal-content {
		display: flex;
		height: 100%;
		flex-direction: column;
		border-radius: 0;
		background-color: var(--gray-50);
	}

	.rate-preview-item,
	.empty-collapse-text {
		font-size: $font-pre-medium;
		display: flex;
		padding: 16px;
		border-bottom: 1px solid var(--gray-300);
	}

	.empty-collapse-text {
		justify-content: center;
	}

	.rate-preview-item {
		&.inactive {
			color: var(--gray-400);
		}

		.term-title {
			@include ellipsis_text;
			margin-right: 10px;
			flex: 5;
		}

		.term-rate {
			@include ellipsis_text;
			flex: 3;
		}
	}
}
