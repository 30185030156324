@import '../../styles/globals.scss';
@import 'repeat/recurring-repeat';
@import 'frequencies/recurring-frequencies';

.recurring {
	width: 100%;

	.recurring-frequency-select {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.select-wrapper {
			width: 67%;
		}

		.disabled {
			.ant-select-selection-selected-value {
				color: $gray;
			}
		}
	}

	label {
		color: $gray;
		display: flex;
		align-items: center;
	}
}
