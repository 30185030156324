.fleet-rules-section {
	.rules-list-content {
		max-height: unset;
		height: calc(100vh - 75px);
	}

	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.header-title {
		font-size: 17px;
		font-weight: 400;
		margin-bottom: 4px;
	}

	.header-description {
		font-size: 15px;
		margin-top: 8px;
		color: #848484;
	}

	.conditions-set {
		padding: 24px 0;
	}

	.title {
		font-size: 18px !important;
	}

	.fleet-add-button {
		cursor: pointer;
	}

	.fleet-add-button:disabled {
		background-color: #ccc;
		cursor: not-allowed;
	}

	.fleet-add-button:not(:disabled):hover {
		background-color: #0056b3;
	}

	.rule-body-container {
		position: relative;
	}

	label {
		font-weight: 400;
	}

	.ant-radio-wrapper {
		color: grey;
	}

	@media (max-width: 768px) {
		.header-container {
			flex-direction: column;
			align-items: flex-start;
		}

		.add-button {
			margin-top: 10px;
			align-self: flex-end;
		}
	}
}
