@import '../../../styles/globals.scss';

.values-list {
	@include basic_font_props;

	.value-row {
		cursor: pointer;
		margin-top: 5px;
	}

	.draggable-icon,
	.delete-icon {
		margin-top: 25px;
		color: $gray;
	}
}
