@import '../../../styles/globals.scss';

.icon-button {
	&.ant-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		background: transparent;

		&:active,
		&:hover,
		&:focus {
			background: transparent;
		}

		svg {
			font-size: 26px;
			color: $primary;
		}

		&.favorite-button {
			box-shadow: none;

			span {
				line-height: 0;
			}

			.bringg-icon {
				font-size: 24px;
				color: $primary;
			}
		}
	}
}
