.popoverContent {
	width: 380px;
	max-height: 152px;
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		.title {
			font-size: 14px;
			font-weight: bold;
			color: var(--gray-800);
			text-overflow: ellipsis;
			max-width: 260px;
			overflow: hidden;
			white-space: nowrap;
		}

		.actions {
			.gray600 {
				color: var(--gray-600);
			}
		}
	}

	.popoverContentBody {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: space-around;

		.services {
			display: flex;
		}

		.tag {
			margin-top: 4px;
			background: var(--primary-100);
			color: var(--primary-400);
			border: 0;
			border-radius: 12px;
			font-weight: bold;
		}
	}
}
