.vrp-auto-dispatch-metric-container {
	color: white;
	background: transparent;

	.ant-collapse-content > .ant-collapse-content-box {
		padding: 9px;
		border-top: 1px dashed #969696;
	}

	svg {
		fill: white;
	}
}

.vrp-auto-dispatch-metric-panel {
	background: rgb(82, 82, 82);

	.ant-collapse-header {
		line-height: 5px;
		padding: 0;
	}
}

.vrp-auto-dispatch-metric-panel-header {
	display: flex;
	color: white;
	font-size: 12px;
	padding: 12px 0 12px 20px;
}

.warn {
	background: #cc3131;
}

.vrp-auto-dispatch-analytics-last-run {
	padding-right: 4px;
}

.vrp-auto-dispatch-analytics {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: white;
	font-size: 13px;

	h3 {
		color: white;
		font-weight: bold;
		margin: 0;
		font-size: 12px;
	}
}

.vrp-auto-dispatch-analytics-metrics {
	display: flex;
	justify-content: center;
}

.vrp-auto-dispatch-analytics-last-updated {
	color: #c9c9c9;
	margin-top: 10px;
	text-align: center;
	font-size: 10px;
}

.vrp-auto-dispatch-analytics-hours-select {
	display: flex;
	padding: 0 10px 18px;

	.ant-select-selection--single {
		background: #525252;
		border-top: 0;
		border-right: 0;
		border-left: 0;
		border-bottom: 1px solid white;
		border-radius: 0;
		color: white;
	}
	p {
		padding: 5px 10px 0 0;
	}
}

.vrp-auto-dispatch-spinner {
	display: flex;
	justify-content: center;
}

.metric-unit-container {
	min-height: 70px;
	min-width: 170px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.right-metric-unit {
	border-left: 1px solid #969696;
	padding: 0 10px 0 10px;
	width: 50%;
}

.left-metric-unit {
	padding: 0 10px 0 10px;
	width: 50%;
}

.no-statistics-available {
	font-size: 14px;
	font-weight: 400;
	color: #b3b3b3;
	text-align: center;
}

.metric-unit-combined-metric {
	padding-bottom: 20px;
}
