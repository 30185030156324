@import '../../styles/globals';

.fleet-assigner-modal-footer-buttons {
	display: flex;
	align-items: center;

	> .right-buttons {
		margin-left: auto;
	}

	.error-content {
		margin-right: 8px;
		flex: 1;

		display: flex;
		text-align: left;

		color: var(--danger-600);

		.error-icon {
			margin-right: 8px;
		}
	}
}
