@import '../../../styles/globals';

.vehicle-container {
	@include basic_font_props;
	@import '../vehicle-card/vehicle-card';
	@import '../assignments/assignments-container/assignment-container';

	background-color: $light-gray-background;
	display: flex;
	height: 100vh;

	.vehicle-card-container {
		width: 33%;
		margin: 22px 15px 23px 8px;
		height: calc(100% - 40px);
		border-radius: 4px;
	}

	.driver-card-container {
		width: 16.3%;
		text-align: center;
		margin: 22px 6px 0px 16px;
		height: 293px;

		.driver-details-wrapper {
			flex-direction: column;
			background-color: $background-secondary-color;
			padding: 0;

			.driver-image {
				margin-right: 0;
			}

			.driver-name {
				margin-top: 2px;
				font-size: $font-medium;
			}

			.driver-rating {
				margin-top: 7px;
			}
		}

		.driver-title {
			@include basic_border;
			height: 54px;
			padding-top: 15px;
		}

		.ant-avatar {
			top: -10px;
			width: 73px;
			height: 76px;
			border-radius: 37px;
		}
	}

	.assignment-history-container {
		margin: 22px 0px 23px 0px;
		height: 100%;
		width: 50%;
	}

	.table-wrapper {
		border: none;
		width: 100%;
	}
}
