.relative-break-data {
	display: flex;
	align-items: center;

	.relative-break-icon {
		font-size: 20px;
		color: var(--gray-700);
		margin-inline-end: 4px;
	}
	& + .break-location-option {
		margin-top: 12px;
	}

	.range-error {
		border: 1px solid var(--danger-500);
	}
}
