@import '../../styles/globals.scss';

.ant-calendar-prev-year-btn:after,
.ant-calendar-next-year-btn:after {
	display: none;
}

.ant-picker-range {
	input {
		text-align: center;
	}
}
