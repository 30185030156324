.chat {
	@import './chat-list/chat-list';
	@import './chat-window/chat-window';

	font-family: Rubik, sans-serif;

	.chat-wrapper {
		height: 90vh;
		margin-top: 15px;
		overflow: hidden;
	}

	.ant-col-5 {
		height: 100%;
		padding-right: 15px;
	}

	.ant-col-12 {
		height: 100%;
	}

	.center {
		position: absolute;
		left: 50%;
		top: 50%;
	}
}
