.employeeList {
	&:empty {
		// To take some space while react render the portal
		width: 100px;
	}

	.hide {
		// alternative for `ng-show` in angular.js
		display: none !important;
	}

	// First row - search abd filters
	.employeesHeader {
		border-top: none;
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;

		.searchDrivers {
			width: fit-content;
			align-items: baseline;
			max-width: 300px;
		}
	}

	.employeeListItemWrapper {
		padding: 0;

		.listItem {
			padding: 5px;

			display: flex;
			align-items: center;

			&.selected {
				background: linear-gradient(to bottom, #5190db 0, #4175d2 100%);

				.employeeName {
					color: white;
				}
			}

			.employeeNameAndProfile {
				.employeeDetailsWrapper {
					min-width: 190px;
					white-space: nowrap;
					overflow: hidden;
					margin-right: 15px;
					text-overflow: ellipsis;

					.avatar {
						width: 20px;
						height: 20px;
						vertical-align: top;

						@media (max-width: 767px) {
							& {
								display: none !important;
							}
						}
					}

					.employeeName {
						min-width: 150px;
						display: inline-block;
						margin-left: 4px;
					}
				}
			}
		}
	}

	.employeeExtraData {
		font-size: 13px;
		color: #808080;
		padding: 0px 10px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.withMargin {
		margin-right: auto;
	}

	.employeeVehicle {
		font-size: 13px;
		color: #808080;
		padding: 0px 10px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.employeeIconsBackground {
		background-color: inherit;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: center;

		.employeeBeaconHomeIconsWrapper {
			margin-left: 0px;
			margin-right: 8px;

			.beaconHomeIcon {
				height: 20px;
				width: 20px;
				margin-top: -5px;
			}
		}
	}
}
