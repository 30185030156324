@import '../../../styles/globals.scss';

.question-data {
	margin-top: 5px;

	.add-answer {
		margin-top: 10px;
		color: $light-blue;
		cursor: pointer;
	}

	.question-input {
		width: 100%;
	}

	.go-to-select,
	.answer-input {
		width: 220px;
	}
}
