@import '../../../../styles/globals';

.route-optimization-percentage-input {
	height: 80px;

	.route-optimization-percentage-input-error-icon,
	.route-optimization-percentage-input-error-text {
		color: var(--danger-600);
	}

	.percentage-input-prompt {
		margin-top: 6px;
		margin-left: 4px;
	}

	.percentage-input {
		width: 200px;
	}
}
