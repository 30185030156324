@import '../../styles/mixins.scss';

.draggableItem {
	display: grid;
	height: $row-height;
	padding-left: 16px;
	border-radius: 4px;
	min-width: 0;
	grid-template-columns: 20px 1fr;
	align-items: center;

	&:hover {
		background: var(--gray-100);
	}

	&.fadeOut {
		animation: fadeOut;
		animation-delay: 0.15s;
		animation-duration: 0.3s;
	}

	&.fadeIn {
		animation: fadeIn;
		animation-duration: 0.3s;
	}

	&.dragging {
		background-color: var(--gray-50);
		opacity: 0.8;
	}

	&.dragDisabled {
		cursor: default;

		.checkBox {
			cursor: default;
		}
	}

	.checkBox {
		color: var(--color-brand-gray);
		overflow: hidden;

		:global(.ant-checkbox + span) {
			@include truncate;
		}

		:global(.ant-checkbox-wrapper-disabled) {
			cursor: pointer;
		}

		:global(.ant-checkbox-disabled + span) {
			cursor: inherit;
			color: var(--color-brand-gray);
		}
	}
}
