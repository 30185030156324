.break-item {
	margin-bottom: 4px;
	display: flex;
	flex-direction: column;
	height: 66px;

	&.relative-break {
		height: 108px;
	}

	.bringg-input-number {
		margin: 0 4px;
	}

	.break-item-at {
		margin-right: 4px;
	}

	.flex {
		display: flex;
	}

	.margin-12 {
		margin-top: 12px;
	}

	.align-items-center {
		align-items: center;
	}

	.column-direction {
		flex-direction: column;
	}

	.break-item-data {
		display: flex;
		align-items: center;
		height: 40px;

		&.relative-break-mode {
			align-items: unset;
			flex-direction: row;

			& + .break-item-errors {
				margin-top: 42px;
			}
		}

		.break-type,
		.break-length,
		.break-location-option,
		.break-range-wrapper {
			margin-inline-end: 16px;
		}

		.break-type,
		.break-length,
		.break-location-option {
			height: 32px;
			width: 120px;
		}

		.break-range-wrapper {
			.break-error-icon {
				color: var(--danger-500);
			}
		}

		.break-range {
			height: 32px;
			width: 195px;
			margin-inline-end: 4px;

			&.range-error {
				border: 1px solid var(--danger-500);

				.ant-picker-suffix,
				.ant-picker-separator {
					color: var(--danger-500);
				}

				&.ant-picker-focused {
					box-shadow: 0 0 0 2px var(--danger-100);
				}
			}
		}

		.break-delete {
			color: var(--gray-600);
			margin-inline-start: auto;
			display: none;

			.bringg-icon {
				font-size: 14px;
			}
		}

		&:hover {
			.break-delete {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.break-item-errors {
		margin-top: 4px;
		color: var(--danger-500);
	}
}
