.delivery-block-modal-wrapper {
	@import '../../../styles/modal-with-collapse';
	.ant-select {
		max-width: 158px;
	}
	&.with-areas {
		.bringg-modal .ant-modal-content {
			width: 1140px;
			.head-row {
				overflow: hidden;
			}
			.ant-select {
				max-width: 142px;
			}
		}
	}
}

.dropdown-is-open .disable-when-select-is-open {
	pointer-events: none;
}
