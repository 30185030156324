.cutoffRadioGroup {
	height: max-content;
}
.cutoffTitle {
	margin-top: 28px;
	font-weight: 600;
}
.radioGroup {
	display: flex;
	flex-direction: column;

	.radio {
		margin-top: 12px;

		.radioContent {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: normal;
			font-size: 12px;
		}
	}
}
