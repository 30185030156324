.spinner {
	position: absolute;
	z-index: 5;
	top: 50%;
	right: 50%;
}

.disabledMode {
	opacity: 0.5;
	pointer-events: none;
}

.exclusionSlotBackground {
	background: repeating-linear-gradient(
		135deg,
		var(--gray-300),
		var(--gray-300) 2px,
		var(--gray-100) 2px,
		var(--gray-100) 14px
	);
}
.exclusionEvent {
	background: var(--gray-300) !important;
	color: var(--gray-700);

	&:global(.rbc-selected) {
		background: var(--gray-300);
	}
}
.event {
	background: var(--primary-500);
	color: var(--white-bg);

	&:global(.rbc-selected) {
		background: var(--primary-500);
	}

	&.selectedEvent {
		background: var(--primary-600);
	}
}
.calendar {
	:global {
		.rbc-selected {
			&.exclusionEvent {
				background: var(--gray-300);
			}
		}

		.rbc-time-header-cell-single-day {
			display: flex;
		}

		.rbc-time-view {
			border: 0;
			max-height: 85vh;
			overflow: auto;
		}

		.rbc-time-view .rbc-allday-cell {
			display: none;
		}

		.rbc-time-header {
			position: sticky;
			top: 0;
			z-index: 5;

			.rbc-time-header-gutter {
				background: var(--white-bg);
				border-top: 1px solid var(--gray-300);
				border-bottom: 1px solid var(--gray-300);
			}
		}
		.rbc-time-header-content {
			border: 1px solid var(--gray-300);
			border-right: 0px;

			.rbc-header {
				border-bottom: 0;
			}
		}

		.rbc-time-content > * + * > * {
			border-left: 1px solid var(--gray-300);
		}

		.rbc-day-slot .rbc-time-slot {
			border-bottom: 1px;
			border-top: 0px;
		}

		.rbc-time-gutter {
			background: var(--white-bg);

			.rbc-timeslot-group {
				padding: 8px;
				border-bottom: 1px solid var(--gray-300);
			}
		}

		.rbc-current-time-indicator {
			display: none;
		}
		.rbc-time-content {
			background: white;
			border-bottom: 1px solid var(--gray-300);
			border-top: 0px;
			border-radius: 0px;
		}

		.rbc-event-label {
			display: none;
		}

		.rbc-day-slot .rbc-event {
			border: 1px solid white;
		}

		.rbc-today {
			background: transparent;
		}

		.rbc-header {
			color: var(--gray-700);
			background: white;
			padding: 10px 0;
		}
	}
}
