@import 'event/delivery-blocks-event-content';
@import 'event/delivery-blocks-event';

.delivery-blocks-calendar {
	$navigation-height: 62px;
	height: calc(100% - #{$navigation-height});
	width: 100%;
	align-self: center;
	padding: 0 30px;

	// hide current time indicator
	.rbc-current-time-indicator {
		display: none;
	}

	// hide default timing label
	.rbc-event-label {
		display: none;
	}

	.rbc-time-view {
		border: none;
	}

	.rbc-time-slot {
		border-top: none;
	}

	.rbc-header {
		border: none;
		font-size: 16px;
		margin-bottom: 15px;
	}

	.rbc-allday-cell {
		display: none;
	}

	.rbc-day-bg + .rbc-day-bg {
		border: none;
	}

	.rbc-header + .rbc-header {
		border: none;
	}

	.rbc-time-header-content {
		border: none;
	}

	.rbc-events-container {
		background-color: rgba(169, 169, 169, 0.19);
	}

	// hide current day color marking
	.rbc-today {
		background-color: transparent;
	}

	.rbc-time-header.rbc-overflowing {
		border: none;
	}

	.rbc-time-content {
		border: none;
	}

	.rbc-day-bg {
		border: none;
	}

	.rbc-day-b {
		border: none;
	}

	.rbc-time-content > * + * > * {
		border-left: none;
	}

	.rbc-header > a {
		color: rgba(0, 0, 0, 0.65);
	}

	.rbc-toolbar-label {
		display: none;
	}
}
