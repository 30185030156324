@import '../../../../styles/globals.scss';

.route-optimization-settings-wrapper {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
}

.optimization-settings-collapse {
	.ant-collapse-content.ant-collapse-content-active {
		padding-top: 0;
	}

	.collapse-icons {
		transform: scale(1.5);
		color: var(--gray-600);
		display: inline-block;
	}

	.ant-collapse-header-text > span {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		display: inline-block;
		padding-left: 10px;
		align-items: flex-end;
	}

	.ant-collapse-item,
	.ant-collapse-item:last-child {
		border-bottom: 1px solid #d9d9d9;
	}
}

.route-optimization-settings-form {
	overflow-y: auto;
	width: 1100px;

	@media screen and (max-width: 1600px) {
		width: 700px;
	}

	@media screen and (max-width: 1200px) {
		width: auto;
	}

	&-body {
		padding: 24px;
		background: white;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&-footer {
		width: 1100px;
		height: 64px;
		background: white;
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;
		padding: 8px 24px;

		button {
			width: 180px;
			height: 48px;
			font-size: 18px;
		}

		& .route-optimization-settings-submit {
			margin-left: 16px;
		}
	}

	.input-title {
		margin-left: 0;
		margin-right: 2px;
		font-weight: 600;
	}

	.required-field::before {
		top: 35%;
		font-weight: normal;
	}
}

.route-optimization-settings-form-footer {
	@media screen and (max-width: 1600px) {
		width: 700px;
	}
}

.route-optimization-field-container {
	padding-left: 40px;
	padding-bottom: 15px;

	&.sub-field {
		padding-left: 65px;
		animation: fadeToTransparent 2s forwards;
	}
}

@keyframes fadeToTransparent {
	from {
		background-color: #cce9ff;
	}
	to {
		background-color: transparent;
	}
}
