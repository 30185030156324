@import '../../styles/globals';
@import '../../styles/theme.scss';

.timeline-tools {
	display: flex;
	background: $white;
	width: $timeline-icons-width;

	.timeline-icons {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.timeline-icon {
		margin-top: 15px;
		color: var(--gray-600);
		cursor: pointer;
		font-size: $font-large;
	}
	.zoomIcon {
		padding: 4px;
	}
}
