.breaks-panel {
	border-bottom: 1px solid var(--gray-200);
	.panel-title {
		display: flex;
		align-items: center;

		.panel-title-icon {
			color: var(--gray-600);
			font-size: 24px;
		}

		.panel-title-translate {
			margin-inline-start: 8px;
			letter-spacing: -0.32px;
			font-weight: 600;
			font-size: 16px;
			color: var(--gray-700);
		}
	}

	.breaks-items {
		display: flex;
		flex-direction: column;
		padding: 0 24px;
	}

	.add-break-button {
		margin-inline-start: 24px;
	}

	.breaks-items.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
