// variables
$white: #ffffff;
$blue1: #3091ce;
$blue2: #e1f0fd;
$blue3: #0077a6;
$blue4: #6fb2dd;
$gray1: #f9fafd;
$gray2: #e1e7ee;
$gray3: #a0aec0;
$gray4: #edf5fd;
$gray5: #cbd5e0;
$gray6: #5b6771;
$green: #35cd7c;

$filter_content_width: 432px;

//css

.delivery-hub {
	&.singleton {
		padding: 15px 0;
	}

	&-backtop {
		button {
			background-color: $blue4 !important;
			border: none;
			width: 48px;
			height: 48px;
		}
	}
}

//
.delivery-catalog-list {
	padding-top: 12px;

	&-found {
		padding-bottom: 24px;
		color: $gray3;
	}

	&-content {
		overflow: hidden;
		padding-bottom: 12px;
	}
}

//
.delivery-catalog-card {
	width: 294px;
	box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
	background-color: $white;
	border-top: 6px solid $blue3;
	border-radius: 4px;
	overflow: hidden;

	&-body {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60px;
		padding: 4px 0;

		img {
			max-height: 100%;
			max-width: 90px;
		}
	}

	&-name {
		text-align: center;
		color: $gray5;
		margin-bottom: 4px;
	}

	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 30px;
		margin-left: 4px;

		.favorite-button {
			svg {
				margin-top: 8px;
			}
		}
	}

	&-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 35px;
		background-color: $gray1;
		padding: 0 8px;

		&-icons {
			display: flex;

			span {
				display: inline-flex;
				margin-right: 4px;
			}
		}

		button {
			color: $blue1;
		}
	}
}

.delivery-catalog-details {
	&-drawer {
		.ant-drawer-content-wrapper {
			height: 100%;
			overflow: auto;
			bottom: 0;
		}

		.ant-drawer-content {
			border-top: 6px solid $blue3;

			.ant-drawer-close {
				margin-right: 4px;
			}

			.anticon-close {
				font-size: 18px;
			}

			.ant-drawer-header {
				border-bottom: none;
				padding: 12px;

				.ant-drawer-header-title {
					flex-direction: row-reverse;
				}
			}

			.ant-drawer-body {
				padding-top: 10px;
			}
		}
	}

	&-header {
		display: flex;
		align-items: center;

		.connected-tag {
			margin-left: 14px;
		}
	}

	&-soon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 36px;
		margin-top: 16px;
		background-color: $gray4;
		color: $gray3;
		font-size: 16px;
	}

	&-image {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		h2 {
			margin-top: 16px;
			font-size: 16px;
			color: var(--gray-700);
		}

		img {
			max-width: 100%;
		}
	}

	&-divider {
		margin-top: 16px;
		margin-bottom: 32px;

		.ant-col {
			height: 4px;

			&:nth-child(1) {
				background-color: #f6b1cf;
			}

			&:nth-child(2) {
				background-color: #ff4422;
			}

			&:nth-child(3) {
				background-color: #3176a2;
			}
		}
	}

	&-data {
		margin-bottom: 24px;
		line-height: normal;

		&:last-of-type {
			margin-bottom: 0;

			.ant-divider {
				display: none;
			}
		}

		h3 {
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			padding-bottom: 3px;
			color: var(--gray-700);
		}

		.ant-btn-link {
			font-weight: 600;
			color: $blue1;
			padding: 0;
			height: auto;
		}

		ul {
			padding-left: 18px;
			margin-bottom: 0;
		}

		.delivery-catalog-details-data-content {
			margin: 0;
			color: var(--gray-600);
		}
	}
}

//
.delivery-catalog-filter-bar {
	padding: 12px 16px;
	background-color: $white;
	border-radius: 8px;
	box-shadow: 0 1px 2px rgba(144, 161, 181, 0.16);

	&-small-filter {
		width: 50%;
	}

	&-tags {
		padding-left: 0 !important;
		padding-right: 0 !important;

		.ant-row {
			display: flex;
			align-items: center;
			height: 57px;
			margin-top: -12px;
			margin-bottom: -12px;
		}
	}

	.delivery-catalog-filter-bar-tag {
		cursor: pointer;
		border-radius: 16px;
		background-color: $blue2;
		border-color: $blue2;
		color: $blue1;

		&:last-of-type {
			margin-right: 0;
		}
	}

	label {
		font-weight: normal;
	}

	&-content {
		width: $filter_content_width - 32px;

		label {
			font-weight: normal;
		}
	}

	label {
		font-weight: normal;
	}

	.filter-buttons {
		.ant-form-item {
			margin-bottom: 0;
		}
	}
}

.delivery-catalog-details-popover {
	background-color: #f2f6f8;
	max-height: 384px;
	border-radius: 3px;
	overflow: auto;

	ul {
		padding: 6px 6px 6px 24px;
		margin: 0;
		color: #2d3748;
	}
}

.delivery-catalog-email-modal {
	.email-input {
		margin-bottom: 0;
	}
	.email-description {
		color: var(--gray-600);
	}
}

.connected-tag {
	background-color: $green;
	color: $white;
}

.delivery-catalog-details {
	&-drawer {
		.ant-drawer-footer {
			padding: 24px;
		}

		.ant-drawer-content-wrapper {
			height: 100%;
			overflow: auto;
			bottom: 0;
		}

		.ant-drawer-content {
			border-top: 6px solid $gray6;

			.ant-drawer-close {
				z-index: 30;
			}

			.anticon-close {
				font-size: 18px;
			}
		}
	}

	&-button.ant-btn {
		background: var(--primary-400);
		height: 48px;
		font-size: 18px;
	}

	&-tags {
		position: absolute;
		z-index: 10;
		display: flex;
		align-items: center;
		height: 50px;
		top: 7px;
		left: 0;
		padding-left: 24px;

		span {
			display: flex;
			margin-right: 8px;
		}
	}

	&-head {
		height: 140px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		box-shadow: 0 2px 4px 0 rgba(0, 51, 80, 0.16), 0 0 1px 0 rgba(0, 51, 80, 0.08);

		img {
			display: block;
			max-height: 52px;
			max-width: 90px;
		}

		h3 {
			margin-left: 10px;
			margin-top: 0;
			margin-bottom: 0;
			font-size: 16px;
			color: var(--gray-700);
		}

		&-border {
			position: absolute;
			bottom: 0;
			background-color: #ff4422;
			height: 4px;
			width: 130px;
			left: 50%;
			transform: translateX(-50%);

			&:before,
			&:after {
				position: absolute;
				display: block;
				content: '';
				width: 130px;
				height: 4px;
				top: 0;
			}

			&:before {
				left: calc(-100% - 16px);
				background-color: #f6b1cf;
			}

			&:after {
				right: calc(-100% - 16px);
				background-color: #3176a2;
			}
		}
	}

	&-body {
		position: absolute;
		top: 140px;
		left: 0;
		bottom: 97px;
		width: 100%;
		overflow: auto;
		padding-left: 24px;
		padding-right: 24px;
	}

	&-icon {
		display: inline-flex;
		width: 24px;
		height: 24px;
		font-size: 24px;
		margin-right: 8px;
	}

	&-section {
		//outline: 1px solid red;
		padding-bottom: 25px;
		padding-top: 25px;

		border-bottom: 1px solid #eff4f8;
		font-family: 'Open Sans', sans-serif;

		h4 {
			display: flex;
			align-items: center;
			margin: 0;
			color: #4a5568;
			font-weight: 600;
			font-size: 16px;

			strong {
				font-weight: normal;
				font-size: 14px;
				color: #718096;
			}

			& + p,
			& + h4,
			& + ul,
			& + .delivery-catalog-details-item {
				margin-top: 16px;
			}
		}

		p {
			margin: 0;
			color: #718096;
		}

		ul {
			margin: 0;
			padding-left: 15px;
			color: #718096;

			li {
				font-family: 'Open Sans', sans-serif;
			}
		}

		&:last-child {
			border-bottom: 0;
		}
	}

	&-item {
		display: inline-flex;
		align-items: center;
		color: #718096;

		svg {
			min-width: 24px;
		}

		&-block {
			display: flex;

			& + & {
				margin-top: 8px;
			}
		}
	}

	&-expander {
		margin-top: 16px;

		&-header {
			display: block;
			height: auto;
			padding: 0;
			background: transparent !important;
			color: var(--primary-400) !important;

			span {
				margin-inline-start: 0 !important;
			}

			.bringg-icon {
				margin-left: 4px;
				font-size: 16px;
			}
		}

		&-hide {
			display: block;
			height: auto;
			padding: 0;
			margin-top: 4px;
			color: var(--primary-400) !important;
			background: transparent !important;
		}

		&-body {
			margin-top: 4px;
		}
	}
}
