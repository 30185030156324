.modalTitle {
	font-size: 20px;
}

.nameInput,
.effectiveDates,
.teamSelect,
.serviceAreasDropdown,
.servicePlanDropdown,
.teamIdsDropdown,
.capacityInput,
.exclusionTypeDropdown {
	width: 340px;
}

.nameInput,
.effectiveDates,
.serviceAreas,
.servicePlan,
.teamIds,
.capacity,
.exclusionType {
	display: flex;
	flex-direction: column;
	margin-top: 16px;

	.dangerText {
		color: var(--danger-500);
		font-size: 12px;
		margin-inline-start: 5px;
	}
}

.effectiveDates.dangerText + .serviceAreas {
	margin-top: 10px;
}

@mixin label-text {
	font-size: 12px;
	color: var(--gray-700);
	margin-bottom: 2px;
}
.labelText {
	@include label-text;
}
.labelTextRequired {
	@include label-text;

	&:before {
		content: '* ';
		color: red;
	}
}

.expandList {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	cursor: pointer;

	.expandListText {
		padding: 5px 12px 5px 16px;
		color: var(--gray-500);
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1;

		&:hover {
			color: var(--primary-500);
		}

		.showMoreIcon {
			color: var(--gray-600);
		}
	}
}
