.delivery-block-timeline-tooltip {
	display: flex;
	flex-direction: column;
	width: 230px;
	height: 68px;
	padding: 16px;
	background-color: #333333;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;

	.text {
		font-size: 13.5px;
		color: #ffffff;
	}

	& + .timeline-item-tooltip-arrow.arrow-bottom::before {
		border-top: 18px solid #333333 !important;
	}
}
