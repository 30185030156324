.delivery-blocks-settings-container {
	margin-left: 20px;
	padding-top: 20px;
	height: 100%;

	.delivery-blocks-settings-tabs-container {
		height: 100%;
	}

	.delivery-blocks-settings-title {
		font-size: 20px;
		font-weight: 600;
		font-family: 'Open Sans', sans-serif;
		width: fit-content;
	}

	.delivery-blocks-settings-tabs {
		height: 100%;
		.ant-tabs-content-holder {
			max-height: 100%;
		}

		.delivery-blocks-settings-tab {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}

	.delivery-blocks-schedules-tab-container {
		width: 70%;
		display: flex;
		flex-direction: column;

		.delivery-blocks-schedules-create-schedule {
			background-color: var(--primary-400);
			color: white;
			width: fit-content;
			margin-left: auto;
		}

		.delivery-blocks-schedules-filter-bar {
			margin-top: 16px;
		}

		.delivery-blocks-schedules-count-title {
			margin-top: 10px;
		}
	}
}
