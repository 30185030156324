@import '../../styles/globals';

.new-user-modal {
	.modal-header {
		margin-top: -18px;
	}

	.iti {
		width: 100%;

		input {
			min-height: 35px;
		}
	}

	.admin-form,
	.dispatcher-form,
	.driver-form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-right: 0;

		.ant-select-single .ant-select-selector {
			color: $gray-8;
		}

		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
			height: 33px;
		}

		.with-loading .status-icon {
			display: none;
		}

		input[type='ant-input-affix-wrapper'].floating-input {
			border: none;
		}

		.sub-header {
			color: $g-600;
		}

		.ant-input-password {
			height: 35px;
			font-size: 15px;
		}

		.email-input-field-alert {
			width: calc(100% - 16px);
			margin: 12px 0;
		}
	}

	.ant-modal-footer {
		display: flex;
		justify-content: space-between;
	}

	.divider-margin {
		margin-bottom: 30px;
	}

	button.save-and-create {
		min-width: 213px;
		height: 40px;
		gap: 4px;
		padding: 8px 24px;
		border-radius: 4px;
		background-color: #e1f0fd;

		span {
			height: 40px;
			flex-grow: 0;
			font-size: 16px;
			line-height: 1.5;
			text-align: center;
			color: #3091ce;
		}
	}

	h3,
	h4 {
		color: $g-700;
	}

	label {
		font-weight: normal;
		margin-bottom: 1px;
		color: $g-700;
	}

	.ant-radio-button-wrapper {
		color: $p-400;
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		border-color: $p-400;
		color: $p-500;
		background-color: $p-50;
	}

	h4 {
		font-size: 16px;
	}

	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px #fff inset;
	}

	button.ant-btn-primary[disabled].ant-btn[disabled] {
		background-color: #dbe1eb;
	}
	button.button.ant-btn-primary[disabled].ant-btn[disabled].save-disabled {
		background-color: #cbd5e0;
	}

	button.save-and-create-disabled {
		min-width: 213px;
		height: 40px;
		flex-grow: 0;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 4px;
		padding: 8px 24px;
		border-radius: 4px;
		background-color: #dbe1eb;

		span {
			width: 165px;
			height: 24px;
			flex-grow: 0;
			font-size: 16px;
			text-align: center;
			color: #fafbfc;
		}
	}

	.ant-btn.ant-btn-primary.save {
		min-width: 213px;
		height: 40px;
		gap: 4px;
		padding: 8px 24px;
		border-radius: 4px;
		background-color: #3091ce;

		span {
			flex-grow: 0;
			font-size: 16px;
			color: #fff;
		}
	}

	button.save-disabled {
		width: 213px;
		height: 40px;
		flex-grow: 0;
		// display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 4px;
		padding: 8px 24px;
		border-radius: 4px;
		background-color: #cbd5e0 !important;

		span {
			flex-grow: 0;
			font-size: 16px;
			text-align: center;
			color: #fff;
		}
	}

	.ant-modal-body {
		overflow: auto;
	}

	.ant-collapse-content-box {
		overflow: hidden;
	}

	.modal-body {
		overflow: auto;
		max-height: 616px;

		div.ant-divider {
			border-top: 1px solid #b7b7b7;
			padding-right: 0;
		}

		.ant-form-item-label {
			width: 100%;
			text-align: left;
		}

		.ant-form-item {
			display: inline-flex;
			margin-right: 16px;
			width: calc(50% - 16px);
			margin-bottom: 10px;

			.ant-row {
				display: block;
				flex: 1;
			}
		}

		.ant-collapse-header {
			text-align: right;
		}

		.ant-collapse-content-box {
			padding: 0;
		}

		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border: 1px solid #cdd6e4;
			border-radius: 4px;
		}

		.driver-form {
			.ant-collapse-header {
				color: #3091ce;
			}
		}

		.ant-modal-body input {
			margin-bottom: 8px;
		}

		.ant-select,
		.ant-input-affix-wrapper.antd-country-phone-input {
			padding: 0;
			min-height: 35px;
			border-radius: 4px;
		}

		.ant-input-affix-wrapper.antd-country-phone-input {
			height: 35px;
		}

		.antd-country-phone-input {
			.ant-input {
				margin-bottom: 0;
				line-height: 35px;
			}

			.ant-select-selection-item {
				line-height: 35px;
			}
		}

		.ant-select-selector {
			min-height: 35px;
		}

		.driver-form-collapse {
			text-align: right;

			.ant-collapse-header {
				display: inline-block;
				padding-top: 20px;
				margin-top: -8px;
			}

			.ant-collapse-content {
				text-align: left;
			}
		}

		.full-width {
			width: 100%;
		}
	}

	.ant-form-item-explain > div:not(:first-child) {
		display: none;
	}
}
