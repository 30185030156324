@use 'sass:math';
@import '../../styles/globals';

$height: 32px;

.dispatch-map-mapbox-spinner {
	margin-top: 50px;
	text-align: center;
}

.dispatch-map-team-marker {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 700;
}

.dispatch-map-driver-marker {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	color: $black;

	.dispatch-map-driver-marker-title {
		width: max-content;
	}

	.dispatch-map-driver-marker-circle {
		$size: 14px;
		$circle-factor: 2;

		position: relative;
		width: $size;
		height: $size;
		border-radius: 50%;

		&:before {
			content: '';
			position: absolute;
			top: -1 * math.div($size, $circle-factor);
			left: -1 * math.div($size, $circle-factor);
			width: ($size * $circle-factor);
			height: ($size * $circle-factor);
			background: inherit;
			opacity: 0.5;
			border-radius: inherit;
		}
	}
}

.dispatch-map-marker-icon {
	font-size: 48px;
}

.time-to-base-container {
	position: absolute;
	z-index: 3;
}
