@import '../../styles/automation-card-mixin.scss';

.workflowLink {
	// Allow highlighting text but not dragging as the card is a link
	user-select: text;
	-webkit-user-drag: none;
}

.workflowCard {
	@include automation-card;

	min-width: 800px;
	max-width: 1100px;
	min-height: 148px;
	margin-left: auto;
	margin-right: auto;

	cursor: pointer;

	&.startAsHighlighted {
		animation: highlightAnimation 2s linear forwards;
	}

	@keyframes highlightAnimation {
		0% {
			outline: 2px solid var(--primary-400);
		}

		100% {
			outline: 0 solid transparent;
		}
	}

	&.workflowRenderError {
		cursor: unset;
		justify-content: center;
		align-items: center;
		font-size: 18px;

		.warningIcon {
			font-size: 48px;
			color: var(--danger-500);
		}
	}

	*:not([data-ignore-open-workflow]) {
		cursor: pointer;
	}

	*[data-ignore-open-workflow='all'],
	*[data-ignore-open-workflow='children'] * {
		cursor: auto;
	}
}

.viewMode {
	background-color: var(--gray-50);
}

.readOnly {
	cursor: auto;
	.content {
		color: var(--gray-600);
	}
}

.header {
	@include automation-header;
}

.title {
	@include automation-title;
}

.actions {
	@include automation-actions;
}

.content {
	@include automation-content;
	width: fit-content;
	align-items: center;

	.showLess {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.footer {
	@include automation-footer;

	display: flex;
	font-size: 12px;
	line-height: 20px;
	color: var(--gray-600);
}
