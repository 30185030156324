$space-from-top: 40px;

.bringg-modal {
	top: $space-from-top;

	.ant-modal-content {
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		overflow: auto;
		max-height: calc(100vh - $space-from-top * 2);

		.ant-modal-header {
			padding: 36px 48px 16px;
		}

		.ant-modal-body {
			padding: 8px 48px 8px;

			.ant-collapse {
				.ant-collapse-item {
					.ant-collapse-header {
						display: flex;
						padding: 12px 40px 16px 16px;

						.ant-collapse-arrow {
							right: 16px;
							left: auto;
						}

						.ant-collapse-extra {
							margin-inline-start: auto;
						}
					}
				}
			}

			.ant-collapse {
				background: #fff;

				.collapse-chevron {
					color: var(--grey-600);
				}

				.ant-collapse-item {
					border-bottom: 1px solid #e1e9f0;
				}
			}
		}
	}
}
