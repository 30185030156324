.employeeCategoryFilter {
	margin-left: auto;
	width: fit-content;

	.select {
		color: var(--primary-400);
	}

	.dropdownContainer {
		// In order to fix the bug that when the list items gets larger the drop down container is not in the right place
		position: absolute;
	}
}
