.delivery-blocks-schedules-container {
	.delivery-blocks-schedules-table {
		padding-bottom: 10px;
		margin-top: 10px;

		.bringg-table-row {
			padding: 8px 0;

			.schedule-menu {
				transform: rotate(90deg);
				color: var(--gray-800);
			}
		}

		.table-header-text {
			margin-top: 6px;
		}

		.delivery-blocks-schedules-title {
			font-size: 13px;
			font-weight: 600;
			color: var(--gray-800);
			font-family: 'Open Sans', sans-serif;
		}

		.delivery-blocks-schedules-name-container {
			.delivery-blocks-schedules-name-chervon {
				width: 60px;
				visibility: visible;

				&.empty-group {
					visibility: hidden;
				}
			}

			.delivery-blocks-schedules-name {
				font-size: 13px;
				font-weight: 400;
				color: var(--primary-400);
			}
		}
	}
}

.template-schedule-menu-button {
	min-width: 100px;
	padding: 0;

	.template-schedule-menu-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0;
	}
}

.template-schedule-menu-button.disabled {
	pointer-events: none;

	.schedule-menu-icon,
	.schedule-menu-title {
		color: var(--gray-800);
	}
}
