@import '../../../../styles/globals';

.recurring-frequency-weekly {
	width: 67%;

	&.ant-checkbox-group {
		display: flex;
		justify-content: space-between;
		> .ant-checkbox-group-item:last-child {
			margin: 0;
		}
	}

	.ant-checkbox-checked {
		span {
			background-color: $light-blue;
			border-color: $light-blue;
		}
	}

	.ant-checkbox-group-item {
		width: 25px;
		position: relative;
		margin-right: 6px;

		.ant-checkbox-inner {
			width: 26px;
			height: 26px;
		}

		.ant-checkbox {
			&.ant-checkbox-checked {
				&.ant-checkbox-disabled {
					span {
						background-color: $gray-2;
					}
				}
			}
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner:after {
				-webkit-transition: none;
				-moz-transition: none;
				-ms-transition: none;
				-o-transition: none;
				transition: none;
				display: none;
			}
		}

		> span:last-child {
			top: 2px;
			left: -4px;
			position: absolute;
			z-index: 2;
		}
	}

	.ant-checkbox-wrapper-checked {
		> span:last-child {
			color: #fff;
		}
	}
}
