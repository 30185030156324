.service-area-map-autocomplete {
	position: absolute;
	left: 70px;
	width: 236px;
	z-index: 1;
	top: 4px;

	&:not(.ant-select-customize-input) .ant-select-selector {
		.ant-select-selection-search-input {
			height: 26px;
		}
		.ant-select-selection-placeholder {
			line-height: 26px;
		}
	}

	.ant-select-selector {
		height: 26px;
	}
}

.service-area-map {
	height: 100%;
	position: relative;
}
