.container {
	display: grid;
	grid-template-rows: 40px 1fr;
	width: 370px;
	margin: 10px 0;
	overflow-y: auto;
}

.emptyColumn {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.emptyMessage {
		background-color: var(--gray-100);
		padding: 15px 20px;
	}
}
