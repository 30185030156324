@import '../../../styles/globals.scss';
.vehicle-card {
	font-size: $font-medium;
	@include basic_border;
	padding: 23px 15px 20px 20px;
	height: 100%;

	.vehicle-header {
		color: $black;

		.vehicle-name-header {
			font-size: $font-large;
			font-weight: bold;
		}

		.vehicle-card-actions {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	}

	.license-plate-header {
		font-size: $font-small;
	}

	.card-button {
		cursor: pointer;
		display: flex;
	}

	.cancel-button {
		color: $red;
		right: -25px;
		font-size: $font-small;
	}

	.cancel-button,
	.save-button {
		box-shadow: none;
		background-color: transparent;
		border: none;
		position: absolute;
		top: -72px;
	}

	.edit-button,
	.save-button {
		color: $second-color;
		font-size: $font-small;
	}

	.edit-button-icon {
		color: $second-color;
		margin-inline-end: 2px;
	}

	.delete-button {
		margin-right: 10px;
		color: $red-danger;
		font-size: $font-small;
	}

	.delete-button-icon {
		color: $red-danger;
		margin-inline-end: 2px;
	}

	.save-button {
		right: 30px;
	}

	// edit icon
	.svg-inline--fa {
		margin-right: 6px;
		width: 13px;
	}

	.vehicle-card-form-container {
		position: relative;

		.vehicle-card-form {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}
	}

	.header-separated-line {
		margin-top: 17px;
		margin-bottom: 10px;
		border-top-width: 1px;
		width: 100%;
	}

	label {
		margin: 0;
		font-size: $font-small;
		color: $gray;
		font-weight: normal;
		font-size: $font-xs;
	}

	hr {
		margin: 0;
		margin-bottom: 8px;
	}

	input:disabled {
		background-color: transparent;
		border: none;
	}

	.form-input {
		&:not(.ant-select) {
			height: 25px;
		}

		color: $black;
		padding: 0;
		width: 90%;
	}

	.ant-form-item-label {
		height: 28px;
		color: $gray;
		margin: 0;
		font-size: $font-xs;
		font-weight: normal;
		text-align: left;
	}

	.ant-select-selection-placeholder {
		color: $black;
		font-weight: normal;
	}

	.ant-select-selection__rendered {
		margin: 0;
	}

	.ant-select-disabled .ant-select-selector {
		border: none;
		background: transparent;
		color: $black;
		font-weight: normal;
	}

	.ant-form-item-required:before {
		display: none;
	}

	.ant-col-12 {
		height: 70px;
	}

	.ant-form-explain {
		margin-top: -10px;
	}
}
