@import '../../../../styles/globals';

.delivery-catalog-connect-data-setup {
	> .delivery-catalog-connect-body {
		display: flex;
		flex-direction: column;
		padding: 32px;
	}

	h1 {
		margin: 0;
		margin-bottom: 8px;
		font-family: 'Open Sans', sans-serif;
		font-size: 16px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: -0.32px;
		color: #2d3748;
	}

	p {
		margin: 0;
	}

	.header-container {
		margin-bottom: 24px;

		.header-top {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-content: flex-start;
			justify-content: space-between;
			align-items: flex-start;

			span {
				font-family: 'Open Sans', sans-serif;
				font-size: 14px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.71;
				letter-spacing: -0.28px;
				text-align: center;
				color: #3091ce;
			}
		}

		p {
			font-family: 'Open Sans', sans-serif;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.64;
			letter-spacing: normal;
			text-align: left;
			color: #4a5568;
		}
	}

	.link-icon {
		width: 22px;
		height: 22px;
		background: url('../../../../images/link-icon.svg');
		margin-right: 3px;
	}

	.external-link-icon {
		width: 10px;
		height: 10px;
		background: url('../../../../images/external-link-icon.svg');
	}

	.right-icon {
		width: 24px;
		height: 24px;
		background: url('../../../../images/right-icon.svg');
	}

	.link-container {
		margin-left: 8px;
		display: flex;
		flex-direction: row;
		position: relative;
		align-items: flex-end;
		width: max-content;

		&.external-link {
			align-items: flex-start;
		}

		p {
			font-family: 'Open Sans', sans-serif;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.71;
			letter-spacing: -0.28px;
			text-align: center;
			color: #3091ce;
		}
	}

	.order-id {
		margin-bottom: 24px;

		.ant-select-selector {
			padding: 0 12px;
			width: 153px;
			border: solid 1px #d7e0e9;
		}

		.ant-select-arrow {
			color: #718096;
		}

		> p {
			margin-bottom: 4px;
			font-family: 'Open Sans', sans-serif;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.71;
			letter-spacing: -0.28px;
			text-align: left;
			color: #718096;
		}

		&-select {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}

	.task-fields {
		.task-fields-table-container {
			margin-bottom: 40px;
		}

		h1 {
			margin-bottom: 16px;
		}

		h1:last-child {
			margin-top: 40px;
		}

		thead > tr:first-child th {
			background-color: #eff4f8;
			padding: 8px;
		}

		thead > tr:first-child th.ant-table-cell:before {
			background-color: #cbd5e0 !important;
			height: 1.3em !important;
		}

		thead > tr:first-child th:first-child {
			border-top-left-radius: 8px;
		}

		thead > tr:first-child th:last-child {
			border-top-right-radius: 8px;
		}

		.ant-table-cell {
			font-family: 'Open Sans', sans-serif;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.85;
			letter-spacing: -0.26px;
			color: #4a5568;
		}

		tbody > tr td {
			background-color: #ffffff;
			padding: 8px 8px 8px 16px;
			border-top: 1px solid #e1e9f0;
			border-bottom: 1px solid #e1e9f0;

			.ant-table-cell {
				text-align: center;
			}
		}

		td.column-data-availability.ant-table-cell {
			text-align: center;
		}

		.ant-collapse {
			border: none;
		}

		.ant-collapse-header {
			padding: 0px 8px;
			background-color: #f8fafb;
			display: flex;
			align-items: center;
		}

		.ant-collapse-content-box {
			padding: 0;
		}

		.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
			padding: 0;
			top: 10px;
			left: 12.5px;
			color: #718096;
		}

		.panel-container {
			display: flex;
			flex-direction: row;
			align-items: center;

			p {
				font-family: 'Open Sans', sans-serif;
				font-size: 13px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.46;
				letter-spacing: normal;
				text-align: left;
				color: #4a5568;
			}
		}

		.bringg-tag.data-availability {
			padding: 0 8px;
			font-family: 'Open Sans', sans-serif;
			font-size: 12px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
		}

		.bringg-tag.data-availability {
			padding: 0 8px;
			font-family: 'Open Sans', sans-serif;
			font-size: 12px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
		}

		.empty-text {
			display: flex;
		}
	}

	.no-order {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
		flex-grow: 1;

		h1 {
			font-family: 'Open Sans', sans-serif;
			font-size: 22px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.09;
			letter-spacing: -0.66px;
			color: #1e2634;
		}

		p {
			font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: -0.48px;
			text-align: center;
			color: #718096;
		}
	}
}
