$row-height: 40px;

.enum-definition {
	display: flex;
	flex-direction: column;

	&-header {
		display: flex;

		&-item {
			background-color: var(--gray-200);
			font-weight: 500;
			color: var(--gray-600);
			padding: 4px 8px;

			&.value-col {
				border-top-left-radius: 4px;
				width: 30%;
				min-width: 100px;
			}
			&.name-col {
				border-top-right-radius: 4px;
				flex: 1;
			}
		}
	}

	&-list {
		max-height: $row-height * 3;
		min-height: $row-height;
		overflow: auto;

		&-item {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #cdd6e4;
			font-size: 13px;

			&.dragging {
				background: lighten(#e8ecf3, 5%); // --gray-200
				span {
					opacity: 0;
				}
			}

			input[type='text'] {
				width: 100%;
				border: none;
				background-color: unset;
				outline: none;
				box-shadow: none;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0;
				padding: 0;
			}

			.value-col {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				padding: 4px 8px;
				width: 30%;
				min-width: 100px;

				.bringg-icon.bringg-icon-drag {
					display: inline-flex;
					padding-top: 2px;
					margin-right: 4px;
					cursor: grab;
				}
			}

			.name-col {
				padding: 4px 8px;
				flex: 1;
			}

			&:hover {
				background-color: var(--gray-200);
			}

			.bringg-icon {
				color: var(--gray-500);
			}
		}
	}

	&-add-new {
		padding-top: 4px;

		.bringg-btn {
			transition: none;
			padding-left: 8px;

			&:focus {
				outline: solid var(--primary-100) 1px;
			}

			span {
				margin-left: 4px;
			}
		}
	}

	&-errors {
		color: #ff4d4f;
	}
}

.remove-enum-item-popover .ant-tooltip-arrow .ant-tooltip-arrow-content {
	background-color: #555555;
	--antd-arrow-background-color: unset;
}
