@import '../../../styles/globals';

.actions-data-header {
	padding-top: 10px;
	padding-bottom: 10px;
	border-width: 0 0 1px;
	border-color: $light-gray;
	border-style: dashed;
	margin-bottom: 5px;

	.delete-button {
		background-color: $light-red-2;
		color: white;
		border-radius: 3px;
		width: 120px;
		height: 30px;
		margin-top: 15px;
		margin-left: auto;
	}
}
