.run-editor-modal-error-container {
	display: flex;
	align-items: center;
	background-color: #ffdede;
	padding: 4px 12px;
	border-radius: 4px;

	.run-editor-modal-error-icon {
		color: #f98560;
		font-size: 20px;
	}
	.run-editor-modal-error-message {
		font-size: 12px;
		margin-inline-start: 5px;
	}
}
