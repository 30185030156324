.deliveryBlockTemplateMainContainer {
	align-self: center;
	.deliveryBlockTemplateMain {
		display: flex;
		flex-direction: column;
		padding: 50px;
		align-items: start;
		background-color: white;
		width: 1100px;

		.deliveryBlockTemplateTime {
			padding: 24px 0;
		}
	}

	.deliveryBlockTemplateFooter {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 20px;
		background-color: white;
		width: 1100px;

		.deliveryBlockTemplateDiscardChangesButton {
			margin-right: 10px;
		}
	}
}
