.delivery-block-modal-header {
	.delivery-block-name,
	.delivery-block-description {
		color: var(--gray-500);
	}

	.delivery-block-name {
		font-size: 20px;
		font-weight: 700;
		letter-spacing: -0.6px;
	}

	.delivery-block-description {
		font-size: 12px;
		letter-spacing: -0.12px;
	}
}
