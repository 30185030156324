.templatesManager {
	margin: 46px 16px;
	font-family: 'Open Sans', sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;

	.emptyState {
		display: flex;
		flex-direction: column;
		align-items: center;

		&Message {
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 28px;
		}

		img {
			margin-bottom: 32px;
		}
	}
}
