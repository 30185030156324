.draggableItem {
	display: grid;
	margin-bottom: 8px;
	cursor: move;
	min-width: 0;
	grid-template-columns: 20px 1fr;
	align-items: center;

	.dragDisabled {
		cursor: pointer;
	}

	&.dragging {
		background-color: var(--gray-200);
		> svg,
		label {
			opacity: 0;
		}
	}

	:global(.item-checkbox-label) {
		font-weight: normal;
		color: var(--color-brand-gray);
		min-width: 0;

		:global(.ant-checkbox-wrapper-disabled) {
			cursor: pointer;

			:global(.ant-checkbox-disabled + span) {
				cursor: inherit;
				color: inherit;
			}
		}
	}
}
