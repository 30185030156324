@import '../../../../styles/globals';

.route-optimization-configurations-list {
	width: 1100px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.route-optimization-list-top-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 32px;
		margin-bottom: 16px;

		.route-optimization-generic-actions {
			button {
				padding-top: 0;
				padding-bottom: 0;
				height: 24px;
				margin-right: 8px;

				&:not(:disabled) {
					color: $gray-700;
					background-color: $white;
					border: 1px solid $gray-200;
				}
			}
		}
	}

	.route-optimization-filterbar {
		width: 100%;
	}
	.route-optimization-settings-table {
		width: 100%;

		thead > tr > th {
			font-size: 13px;
		}

		td {
			max-width: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.route-optimization-default-edit {
			padding-left: 9px;
		}

		.route-optimization-settings-empty-table {
			text-align: center;
			margin: 40px 0 20px 0;

			.route-optimization-create-new-description {
				margin-top: 20px;
			}

			.route-optimization-create-new {
				font-weight: 600;
			}
		}
	}

	.route-optimization-menu-horizontal {
		background: inherit;
		transition: none;
		color: var(--gray-600);
	}
	.route-optimization-menu-horizontal:hover {
		background: inherit;
	}
	.route-optimization-default-merchant-tag {
		margin-left: 10px;
	}
	.route-optimization-teams-count {
		background: var(--primary-50);
		border-radius: 13px;
		span {
			color: var(--primary-400);
			font-weight: 400;
			font-size: 12px;
		}
	}
	.route-optimization-no-teams-merchant-level {
		width: 5%;
		border-top: 0.1px solid;
		border-color: black;
		margin-left: 0;
	}
}

.route-optimization-dropdown-menu > div > ul > li > span > div {
	i {
		margin-right: 5px;
		transform: scale(1.2);
		color: var(--gray-600);
	}
	span {
		font-weight: 400;
		font-size: 14px;
	}
}
