@import '../../../../styles/globals.scss';

.table-wrapper {
	.ant-table {
		border: none;

		.ant-table-thead {
			th {
				background-color: transparent;
				color: $black;
			}
		}
	}

	.driver-column {
		color: $second-color;
		cursor: pointer;
		padding-right: 90px;
	}

	.ant-table-pagination.ant-pagination {
		float: left;
		margin-top: 30px;
		margin-left: 10px;
	}

	.ant-pagination-item,
	.ant-pagination-item-link {
		border-radius: 4px;
		font-size: $font-xs;
	}

	.ant-pagination.mini {
		li {
			@include basic_border;
			margin-left: 10px;
			width: 28px;
			height: 26px;
		}

		.ant-pagination-total-text {
			font-size: $font-small;
			border: none;
			position: absolute;
			top: -60px;
			right: 10px;
			color: $black;
			width: 150px;
		}
	}
}
