.loading {
	opacity: 0.6;
	pointer-events: none;
}

.calendar {
	:global {
		.rbc-allday-cell {
			display: none;
		}

		.rbc-time-view .rbc-header {
			border-bottom: none;
		}

		.rbc-time-header {
			border-right: none;
		}

		.rbc-header {
			background: var(--white-bg);
			color: var(--gray-700);
			font-size: 14px;
			font-weight: 400;
			padding: 10px 0;
		}

		.rbc-time-content {
			border-top-width: 1px;
		}

		.rbc-time-header-gutter {
			align-items: center;
			background-color: var(--white-bg);
			display: flex;
			line-height: 1;
			padding-right: 8px;
			text-align: right;
		}

		.rbc-time-gutter {
			background: var(--white-bg);
			.rbc-timeslot-group {
				min-width: 72px;
				padding: 4px 4px 8px 8px;
			}
		}
	}
}
