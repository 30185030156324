@import '../../../../styles/globals';

.two-state-toggle-title {
	width: 42px;
	height: 24px;
	font-weight: 600;
	color: $g-700;
	margin-left: 4px;
	margin-right: 2px;
}

.two-state-toggle-radio-group {
	border: 1px solid var(--gray-200);
	border-radius: 8px;
	span {
		font-weight: 400;
		letter-spacing: -0.02em;
		color: $g-700;
	}
	label {
		padding-top: 1px;
	}
	label.ant-radio-button-wrapper-checked {
		background: var(--gray-100);
		background-color: var(--gray-100);

		span {
			color: var(--primary-500);
		}
	}
	label:first-child {
		border-radius: 4px;
		margin: 4px 0.5px 4px 4px;
		border: none;
	}
	label:nth-child(2) {
		border-radius: 4px;
		margin: 4px 4px 4px 0.5px;
		border: none;
	}

	.ant-radio-button-wrapper-checked:not(
			[class*=' ant-radio-button-wrapper-disabled']
		).ant-radio-button-wrapper:first-child {
		background-color: var(--gray-100);
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
		background-color: transparent;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		border-color: $gray-200;
	}
	.ant-radio-button-wrapper:focus-within {
		box-shadow: none;
	}
	.ant-radio-button-wrapper:not(:first-child):before {
		width: 0px;
	}
}
