.manualTriggerModal {
	color: var(--gray-800);

	.title {
		font-weight: 600;
	}

	.tasksList {
		color: var(--gray-600);
	}

	.actionButton {
		margin-inline-start: auto;
	}

	.footerButtons {
		display: flex;
	}
}
