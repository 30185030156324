@import '../../../../styles/globals.scss';

.assignments {
	@import '../drives-filter/drivers-filter';
	@import '../assignments-table/assignments-table';

	@include basic_border;
	font-size: $font-medium;
	padding: 24px;
	height: calc(100% - 40px);

	.assignments-title {
		font-size: $font-large;
		color: $black;
		font-weight: 300;
	}

	.driver-button {
		cursor: pointer;
		float: left;
		color: $second-color;
		border-radius: 5px;
		padding: 2px;
		margin-right: 10px;
		font-size: $font-small;
		margin-top: 15px;
	}

	.ant-calendar-picker-icon {
		left: 5px;
		right: unset;
		color: $second-color;
	}

	.ant-calendar-range.ant-calendar-time {
		display: none;
	}

	.ant-picker-range {
		height: 28px;
		min-width: 230px;
		border-color: $second-color;
		margin-top: 17.3px;
		margin-left: 5px;
		background-color: transparent;
		input,
		.ant-picker-suffix {
			color: $second-color;
		}
	}

	.ant-calendar-range-picker-separator {
		display: none;
	}

	.ant-calendar-prev-month-btn {
		border: none;
	}

	.calendar-placeholder {
		position: absolute;
		margin-top: 23px;
		width: 190px;
		color: $second-color;
		margin-left: 30px;
	}

	.calendar-arrow-icon {
		float: right;
		height: 10px;
		width: 12px;
		margin-right: -10px;
		margin-top: 3px;
	}

	.ant-calendar-range-picker-input {
		padding-left: 8px;
	}
}
