@import './styles/theme.scss';
@import './components/group-headers/group-headers.scss';
@import './components/group-row-cells/group-row-cells.scss';
@import './components/remove-group-button/remove-group-button.scss';

.timeline-drop-container {
	height: 100%;

	.timeline-container {
		@include timeline_font_props;
		height: 100%;
		display: flex;

		.timeline {
			flex: 1;
		}
	}
}

.gantt-item {
	cursor: pointer;
	color: white;

	&.editable-false {
		opacity: 0.5;
	}

	&.late {
		@include late();
	}
}

.vis-item {
	&.vis-background {
		.item-text {
			visibility: hidden;
		}
	}

	.vis-item-content {
		width: 100%;
		font-size: 12px;
		word-break: break-all;
		white-space: normal !important;
		min-height: 30px;
		display: inline-flex !important;
	}
}

.vis-itemset {
	.vis-item {
		min-width: 27px;
	}
}

.ant-notification {
	z-index: 2000;
}

.loadingGroup {
	color: rgba(0, 0, 0, 0.5);
}

.gray-out {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	background-color: rgba(45, 55, 72, 0.2);
}

// horizontal Zebra stripes in left panel:
.vis-panel {
	.vis-labelset {
		.vis-label {
			background-color: white;
		}
	}
}

.vis-time-axis.vis-foreground {
	background: $timeline-axis-color;
	box-shadow: $timeline-axis-box-shadow;

	.vis-text.vis-minor {
		border-right: 1px solid $timeline-axis-scale-lines-color;
	}

	.vis-text {
		color: $timeline-axis-text-color;
	}
}

.vis-label {
	// align row content in center
	display: flex;
	align-items: center;
	flex: 1;
}

.vis-itemset {
	.vis-item {
		color: $items-text-color;
	}
}

.vis-group {
	&.error-group {
		background-image: url('images/red-zebra.svg');
		background-repeat: repeat;
	}
}
