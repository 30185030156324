.task-reject-reasons-card {
	background-color: var(--white-bg);
	padding: 24px 16px 16px;

	.task-reject-reasons-card-title {
		margin: 0;
	}
	.task-reject-reasons-card-explanation {
		margin-top: 4px;
		margin-bottom: 16px;
	}
	.task-reject-reasons-items {
		display: flex;
		flex-direction: column;
		.task-reject-reasons-items-header,
		.task-reject-reasons-item {
			.task-reject-reasons-content {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 40px;
				.task-reject-reasons-id {
					padding-left: 8px;
					width: 68px;
				}
			}
			border-bottom: 1px solid var(--gray-200);
		}
		.task-reject-reasons-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			&:hover {
				background: var(--gray-200);
			}
			.task-reject-reasons-delete-container {
				align-items: center;
				width: 40px;
				.task-reject-reasons-delete {
					transition: opacity 200ms;
					&.hide-delete-button {
						opacity: 0;
					}
					.bringg-icon-trash {
						color: var(--gray-500);
					}
				}
			}
			.edit-title-icon {
				color: var(--gray-500);
			}
		}
		.task-reject-reasons-items-header {
			background-color: var(--gray-100);
			font-weight: 500;
		}
	}
	.task-reject-reasons-add {
		margin-top: 8px;
	}
}
