@import '../../../styles/globals.scss';

.actions-configurations-page {
	.actions-configurations-container {
		@include basic_font_props;
		padding-left: 15px;
		padding-right: 15px;
		font-size: $font-xs;
	}

	.legend-container {
		margin-top: 80px;
		padding-bottom: 100px;
		width: 100%;
		max-width: none;
		border-radius: 4px;

		.description {
			font-size: $font-xs;
		}

		.title {
			font-weight: $font-weight-medium;
		}

		.sub-title {
			white-space: pre-line;
			line-height: 1.5;
		}
	}

	.variable-legend-container {
		position: sticky;
		top: 70px;
	}
}
