.logContainer {
	color: var(--gray-800);
	width: 750px;
	padding: 16px 0 32px 0;
}

.logHeader {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	.headerTitle {
		margin-left: 6px;
		font-size: 20px;
		font-weight: 600;
		flex: 1;
	}
	.iconContainer {
		color: #ff9900;
		font-size: 24px;
	}
}

.subHead {
	margin-left: 32px;
	font-size: 12px;
	margin-bottom: 20px;
}

.teamRowsContainer {
	margin-left: 32px;
	overflow: auto;
	max-height: 255px;
	.teamRow {
		display: flex;
		align-items: center;
		.textInSentence {
			margin-left: auto;
		}
	}
	.teamScheduleMissingResource {
		margin-left: 40px;
		.missingCount {
			font-weight: 600;
		}
	}
}
