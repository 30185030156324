.timeoff-modal {
	.timeoff-form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 150px;

		.required-field {
			position: relative;

			&:before {
				content: '*';
				position: absolute;
				top: 50%;
				bottom: 0;
				color: red;
				transform: translateY(-50%);
				left: -15px;
			}
		}

		.range-picker {
			width: 100%;

			.ant-picker-input {
				input {
					text-align: center;
					margin-bottom: 0;
				}
			}
		}

		.repeat-yearly-container {
			display: flex;
			justify-content: space-between;
		}
	}
}
