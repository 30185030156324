@import '../../../../styles/globals';
@import './breaks/breaks-card';

.delivery-block-card {
	padding: 25px;

	.delivery-block-info {
		display: flex;
		margin-bottom: 5px;

		label {
			line-height: 40px;
			color: $gray;
			margin: auto 0;
			width: 33%;
		}

		.info {
			margin: auto 0;
			color: $black;
		}
	}
}
