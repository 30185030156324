@import '../../../styles/globals.scss';

.actions-configuration-container {
	@include basic_font_props;

	.action-data-input {
		min-width: 200px;
		width: 200px;
		height: 30px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.red-border {
		border-color: red;
	}
	.required-field {
		color: red;
		margin-top: 5px;
	}
	.flex {
		display: flex;
	}

	input {
		border-style: solid;
		border-color: $light-gray;
	}

	.ant-select-selector {
		height: 100%;
	}

	.actions-data-list {
		min-height: 700px;
	}

	h6 {
		z-index: 1000;
		background-color: $background-secondary-color;
	}
}
