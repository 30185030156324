@import '../../../styles/globals';

.user-types-header {
	margin-bottom: $default-margin;
	text-align: right;

	.add-user-type {
		background-color: $blue;
		border-color: $blue;
		color: $white;
		margin-right: 5px;
		font-size: $font-small;

		&:hover {
			background-color: lighten($blue, 10);
		}
	}

	.back-to-drivers {
		background-color: $gray;
		color: $white;
		border-color: darken($gray, 2);
		font-size: $font-small;

		a {
			color: $white;
		}

		&:hover {
			background-color: lighten($gray, 10);
			border-color: darken($gray, 10);
		}
	}
}
