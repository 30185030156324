@import '../../../styles/globals';

.teams-section {
	.team-section-card {
		background-color: #fff;
		border: 1px solid $g-200;
		padding: 16px 18px 16px 0;
		border-radius: 8px;
		margin-bottom: 24px;
		display: flex;
	}

	.team-section-main {
		flex: 1;
	}

	.team-section-draggable {
		width: 40px;
		margin-right: -5px;
	}

	.centered-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.team-section-header {
		font-size: 16px;
		margin-bottom: 12px;
	}

	.team-section-info {
		display: flex;
	}

	.team-section-badge {
		font-size: 12px;
		color: $g-600;
		padding: 0 8px;
		border: 1px solid $g-200;
		border-radius: 4px;
		margin-right: 8px;
	}

	.ant-select-selection-item {
		color: #b2b2b2;
	}
}

.service-area-team-section-menu-item {
	font-size: 14px;
	.bringg-icon {
		margin-right: 9px;
	}
}

.service-area-teams-section-modal {
	.ant-modal-body {
		height: 540px;
	}

	.range-description {
		font-weight: 400;
		font-size: 14px;
		margin-bottom: 16px;
	}

	.range-header {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 4px;
		margin-top: 32px;
	}
}

.service-area-teams-section-range-picker {
	padding: 0;

	.ant-picker-panel-container {
		box-shadow: none;
		border-radius: 0;
		padding: 0;
	}

	.ant-picker-panels {
		gap: 16px;

		.ant-picker-date-panel {
			margin: 0;
		}
	}

	.ant-picker-range-arrow {
		display: none;
	}
}
