.description {
	margin: 8px 0;
	color: var(--gray-600);
	font-weight: 400;
	font-size: 14px;
}

.labelContainer {
	flex-wrap: wrap;
	display: flex;
	flex-direction: row;
	gap: 8px 16px;
	margin-top: 8px;

	:global(button.bringg-btn) {
		&.labelButton {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 4px;
			padding: 0 8px;
			height: 20px;
			background: var(--gray-200);
			border-radius: 4px;
			color: var(--gray-600);
			// we have different weight discrepancy for font-families
			// 500 left to be consistent with automation-text-template(Automation feature)
			font-weight: 500;
			font-size: 12px;
			min-width: unset;
			&:hover {
				background: var(--gray-400);
				color: var(--gray-600);
			}
		}
	}
}
