.bringgMapRoutePlayerTimes {
	width: 100%;
	display: flex;
	margin-left: 8px;

	.bringgMapRoutePlayerEndTime {
		margin-left: auto;
		margin-right: 16px;
	}
}
