.recharges-panel {
	border-bottom: 1px solid var(--gray-200);

	.panel-title {
		display: flex;
		align-items: center;

		.panel-title-icon {
			color: var(--gray-600);
			font-size: 24px;
		}

		.panel-title-translate {
			margin-inline-start: 8px;
			letter-spacing: -0.32px;
			font-weight: 600;
			font-size: 16px;
			color: var(--gray-700);
		}
	}

	.delivery-block-recharge-panel-container {
		display: flex;
		flex-direction: column;
		align-items: start;
	}

	.add-recharge-button {
		margin-inline-start: 24px;
	}

	.add-recharge-button-disabled {
		pointer-events: none;
	}
	.delivery-block-recharge-panel-container.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
