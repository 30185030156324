.teamsPanel {
	border-bottom: 1px solid var(--gray-200);

	.panelTitle {
		display: flex;
		align-items: center;

		.panelTitleIcon {
			color: var(--gray-600);
			font-size: 24px;
		}

		.panelTitleTranslate {
			margin-inline-start: 8px;
			letter-spacing: -0.32px;
			font-weight: 600;
			font-size: 16px;
			color: var(--gray-700);
		}
	}

	.teamsPanelContainer {
		display: flex;
		flex-direction: column;

		.teamsSelect {
			width: 210px;
		}

		.teamsSelectInColumn {
			max-width: 200px;
		}

		.teamsPanelActions {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 15px;

			.panelSubTitle {
				font-size: 12px;
				font-weight: 600;
				white-space: nowrap;
			}

			.teamsSelectContainer {
				display: flex;
				flex-direction: column;
				flex: 3;

				.teamsSelect {
					width: auto;
				}

				.selectedTeamsBadge {
					background: var(--primary-400);
					border-radius: 50%;
					color: white;
					padding: 2px;
				}
			}

			.teamsCapacityContainer {
				display: flex;
				flex-direction: column;
				margin: 0 20px;
				flex: 1;

				.capacityInput {
					width: auto;
				}
			}

			.teamsStartDateContainer {
				display: flex;
				flex-direction: column;
				flex: 2;
				margin: 0 20px 0 10px;
			}

			.teamsEndDateContainer {
				display: flex;
				flex-direction: column;
				flex: 2;
			}

			.addTeamsButton {
				flex: 1;
				margin-top: 20px;
			}
		}
	}

	.iconDeleteContainer {
		border: none;
		display: none;
		color: var(--gray-600);
	}

	.iconEditStartDate {
		margin-left: 16px;
		border: none;
		display: none;
		color: var(--gray-600);
	}

	.iconEditEndDate {
		margin-left: 16px;
		border: none;
		display: none;
		color: var(--gray-600);
	}

	.schedulesStartDatePicker {
		display: flex;

		:global(.ant-picker-input > input) {
			font-size: 12px;
			color: var(--gray-700);
		}

		&:hover {
			.iconEditStartDate {
				display: block;
			}
		}
	}

	.schedulesEndDatePicker {
		display: flex;

		:global(.ant-picker-input > input) {
			font-size: 12px;
			color: var(--gray-700);
		}

		&:hover {
			.iconEditEndDate {
				display: block;
			}
		}
	}

	:global(.bringg-table-row) {
		align-items: center;

		&:hover {
			background-color: var(--gray-100);

			.iconDeleteContainer {
				display: block;
			}
		}
	}
}
