.delivery-blocks-timeline-calendar {
	height: 100%;

	.assign-component-container .ant-select-selector {
		border: unset;
		padding-left: 0;
		background: transparent;

		.ant-select-selection-search {
			left: 0;
		}
	}

	.delivery-block-slot-content {
		color: #000000 !important;
	}

	.delivery-block-slot-content-unassigned {
		@extend .delivery-block-slot-content;
	}

	.delivery-block-slot-content-assigned {
		@extend .delivery-block-slot-content;
	}

	.delivery-block-break-item {
		text-align: center;
		z-index: 11;
	}

	.delivery-block-flex-break-item {
		text-align: center;
		z-index: 10;
	}

	.vis-timeline {
		border: 1px solid rgba(0, 0, 0, 0.09);
	}

	.vis-time-axis.vis-foreground {
		background-color: white;
		box-shadow: -1px 5px 5px -2px #d4d4d4;

		.vis-text {
			color: #494949;
			padding-left: 4px;
		}
	}

	.vis-item .vis-item-overflow {
		min-height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
	}

	.group-row-cells-component.row-container {
		padding: 6px 16px;
	}

	.vis-item.vis-range {
		border-radius: 0;

		.vis-item-content {
			width: 100%;
			display: flex;

			.item-text {
				width: 100%;
			}
		}
	}

	.vis-itemset .vis-item {
		font-weight: 600;
		font-size: 12px;
		border: unset;
	}

	.gantt-headers-container {
		box-shadow: 0 5px 5px 0 #d4d4d4;
		position: absolute;
	}

	.vis-panel .vis-labelset .vis-label:nth-child(odd) {
		background-color: #fafafa;
	}

	.vis-panel .vis-labelset .vis-label:nth-child(even) {
		background-color: #fafafa;
	}

	.vis-panel.vis-background.vis-vertical {
		background: #e8e8e8;
	}

	.vis-foreground .vis-group {
		border: 1px solid rgba(0, 0, 0, 0.09);
	}

	.vis-time-axis .vis-grid.vis-minor {
		border: 1px solid rgba(0, 0, 0, 0.09);
	}

	.vis-labelset .vis-label {
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
	}

	.vis-panel.vis-top {
		border-left-color: rgba(0, 0, 0, 0.09);
	}

	.vis-time-axis.vis-foreground .vis-text.vis-minor {
		border-right: 1px solid rgba(0, 0, 0, 0.09);
	}

	.group-headers-component.headers-container {
		background-color: white;
		padding-left: 13px !important;
		min-width: 425px;

		.header {
			color: #494949;
			white-space: pre-line;
		}
	}

	.vis-time-axis.vis-foreground .vis-text.vis-minor {
		white-space: pre-line;
		line-height: 1.5;
	}

	.vis-time-axis.vis-foreground {
		min-height: 80px;
	}

	.total-value {
		font-weight: 500;
	}

	.gantt-recharge-time {
		background: repeating-linear-gradient(
			135deg,
			var(--gray-500),
			var(--gray-500) 1px,
			var(--gray-200) 2px,
			var(--gray-200) 8px
		);
		z-index: 2;
	}
}
