.connected-multiple-text {
	margin: 15px;
	margin-inline-start: 0;
}

.team-select {
	width: 100%;
}
.team-select-option {
	.ant-select-item-option-content {
		display: flex;
		justify-content: space-between;
	}
}
