@import '../../../../../styles/globals';

.breaks-card {
	padding: 25px;
	overflow-y: scroll;
	border-right: 1px solid #cccccc;

	.delivery-block-info {
		display: flex;
		margin-bottom: 5px;
		width: 100%;

		label {
			width: 33%;
		}
	}

	.info {
		margin: auto 0;
		color: $black;
	}

	label {
		line-height: 40px;
		color: $gray;
		margin: auto 0;
	}

	.break-separator {
		border: 1px dashed #cccccc;
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.form-details {
		width: 100%;
	}
}
