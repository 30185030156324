@import '../../styles/globals';

.react-table-wrapper {
	--gray0: #bfbfbf;
	--border-gray: #e6e8ed;
	--action-icon-font-size: 16px;

	border: 1px solid #ddd;
	width: fit-content;
	// See if there will be any side effects
	//max-width: 100%;

	.loading-more-items-indicator {
		align-items: center;
		display: inline-flex;
		position: absolute;
		z-index: 10;
		padding: 10px 20px;
		background-color: rgba(217, 237, 247, 0.6);
		color: var(--primary-700);
		border-radius: 10px;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);

		.ant-spin {
			margin-right: 1em;
		}
	}

	.table-header {
		font-weight: 500;
		background-color: var(--gray-100);

		.table-row {
			min-height: 30px;
		}
	}

	.table-row {
		border-bottom: 1px solid $gray-200;

		.table-data-cell {
			background-color: #fafafa;
		}
	}

	.table-group-row {
		border-bottom: 1px solid var(--border-gray);
		background-color: var(--gray-200);

		.group-selection-cell {
			display: flex !important;
			align-items: center;
			justify-content: center;
		}

		div[role='cell'] {
			background-color: inherit;
		}

		.table-data-cell {
			display: flex;
			align-items: center;
			border: 0;
			font-weight: 500;
		}

		&-icon {
			font-size: 14px;
			margin-right: 10px;
		}
	}

	.table-header-cell {
		background-color: var(--gray-100);
		display: flex !important;
		padding: 0 1rem;

		&.row-selection-cell {
			display: flex !important;
			padding: 0;
			justify-content: center;
			align-items: center;
		}

		&.row-actions-cell {
			background-color: transparent;
		}

		.header-wrapper {
			display: flex;
			align-items: center;
			min-width: 0;
			flex: 1;
		}

		.sorter-wrapper {
			display: none;
			padding: 3px;
			margin-left: auto;
			flex-direction: column;
			color: var(--gray0);

			.active {
				color: var(--gray-500);
			}
		}

		&:hover .sorter-wrapper,
		.sorter-wrapper.sorted {
			display: flex;
		}

		.column-resizer {
			width: 10px;
			margin-left: 4px;

			&-icon {
				position: absolute;
				top: calc(50% - 0.75em);
				right: 5px;
				height: 1.5em;
				width: inherit;
				border-right: 1px solid;
				border-color: var(--gray-400);
			}
		}
	}

	.table-header-cell,
	.table-data-cell {
		display: flex;
		margin: 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		align-items: center;
	}

	.cell-data {
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%; // not sure if this will have any side effects
	}

	.row-actions-wrapper {
		display: flex;
		justify-content: center;
		font-size: var(--action-icon-font-size);
		color: var(--gray-650);
	}

	.table-body {
		.table-row {
			background-color: #fff;

			.table-data-cell {
				background-color: inherit;
				padding: 0 1rem;

				&.row-selection-cell {
					padding: 0;
					justify-content: center;

					.cell-data {
						display: inline;
						width: initial;
					}
				}
			}

			.row-actions-cell {
				background-color: transparent;
				.cell-data {
					display: none;
				}
			}

			&:hover {
				background-color: #f6f9fc;
				.row-actions-cell {
					background-color: inherit;
					.cell-data {
						display: initial;
					}
				}
			}
		}

		.no-data-message {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 20px;
		}
	}

	&.sticky {
		.table-header {
			position: sticky;
			z-index: 1;
			width: fit-content;
			top: 0;
		}

		.table-body {
			position: relative;
			z-index: 0;
		}

		[data-sticky-td] {
			position: sticky;
		}
	}
}
