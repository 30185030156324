.plannedDeliveryWindowsTableCell,
.selectedItem {
	min-width: 130px;
	max-width: 150px;
}
.tableStep {
	.header {
		margin-bottom: 20px;

		.description {
			margin-top: 4px;
			margin-inline-start: 34px;
		}
	}
	.tableDetails {
		display: flex;
		justify-content: space-between;
		padding: 0 8px;
	}

	.tableBody {
		max-height: 500px;
	}

	.tableStepCutoff {
		letter-spacing: -0.55px;
	}
}
