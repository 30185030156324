@mixin bounceInAnimation {
	opacity: 0;
	transform: scale3d(0.3, 0.3, 0.3);

	animation-duration: 0.75s;
	animation-name: bounceIn;
	animation-fill-mode: forwards;
}

.row {
	display: flex;
	align-items: center;

	.text {
		color: var(--gray-600);

		.numberOfItems {
			margin-right: 4px;
			font-weight: 600;

			&.completed {
				color: var(--gray-700);
			}

			&:not(.completed) {
				.successfulItems {
					color: var(--success-500);
				}
			}
		}
	}

	.progress {
		// progress bar is 2px off from the error icon in the row below
		margin-left: 2px;
	}

	.iconStatus {
		margin-right: 10px;
		display: flex;

		@include bounceInAnimation;

		&.success {
			font-size: 20px;
			margin-left: 2px;
			margin-right: 10px;
			color: var(--success-500);
		}
	}
}

.inProgress,
.completedWrapper {
	@include bounceInAnimation;
}

@keyframes bounceIn {
	0%,
	20%,
	40%,
	60%,
	80%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1);
	}

	40% {
		transform: scale3d(0.9, 0.9, 0.9);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: scale3d(0.97, 0.97, 0.97);
	}

	to {
		opacity: 1;
		transform: scaleX(1);
	}
}
