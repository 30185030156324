@import '../../../styles/globals';

.conflict-rules-modal {
	.conflict-rules-modal-title {
		@include row;
		font-size: 18px;

		.square {
			width: 14px;
			height: 14px;
			background: $warning-sign;
			border-radius: 2px;
			margin-right: 8px;
		}
	}

	.rules-list {
		margin-top: 24px;
		max-height: 200px;
		overflow: auto;

		.rule-row {
			margin-bottom: 8px;
		}
	}

	.ant-modal-footer {
		display: flex;

		.cancel-btn {
			margin-right: auto;
		}
	}
}
