@import '../../../styles/globals.scss';

.chat-window-header {
	border: 1px solid $border-color;
	background-color: $white;
	height: 50px;

	.chat-window-header-image {
		.task-icon {
			display: inline-block;
			margin: 10px;

			.bringg-icon-location {
				font-size: 25px;
				color: #9b9b9b;
			}
		}

		.avatar-wrapper {
			display: inline-block;

			span {
				background-color: $background-icon-color;
				width: 30px;
				height: 30px;
				display: inline-block;
				-webkit-border-radius: 30px;
				-moz-border-radius: 30px;
				border-radius: 30px;
				margin: 10px;
			}
		}

		img {
			max-width: 100%;
			border-radius: 40px;
			-webkit-border-radius: 40px;
			-moz-border-radius: 40px;
			border-radius: 40px;
		}
	}

	.chat-window-header-title {
		color: $light-blue;
		margin-left: 5px;

		.link-wrapper {
			display: inline-block;
		}
	}
}
