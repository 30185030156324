$wrapper-width: 590px;
$modal-height: 60px;

.modal {
	position: absolute;
	background: white;
	top: calc(100vh - $modal-height - 50px);
	right: 20px;
	z-index: 20;
	right: unset;
	border-radius: 4px;
	height: $modal-height;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

	.modalBody {
		display: flex;
		height: $modal-height;
		align-items: center;

		.numberOfTasksBlock {
			background: var(--gray-600);
			height: $modal-height;
			border-radius: 4px 0 0 4px;
			display: flex;
			align-items: center;
			gap: 8px;

			.cursorMove {
				margin-inline-start: 4px;
				margin-top: 1px;
				color: rgba(255, 255, 255, 0.4);
				font-size: 24px;
				cursor: move;
				display: flex;
				align-items: center;

				&:hover,
				&:active {
					color: rgba(255, 255, 255, 1);
				}
			}

			.numberOfTasks {
				color: #fff;
				font-size: 20px;
				font-weight: 700;
				padding-inline-end: 16px;
			}
		}

		.selectedText {
			padding: 0px 10px;
		}

		.actions {
			display: flex;
			justify-content: center;

			.button {
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				color: var(--gray-700);
				padding: 4px 10px;

				&:disabled {
					color: var(--gray-500);
				}
			}

			.buttonIcon {
				font-size: 20px;
			}

			.buttonSpan {
				margin: 0;
			}
		}

		.close {
			box-shadow: none;
		}
	}
}

.additionalMenu {
	:global(.bringg-dropdown-menu) {
		max-height: 42vh;
		overflow: auto;
	}
}
