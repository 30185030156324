@import '../../styles/globals';
.soft-rollout {
	font-family: $font-stack, sans-serif;

	.soft-rollout-modal-image {
		width: 240px;
		height: auto;
	}

	.blocked-rollout-modal {
		background-color: white;
		border-radius: 9px;
		width: 690px;
	}
}
