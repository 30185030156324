.chat-window-input {
	width: 100%;

	input {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		height: 37px;
		border-top: none;
	}
}
