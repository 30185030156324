@import '../../../styles/globals.scss';

.actions-data-container {
	@include basic_font_props;
	padding-top: 15px;
	padding-bottom: 15px;
	border-width: 0 0 2px;
	border-color: $light-gray;
	border-style: solid;

	.action-data {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.delete-icon {
		margin-top: 27px;
		color: $gray;
		float: right;
		margin-right: 15px;
		cursor: pointer;
	}

	.ant-row {
		padding-bottom: 6px;
	}

	.label-description {
		font-size: $font-xs;
		width: 200px;
	}

	.input-label {
		width: 200px;
	}
}
