.map-legend-information-container {
	width: 200px;
	margin-top: 10px;
	background-color: #fff;
	box-sizing: border-box;
	padding: 7px;
	border-radius: 3px;

	.map-legend-information-list {
		display: flex;
		flex-direction: column;

		.map-legend-information-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 5px;

			.map-legend-information-item-title {
				margin-left: 10px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
