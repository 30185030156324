@import '../../styles/globals.scss';

$floating-inventories-event-log-table-h: 280px;
$floating-inventories-event-log-header-h: 40px;
$floating-inventories-event-log-modal-h: calc(
	#{$floating-inventories-event-log-table-h} + #{$floating-inventories-event-log-header-h} + 40px
);

.floating-inventories-container {
	@include basic_font_props;
	padding-top: 16px;
	width: 100%;
	.floating-inventories-table-container {
		margin-top: 40px;

		.ant-table-tbody > tr > td,
		th {
			&.ant-table-cell {
				font-weight: 600 !important;
			}
		}

		.floating-inventories-event-log-button {
			color: $light-blue;
			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
}

.floating-inventories-event-log-modal {
	> div {
		> div {
			padding: 16px 0;
			> div {
				display: flex;
				justify-content: start;
				align-items: start;
				height: $floating-inventories-event-log-modal-h;
			}
		}
	}

	.floating-inventories-event-log-title-icon {
		transform: translateY(-2px);
		margin-right: 16px;
		font-size: 28px;
		line-height: 32px;
		color: var(--primary-400);
	}
}

.floating-inventories-event-log-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	width: 92%;

	.floating-inventories-event-log-header-title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		min-height: $floating-inventories-event-log-header-h;
		margin-bottom: auto;
		font-size: 24px;
		font-weight: 600;
		line-height: 32px;
		width: 100%;

		p {
			width: 90%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.floating-inventories-event-log-header-close-button {
		all: unset;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: var(--gray-50);
		transform: translateY(-8px);
		font-size: 24px;
		color: var(--gray-600);
		&:hover {
			cursor: pointer;
			background-color: var(--gray-300);
		}
	}

	.floating-inventories-event-log-table-last-update {
		margin-top: auto;
	}

	.floating-inventories-event-log-last-update {
		color: var(--gray-500);
		margin-right: 4px;
	}

	.floating-inventories-event-log-table {
		> div {
			height: $floating-inventories-event-log-table-h;
			> div {
				height: $floating-inventories-event-log-table-h;
				> div {
					height: $floating-inventories-event-log-table-h;
					> div {
						height: $floating-inventories-event-log-table-h;
					}
				}
			}
		}
	}
}
