@import '../../../styles/globals';
@import '../crews-card/crews-card';

.crews-container {
	margin-top: 10px;

	.crews-container-empty {
		border: 1px solid #efefef;
		text-align: center;
		padding: 5px 10px;
		border-radius: 4px;
		background-color: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.new-crew {
		margin-top: 10px;

		.new-crew-title {
			display: block;
			margin-bottom: 10px;
		}
	}

	.crews-space-top {
		margin-top: 20px;
	}

	.float-right {
		float: right;
	}

	.add-crew-btn {
		background-color: $crew-cta-color;
		border-color: $crew-cta-bgc-color;
		color: $white;
		margin-right: 5px;
		font-size: $font-small;

		&:hover {
			background-color: lighten($crew-cta-color, 10);
		}
	}

	.ant-input {
		border-radius: 22px 22px 22px 22px;
	}

	.drivers-btn {
		background-color: $gray;
		color: $white;
		border-color: darken($gray, 2);
		font-size: $font-small;

		&:hover {
			background-color: lighten($gray, 10);
			border-color: darken($gray, 10);
		}
	}
}
