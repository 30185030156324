.partial-runs-actions-modal {
	height: 245px;
	width: 400px;

	.partial-runs-actions-description {
		color: var(--gray-800);
		padding-top: 10px;
	}

	.runs-list {
		list-style-type: decimal;

		.run-link {
			display: flex;
			align-items: center;
			gap: 4px;
		}
	}

	.run-actions {
		display: flex;
		flex-direction: column;
		margin-top: 10px;

		label {
			color: var(--gray-800);
			font-weight: normal;
		}
	}

	.selection-comment {
		font-size: 11px;
	}
}
