@import '../../styles/globals.scss';

$TOOLTIP_WIDTH: 240px;
$ARROW_SIZE: 18px;

@mixin baseArrowProps() {
	position: absolute;
	width: 0;
	height: 0;
	z-index: 1;
}

.timeline-item-tooltip-container {
	width: $TOOLTIP_WIDTH;
	z-index: 1060;

	.timeline-item-tooltip-arrow {
		@include baseArrowProps;

		&::before {
			@include baseArrowProps;
			content: '';
		}

		&.arrow-bottom::before {
			left: calc(#{$TOOLTIP_WIDTH} / 2 - #{$ARROW_SIZE});
			top: -1px;
			border-left: $ARROW_SIZE solid transparent;
			border-right: $ARROW_SIZE solid transparent;
			border-top: $ARROW_SIZE solid $white;
		}

		&.arrow-top::before {
			left: calc(#{$TOOLTIP_WIDTH} / 2 - #{$ARROW_SIZE});
			top: -$TOOLTIP_WIDTH; // should get dynamic height of tooltip with js
			border-left: $ARROW_SIZE solid transparent;
			border-right: $ARROW_SIZE solid transparent;
			border-bottom: $ARROW_SIZE solid $white;
		}

		&.arrow-left::before {
			left: -$ARROW_SIZE;
			top: calc(0px - $TOOLTIP_WIDTH / 2); // should get dynamic height of tooltip with js
			border-top: $ARROW_SIZE solid transparent;
			border-bottom: $ARROW_SIZE solid transparent;
			border-right: $ARROW_SIZE solid $white;
		}

		&.arrow-right::before {
			left: $TOOLTIP_WIDTH;
			top: calc(0px - $TOOLTIP_WIDTH / 2); // should get dynamic height of tooltip with js
			border-top: $ARROW_SIZE solid transparent;
			border-bottom: $ARROW_SIZE solid transparent;
			border-left: $ARROW_SIZE solid $white;
		}
	}
}
