.runs-container {
	@import '../../styles/globals.scss';
	@include basic_font_props;
	margin-bottom: 5px;

	.ant-pagination {
		float: right;
		margin-top: 5px;
	}

	.pagination-container {
		margin-bottom: 16px;
		justify-content: space-between;
	}

	.runs-filter {
		margin: 24px 0;
		width: 100%;
		.ant-select {
			min-width: 115px;
		}
	}

	.runs-header {
		margin-top: 10px;

		.runs-bulk-close-button {
			max-width: 120px;
		}

		.runs-title {
			display: inline-block;
			margin-top: 3px;
			max-width: 100px;
		}

		button {
			span {
				margin-left: 2px;
			}
		}
	}

	.runs-footer {
		margin-top: 10px;
	}

	th.ant-table-cell {
		font-weight: 600 !important;
	}
}
