@import '../../../../styles/globals';

.drivers-filter {
	.ant-select-selector {
		min-width: 146px;
		max-width: 146px;
		border: 1px solid $second-color;
		min-height: 28px;
		max-height: 28px;
		background-color: transparent;
	}

	.ant-select-selection-overflow-item {
		display: none;
	}

	// calendar icon
	.anticon {
		left: 3px;
		right: unset;
	}

	.drivers-placeholder {
		position: absolute;
		margin: 7px 5px;
		font-weight: normal;
		font-size: $font-small;
		width: 136px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.drivers-text-icon {
			display: flex;
		}
	}

	.drivers-icon {
		margin-inline-end: 2px;
	}

	.placeholder-text {
		max-width: 100px;
		display: inline-block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
