$row-height: 32px;

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		height: 0px;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		height: 0px;
	}
	to {
		opacity: 1;
		height: $row-height;
	}
}

@mixin truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
