@import '../../styles/globals';

.resource-results-modal {
	.resource-results-text {
		font-size: 16px;
		font-weight: 600;
		color: $g-600;
	}

	.resource-results-success {
		color: $success-500;
	}

	.resource-results-progress {
		margin: 24px 20px 24px 0;
		padding: 12px;

		.ant-progress-circle-path {
			stroke: $success-500 !important;
		}

		.ant-progress-text {
			display: none;
		}
	}

	.bringg-icon-selected,
	.bringg-icon-warning {
		font-size: 24px;
		margin-right: 8px;
		position: relative;
		top: 4px;
	}

	.bringg-icon-info {
		font-size: 16px;
	}

	.bringg-icon-info,
	.bringg-icon-warning {
		color: $danger-500;
	}

	.resource-results-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-right: -33px;
	}

	.resource-results-actions {
		.bringg-icon {
			font-size: 24px;
		}

		.ant-btn {
			color: $g-600;

			:hover {
				color: rgba(0, 0, 0, 0.45);
			}
		}
	}
}
