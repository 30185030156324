.conditions {
	margin-top: 32px;

	.conditionsDropdown {
		margin-top: 24px;
		margin-inline-start: 38px;
		row-gap: 12px;
		display: grid;
		grid-template-columns: 200px 250px;

		.selectItem {
			width: 300px;
		}
	}
}
