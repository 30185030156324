@mixin automation-card {
	display: flex;
	flex-direction: column;

	background: white;
	box-shadow: 0 1px 2px rgba(0, 51, 80, 0.16);

	border-radius: 8px;

	padding: 0 16px;
	margin-bottom: 8px;
}

@mixin automation-header {
	height: fit-content;
	margin-top: 8px;
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 24px;
	color: var(--gray-600);
}

@mixin automation-title {
	display: flex;
	align-items: center;
	gap: 8px;
}

@mixin automation-actions {
	display: flex;
	align-items: center;
	gap: 24px;
	margin-inline-start: auto;
}

@mixin automation-content {
	flex: 1;
	display: flex;
	padding: 16px 0;
	font-size: 16px;
	line-height: 24px;
	color: var(--gray-800);
}

@mixin automation-footer {
	height: fit-content;
	margin-top: auto;
	margin-bottom: 16px;
}
