.arrowsContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	align-items: center;
	margin-top: 20px;
	padding-top: 30px;

	.arrowButton {
		margin: 5px 0;
	}

	.decorativeLine {
		position: absolute;
		width: 1px;
		height: 100%;
		top: 0;
		background: var(--gray-100);
	}
}
