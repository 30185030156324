@import '../../../styles/globals';

.recurring-repeat {
	width: 100%;
	padding-top: 11px;

	.label-number-input-text {
		font-size: 14px;
	}

	.repeat-item {
		display: flex;
		justify-content: space-between;
		padding-top: 11px;

		.times-input {
			width: 67%;
		}

		.ant-calendar-picker {
			width: 67%;
		}

		input:disabled {
			color: $gray;
		}
	}

	.recurring-repeat-group {
		width: 100%;
	}
}
