.optimization-error-modal {
	.error-modal-title {
		font-weight: 600;
		font-size: 20px;
	}
	.ant-modal-body {
		padding: 0 24px;
	}
	.modal-body {
		font-size: 13px;
		white-space: pre-line;
		color: var(--color-brand-gray);

		.extra-data {
			margin-top: 5px;
			font-weight: bold;
			line-height: 20px;
		}
	}
	.optimization-error-modal-footer {
		display: flex;
		justify-content: flex-end;
	}

	.task-error-list {
		background: var(--primary-10);
		border-radius: 4px;
		margin-top: 8px;
		max-height: 150px;
		overflow: auto;
	}

	.task-link {
		list-style: auto;

		.external-icon {
			font-size: 16px;
			margin-inline-start: 2px;
		}
	}

	.proceed_text {
		margin-top: 10px;
	}
	.proceed-button {
		margin-inline-start: 8px;
	}
}
