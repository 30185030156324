@import '../../styles/theme.scss';

$button-size: 14px;
$remove-row-button-color: rgba(0, 0, 0, 0.4);

.group-remove-button-component {
	&.remove-button {
		color: $remove-row-button-color;
		cursor: pointer;
		color: var(--gray-500);

		display: flex;
		flex-direction: row;
		align-items: center;

		font-style: normal;
		font-weight: normal;

		&.disabled {
			pointer-events: none;
		}
	}
}
