@import '../../styles/globals';

.bringgDragger {
	display: flex;
	flex-direction: column;
	align-items: center;

	.description {
		color: $g-600;
	}

	.button {
		padding: 0;
		font-size: 12px;
	}
}
