.times {
	margin-top: 24px;
	display: flex;
	flex-direction: column;

	.duration {
		margin-top: 24px;
	}

	.timeRangePickerWrapper {
		display: flex;
		align-items: center;

		.timeRangeToText {
			margin-inline-start: 8px;

			.timeRangeToTextTitle {
				color: var(--gray-600);
			}
			.timeRangeToTextValue {
				font-weight: bold;
			}
		}
	}
}

.flexColumn {
	display: flex;
	flex-direction: column;
	.label {
		font-size: 12px;
		color: var(--gray-800);
		font-weight: 600;
	}
}

.timeRangePicker {
	width: 200px;
}
.hours {
}
.minutes {
	margin-inline-start: 8px;
}
