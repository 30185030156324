.row {
	width: 100%;

	display: flex;
	align-items: center;

	.text {
		color: var(--gray-600);

		> .numberOfItems {
			margin-right: 4px;
			font-weight: 600;

			&.completed {
				color: var(--gray-700);
			}
		}
	}

	.iconStatus {
		font-size: 20px;
		margin-left: 2px;
		margin-right: 10px;
		color: var(--danger-300);
	}
}
