.delivery-block-recharge-time-and-location-container {
	width: 100%;
	padding-left: 40px;
	align-items: center;

	.delivery-block-recharge-time-and-location {
		display: flex;
		padding-bottom: 15px;
	}

	.delivery-block-recharge-time-and-location:hover .recharge-trash-icon {
		display: block;
	}

	.recharge-duration-text {
		padding: 8px;
		font-size: 13px;
	}

	.recharge-location-text {
		padding: 8px;
		font-size: 13px;
		flex: 1;
	}

	.recharge-trash-icon-button {
		margin-right: 25px;

		.recharge-trash-icon {
			color: var(--gray-600);
			margin-inline-start: auto;
			font-size: 14px;
			display: none;
		}
	}

	.delivery-block-recharge-error {
		color: var(--danger-500);
		padding-bottom: 8px;

		.bringg-icon-warning {
			padding-right: 4px;
		}
	}
}
