.attachmentsLoader {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.attachmentsLoaderTitle {
	text-align: center;
	margin: 30px 0;
}
