.breaks-summary-items {
	margin-inline-start: 8px;
	display: flex;
	font-size: 14px;
	color: var(--gray-600);
	letter-spacing: -0.14px;

	.breaks-summary-item {
		margin-inline-start: 4px;

		.break-type:before,
		.break-length:before,
		.break-times:before {
			content: ' ';
		}

		&:first-child {
			margin-inline-start: 0;
		}

		+ .breaks-summary-item:before {
			content: ' | ';
			color: var(--gray-700);
		}
	}
}
