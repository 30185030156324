.configurations-audit-changed-fields {
	display: flex;
	align-items: baseline;
	&-list {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&-impersonated-tooltip {
		margin-left: auto;
	}
	&-tooltip {
		color: #718096;
	}
}
