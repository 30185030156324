.delivery-blocks-breaks {
	overflow-y: scroll;

	.ant-select {
		width: 100%;
	}

	.ant-form-item label {
		font-weight: normal;
		float: left;
		margin-bottom: 0px;
		line-height: 35px;
	}

	.time-range-separator-break {
		height: 32px;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.break-separator {
		border: 1px dashed #cccccc;
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.ant-form-item-control {
		line-height: 15px;
	}

	.breaks-length {
		width: 100%;
		input {
			border: none;
			padding: 0px 11px;
		}
	}
}
