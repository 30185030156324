@import '../../../../styles/globals';

.custom-attributes-list-container {
	@import '@bringg/react-components/dist/components/collapse/custom-collapse-lastchild';

	.custom-attributes-list-item {
		.custom-attributes-list-item-value {
			&.enum {
				line-height: 1.67;
				@include ellipsis_text;
				border-radius: 4px;
				border: solid 1px $super-light-blue-2;
				padding: 0 4px;
			}

			&.mono {
				font-family: monospace;
			}
		}
	}
}
