.plannedDeliveryWindows {
	background: var(--white-bg);
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.extraContent {
		margin-inline-start: 28px;
		margin-inline-end: 60px;
	}
	:global {
		.bringg-tabs .ant-tabs-content-holder {
			max-height: unset;
		}
	}
}
