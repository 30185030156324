.tabTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 4px;

	.removeLanguageBtn {
		opacity: 0.4;
		background-color: transparent;
	}
}

.contentTab {
	:global(.ant-tabs-content-holder) {
		margin-top: 16px;
	}

	:global(.ant-tabs-nav-operations) {
		// only way to beat antd styles selector
		display: none !important;
	}

	:global(.ant-tabs-tab) {
		&:hover {
			.removeLanguageBtn {
				opacity: 1;
				visibility: visible;
				background-color: transparent;
			}
		}
	}
}
