@import '../../../styles/globals.scss';

.chat-window-body {
	border: 1px solid $border-color;
	background-color: $white;
	height: 80vh;
	overflow-y: scroll;
	border-top: none;

	.messages {
		white-space: pre-line;
		min-height: 685px;

		@import '../chat-message/chat-message';

		&.scrolling {
			visibility: hidden;
		}

		.date-divider {
			span {
				font-size: 14px;
				color: #cccccc;
			}
		}
	}

	.spinner-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
	}
}
