.typeIcon {
	display: inline-block;
	width: 8px;
	height: 8px;
	margin-right: 8px;
	border-radius: 50%;
	background: var(--purple);

	&.emailType {
		background: var(--aquamarine);
	}
}

.actionsPopover {
	display: flex;
	flex-direction: column;
	padding: 8px;
	font-family: 'Open Sans', sans-serif;

	[class~='bringg-btn'] {
		text-align: left;

		.bringg-icon {
			color: var(--gray-600);
		}
		span {
			color: var(--gray-800);
		}

		&:hover {
			background-color: var(--primary-10);

			.bringg-icon,
			span {
				color: inherit;
			}
		}
	}
}
