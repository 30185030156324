@import '../../../../../../styles/globals';

.createSummary {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-inline-start: auto;

	.summaryInfo {
		.infoName,
		.infoDate {
			font-weight: $font-weight-medium;
		}
	}
}
