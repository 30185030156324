.recurrence-panel {
	border-bottom: 1px solid var(--gray-200);

	.panel-title {
		display: flex;
		align-items: center;

		.panel-title-icon {
			color: var(--gray-600);
			font-size: 24px;
		}

		.panel-title-translate {
			margin-inline-start: 8px;
			letter-spacing: -0.32px;
			font-weight: 600;
			font-size: 16px;
			color: var(--gray-700);
		}

		.recurrence-summary {
			color: var(--gray-600);
			letter-spacing: -0.14px;
			font-size: 14px;
			margin-inline-start: 8px;
		}
	}

	.recurrence-items {
		display: flex;
		flex-direction: column;
		padding: 0 24px;
		margin-bottom: 16px;

		.days-in-week {
			margin-inline-start: 16px;

			.ant-checkbox-group-item {
				& > span:last-child {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 40px;
					height: 40px;
					color: var(--gray-700);
					letter-spacing: -0.02em;
					background: var(--gray-100);
					border: 0;
					border-radius: 50%;
				}

				&.ant-checkbox-wrapper-checked {
					& > span:last-child {
						background: var(--primary-400);
						color: #fff;
					}
				}
			}

			.ant-checkbox {
				display: none;
			}
		}

		.recurrence-every,
		.recurrence-at-the {
			display: flex;
			align-items: center;
			margin-inline-start: 16px;

			.bringg-input-number {
				margin-inline: 8px;
			}
		}
	}

	.recurrence-items.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
