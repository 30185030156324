@import '../../styles/mixins.scss';

.header {
	display: flex;
	align-items: center;
	padding: 8px 8px 0 8px;

	.title {
		font-size: 16px;
		font-weight: 500;
		margin-inline-start: 16px;

		@include truncate;
	}

	.defaultButton {
		margin-inline-start: auto;
	}
}
