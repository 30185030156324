.ant-btn:empty {
	visibility: visible;
	width: 106px;
	margin-left: 2px;
	padding-left: 0px;
	text-align: left;
}

#configuration-audit-export-button {
	display: inline-block;

	input.ant-btn.ant-btn-link.filter-bar-2-button-blank {
		top: -2px;
	}
}

#configuration-audit-export-button > input.ant-btn.ant-btn-link.filter-bar-2-button-blank {
	color: #718096;
	&:disabled {
		color: #a0aec0;
	}
}

.bringg-icon.bringg-icon-export {
	font-size: 19px;
	padding-top: 6px;
}
