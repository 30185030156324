.customer-rating-container {
	@import '../customer-rating-header/customer-rating-header';

	font-size: $font-medium;
	font-weight: 400;
	color: #979797;

	.customer-rating-save-button {
		margin-top: 15px;
		float: right;
	}

	h3 {
		border-bottom: none;
	}

	.ant-checkbox-wrapper,
	.select-wrapper,
	.rating-option-delete button {
		float: right;
	}

	.customer-rating-field {
		margin-bottom: 30px;
		align-items: center;
		.allow-text-comment-checkbox {
			float: unset;
			margin-right: 8px;
		}
	}

	.bg-success {
		background-color: var(--primary-50);
		color: $primary;
		font-size: 16px;
		&:hover {
			color: $p-500;
			background-color: var(--primary-100);
		}
	}

	.trash-btn {
		width: 30px;
		height: 30px;
		background: none;
		box-shadow: none;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.2s ease-in-out;
		margin-left: 8px;
		&:hover {
			background: $g-200;
		}
		.bringg-icon-trash {
			font-size: 20px;
			color: $g-600;
		}
	}
}
