@import '../../styles/globals.scss';

.vehicle-profiles-form-wrapper {
	font-family: 'Open Sans', 'Rubik', sans-serif;
}

.vehicle-profiles-page--form-wrapper {
	margin: 2rem auto;
	max-width: 80%;
	height: calc(100vh - 375px);
	overflow: auto;
	box-shadow: 0px 1px 3px rgba(0, 51, 80, 0.16);
	margin: 0 auto;
	background-color: $light-gray-background;

	.vehicle-profiles-form-item {
		margin: 0;
	}

	.vehicle-profiles-form--section-wrapper {
		margin: 0 2rem 1rem 2rem;
		border-top: 1px solid #e1e9f0;
		display: flex;

		&:first-child {
			border-top: unset;
		}

		.vehicle-profiles-form--section-icon {
			margin-right: 10px;
			font-size: 24px;
			margin-top: 4px;
			color: #718096;
		}

		.vehicle-profiles-form--section-content {
			.vehicle-profiles-form--section-title {
				font-size: 16px;
				color: #2d3748;
			}

			.vehicle-profiles-form--section-sub-title {
				font-size: 12px;
				color: #718096;
				margin-bottom: 1rem;
			}

			.vehicle-profiles-form--group-title {
				width: 100%;
				margin-top: 2rem;
				font-weight: 700;
				font-size: 13px;
				color: #2d3748;
			}

			.vehicle-profiles-form--input-group,
			.vehicle-profiles-form--checkbox-group {
				margin-top: 1rem;
				margin-bottom: 1rem;

				.vehicle-profiles-form--label-text {
					font-size: 12px;
					color: var(--gray-700);
					font-weight: 500;
					line-height: 24px;

					span.vehicle-profiles-form--required {
						color: #d93a17;
					}
				}

				.vehicle-profiles-form--input {
					width: 164px;
				}

				.vehicle-profiles-form--select {
					width: 320px;
				}
			}

			.vehicle-profiles-form--checkbox-group {
				margin-top: 3rem;
			}

			.vehicle-profiles-form--flex-group {
				display: flex;
				flex-wrap: wrap;
				.vehicle-profiles-form--input-group {
					margin-right: 2rem;
				}

				.vehicle-profiles-form--select {
					width: 164px;
				}
			}
		}
	}
}

.vehicle-profile-form-title-wrapper {
	display: flex;
	cursor: pointer;

	:focus {
		box-shadow: none;
	}
	:focus-visible {
		box-shadow: none;
	}

	.vehicle-profile-form-title-input {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		padding-left: 0px;
		padding-right: 40px;
		margin-right: 0px;
		font-size: 22px;

		background: var(--primary-10);
		border: none;

		width: 15ch;

		:focus {
			box-shadow: none;
			background: var(--primary-10);
			color: var(--primary-10);
		}
	}

	.edit-title-icon {
		padding-top: 5px;
		font-size: 1.7em;
		color: var(--gray-600);
		opacity: 0;

		pointer-events: none;
		margin-left: -40px;
		z-index: 1;
	}

	&:hover {
		.edit-title-icon {
			opacity: 1;
		}
	}

	.no-value {
		opacity: 1;
	}
}

.vehicle-profile-form-title-wrapper > .ant-input.ant-input-status-error.bringg-input.vehicle-profile-form-title-input {
	background: var(--primary-10);
	:focus {
		border: none;
	}
}

.vehicle-profile-form-title-wrapper
	> .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.vehicle-profile-form-title-wrapper
	> .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
	border: none;
	box-shadow: none;
}

.vehicle-profile-form--title-editor-wrapper {
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: space-between;
	background: var(--primary-10);
	margin: 15px auto 0px;
	max-width: 80%;

	.vehicle-profile-form--title-editor {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 2rem;
		padding-bottom: 0px;

		.ant-form-item:first-child {
			margin-bottom: 0px;
		}

		.vehicle-profile-title {
			font-size: 20px;
		}

		.vehicle-profile-description {
			font-size: 12px;
		}

		.editable-title {
			width: 100%;
		}
	}

	.vehicle-profile-form--title-editor-reset-button {
		margin: auto 1rem;
	}
}

.vehicle-profiles-page--form-controls-wrapper {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	padding: 2rem;
	max-width: 80%;
	box-shadow: 0px 1px 3px rgba(0, 51, 80, 0.16);

	.vehicle-profiles-page--form-controls-wrapper-right {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		flex: 1 1;
		justify-content: flex-end;

		.save-and-create-new-btn {
			margin-right: 1em;
		}
	}
}
