.selected-task {
	display: flex;
	margin-bottom: 16px;

	align-items: center;
	justify-content: space-around;
	gap: 8px;

	> .task-item {
		&.wrap-text,
		> span.wrap-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.dont-break {
			flex: 0 0 auto;
		}
	}

	.task-detail-with-icon {
		display: flex;
		align-items: center;

		.selected-task-icon {
			color: var(--gray-600);
			margin-right: 8px;
			font-size: 18px;
		}
	}
}
