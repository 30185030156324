.plannedDeliveryWindowsSlotModalWrapper {
	.PlannedDeliveryWindowsModal {
		top: 40px;
	}
	.multiEditDropdown {
		margin-top: 12px;
	}

	.conditionElements {
		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: 10fr 20px;
		row-gap: 12px;
		margin-top: 12px;
	}

	.eraseButton {
		display: flex;
		align-items: center;
		align-self: center;
		justify-content: center;
	}

	:global {
		.ant-modal {
			color: var(--gray-800);
		}
	}
}
