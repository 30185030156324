@import '../../../../styles/globals.scss';
.history-filter {
	border-bottom: 1px solid $border-color;
	padding-bottom: 10px;
	.ant-select {
		width: 100%;
	}
	.anticon-down,
	.ant-select-selection__placeholder,
	.ant-select-dropdown-menu-item {
		color: $primary-color;
	}
}
