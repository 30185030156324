.workflow-content {
	line-height: 24px;
	font-size: 16px;
	text-transform: lowercase;

	.bold {
		font-weight: 500;
	}

	&:first-letter {
		text-transform: capitalize;
	}

	.action-content {
		display: inline-block;
		&:first-letter {
			text-transform: capitalize;
		}
	}

	.content-actions {
		margin-top: 8px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		width: fit-content;

		.content-arrow-icon {
			color: var(--gray-600);
			margin-right: 8px;
			font-size: 32px;
		}
	}
}
