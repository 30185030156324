.delivery-block-summary-details {
	font-size: 12px;

	.summary-details-title {
		color: var(--gray-800);
		font-weight: 700;
	}

	.summary-details-title,
	.summary-details-name,
	.summary-details-block-time {
		letter-spacing: -0.12px;
	}

	.summary-details-name,
	.summary-details-block-time {
		color: var(--gray-600);
	}

	.summary-details-name::before {
		content: ' ';
	}
	.summary-details-block-time::before {
		content: ' - ';
	}

	.summary-details-resources {
		font-weight: 600;
		margin-inline-start: 5px;
		background: var(--primary-50);
		color: var(--primary-400);
		border-radius: 12px;
		padding: 2px 12px;
	}
}
