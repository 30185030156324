@import '../../styles/globals.scss';

.changes-diff {
	&-modal {
		max-width: 80%;
		min-width: 520px;
	}

	&-cancel-button {
		display: none;
	}

	&-arrow {
		&-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&-icon {
			font-size: 18px;
			color: rgba(0, 0, 0, 0.7);
		}
	}

	&-changed-field-name {
		font-weight: 500;
		margin: 18px 0 6px -6px;
		color: #4a5568;
		span {
			color: #25dfdf;
			font-style: italic;
		}
	}

	&-text {
		border: 1px solid $light-gray;
		background-color: inherit;

		&-added {
			background-color: rgba(46, 160, 67, 0.4);
		}

		&-removed {
			background-color: rgba(248, 81, 73, 0.4);
		}
	}
}
