@import '../../../../styles/globals';

.delivery-blocks-drivers-panel {
	@include delivery-block-modal-column;
	border-right: 1px solid #cccccc;

	.drivers-panel-select {
		width: 100%;
		padding: 5px;
	}

	.capacity-counter {
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: solid 0.6px #cccccc;
		height: 103px;
		font-size: 60px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.ant-list-item-action {
		> li {
			padding: 0;
		}

		.ant-btn-default {
			box-shadow: none;
		}
	}

	.users-list-container {
		padding: 0 10px;

		.ant-list-item-meta-title {
			padding-top: 8px;

			.clickable-span {
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100px;
				display: inline-block;
			}
		}
		.phone-span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100px;
			display: inline-block;
		}
	}
}
