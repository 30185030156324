.optimization-time-frame {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--gray-200);
	padding: 10px 0;

	.time-frame-left-side {
		display: flex;
		flex-direction: column;
		.time-frame-time-zone {
			margin-top: 10px;
		}
	}
	.show-resources-button {
		margin-inline-start: 10px;
	}

	.single-day-time-selection {
		display: grid;
		grid-auto-flow: column;
		grid-gap: 10px;
	}
}
