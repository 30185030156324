.required-field {
	position: relative;

	&:before {
		content: '*';
		position: absolute;
		top: 50%;
		bottom: 0;
		color: red;
		transform: translateY(-50%);
		left: -12px;
	}
}
