@import '../../../styles/globals.scss';

.chat-item {
	$unread-color: #04cf4e;
	$task-icon-color: #d8d8d8;

	height: 60px;
	border-bottom: 1px solid $border-color;
	padding: 10px 5px;

	&.selected {
		background-color: rgba(112, 187, 253, 0.25);
	}

	.avatar-wrapper,
	.task-icon {
		float: left;
		width: 42.6px;
		height: 42.6px;

		span {
			width: 42.6px;
			height: 42.6px;
			background-color: $task-icon-color;
		}

		.bringg-icon-location {
			color: $gray;
			font-size: 42px;
		}
	}

	.chat-info,
	.avatar-wrapper,
	.chat-numbers {
		display: inline-block;
	}

	.chat-numbers {
		float: right;
		text-align: right;

		.chat-time {
			font-size: 11px;
			color: $gray;

			&.green {
				color: $unread-color;
			}
		}

		.chat-unread-count {
			width: 22px;
			font-size: $font-small;
			margin-left: 4px;
			margin-top: 4px;
			display: block;
			border-radius: 57px;
			text-align: center;
			background-color: $unread-color;
			color: $white;
		}
	}

	.chat-info {
		float: left;
		margin-left: 30px;
		white-space: nowrap;
		max-width: 140px;
		text-overflow: ellipsis;
		overflow: hidden;

		.chat-title {
			font-size: $font-medium;
			color: #4a4a4a;
		}

		.chat-message {
			font-size: $font-small;
		}
	}
}
