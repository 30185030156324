@import '../../../../../styles/globals';

.optimization-settings-header-empty {
	height: 96px;
	padding: 24px 24px 16px 24px;
	background: var(--gray-100);
	position: relative;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	display: grid;
	grid-template-columns: 2fr 1fr;

	p {
		display: grid;
		align-self: center;
	}

	button {
		display: grid;
		align-self: center;
	}

	div {
		padding-top: 12px;

		span {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: -0.02em;
			text-align: left;
		}
	}
	.new-override-btn {
		margin-top: -4px;
		float: right;
		text-align: right;
	}
}

.optimization-settings-header {
	height: 200px;
	left: 0;
	top: 0;
	padding: 24px 24px 16px 24px;
	margin: auto;
	background: var(--gray-100);
	border-left: solid 1px;
	border-right: solid 1px;
	border-color: var(--gray-150);
	border-radius: 10px 10px 0 0;

	.optimization-settings-name-block {
		display: flex;
		width: 25%;
		align-items: center;

		input {
			padding-left: 0;
			box-shadow: none;
		}

		&.required-field::before {
			top: 48%;
		}

		.optimization-settings-name {
			margin-bottom: 0;
			font-size: 20px;
			font-weight: 600;
			text-align: left;
			color: var(--gray-600);
			background-color: var(--gray-100);
			border: none;

			textarea {
				background: var(--gray-100);
				border: none;
			}

			input:focus {
				box-shadow: none;
			}

			&:focus::placeholder {
				color: transparent;
			}
		}
	}

	.name-edit-icon {
		padding-top: 0;
		left: 8.33%;
		right: 8.33%;
		top: 8.33%;
		bottom: 8.33%;
		color: var(--gray-600);
		flex: 1;
	}

	.route-optimization-create-merchant-tag {
		margin-left: 10px;
	}

	.optimization-settings-description-block {
		display: flex;
		width: 30%;
		align-items: center;

		.optimization-settings-description {
			padding-left: 0;
			box-shadow: none;
			background-color: var(--gray-100);
			border: 0;
			font-size: 12px;
			line-height: 16px;
			color: var(--gray-700);

			&::placeholder {
				color: var(--gray-700);
			}

			&:focus::placeholder {
				color: transparent;
			}
		}
	}

	.description-edit-icon {
		left: 8.33%;
		right: 8.33%;
		top: 8.33%;
		bottom: 8.33%;
		color: var(--gray-500);
		flex: 1;
	}

	.optimization-settings-teams {
		margin-top: 16px;

		.input-title {
			margin-bottom: 4px;
		}
	}

	.bringg-tree-select {
		width: 400px;
	}

	.ant-select-tree-title {
		display: inline-block;
		width: 100%;
	}
}
.bringg-icon.bringg-icon-pencil.name-edit-icon {
	color: var(--gray-600);
}

.reassignable-team-entry {
	width: 100%;
	display: inline-flex;
	justify-content: space-between;

	.reassignable-team-label {
		color: $light-gray;
	}

	.reassignable-team-button {
		padding: 0;
		height: auto;
	}
}
