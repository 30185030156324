@import '../../styles/globals.scss';

.help-menu {
	display: flex;
	align-items: center;
	// since we don't have one main font in the app and don't have one main font on menu level
	// to match the design requirements this footer button should have font-family property with font from design
	// in menu three different font are used: Rubik, Source Sans Pro and now Open Sans
	font-family: 'Open Sans', sans-serif;
	margin: var(--footer-menu-item-margin);
	color: var(--gray-400);
	cursor: pointer;

	.help-menu-container {
		width: 100%;

		.help-menu-title {
			font-size: var(--footer-menu-item-text-size);
			margin-left: var(--footer-menu-item-text-left-margin);
		}
	}
}

.help-menu-overlay {
	margin-top: -66px;

	.popover-arrow {
		margin-top: 32px;
	}

	.help-menu-item-icon {
		color: $g-600;
		margin-right: 8px;
		font-size: 16px;
		width: 24px; // to have the same width as campaign icon
		padding-left: 2px;
		&.campaign {
			font-size: 24px;
			padding-left: 0px;
		}
	}

	a:hover {
		text-decoration: none;
	}

	.help-menu-item {
		color: $gray-800;
		&.campaign {
			display: flex;
		}
	}
}
