@import '../../../styles/globals.scss';

.actions-configuration-error {
	height: 18px;
	color: var(--danger-600);
	font-size: 16px;
	font-weight: normal;
	margin-top: 7px;
}

.actions-configuration-header {
	padding-top: 10px;
	padding-bottom: 7px;
	border-width: 0 0 2px;
	border-color: $light-gray;
	border-style: solid;
	z-index: 100;

	.add-button,
	.save-button,
	.cancel-button {
		width: 120px;
		background-color: $blue;
		color: white;
		margin-top: 7px;
		height: 30px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.cancel-button,
	.save-button {
		margin-left: 10px;
	}

	.cancel-button {
		background-color: $gray;
	}

	h6 {
		margin-bottom: 0px;
	}
}
