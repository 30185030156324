#application-configuration-portal > .rjsf > div:first-child {
	max-height: 70vh;
	overflow: auto;
	border-bottom: solid 1px;
}

#application-configuration-portal .spinner {
	display: flex;
	align-items: center;
	justify-content: center;
}
