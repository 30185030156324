.modalFooter {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.repeat {
		display: flex;
		gap: 5px;
	}
}
