.submitConfirmation {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
	width: 100%;

	p {
		text-align: center;
	}

	.confirmationTitle {
		font-size: 24px;
		margin-bottom: 24px;
		margin-bottom: 24px;
		font-weight: 700;
	}

	.ticketInfo,
	.confirmationMessage {
		font-weight: 600;
	}

	.ticketInfo {
		font-size: 14px;
	}

	.confirmationMessage {
		margin-top: 24px;
		margin-bottom: 80px;
	}
}
