.with-loading {
	position: relative;

	.status-icon {
		position: absolute;
	}

	.loader {
		animation: spin 1s linear infinite;

		@keyframes spin {
			from {
				transform: rotate(0);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
}
