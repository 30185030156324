@import '../../../styles/globals';

.marker-tooltip {
	width: max-content;

	.dest-marker-separator {
		widht: 100%;
		height: 1px;
		background-color: $gray;
	}

	.dest-marker-way-points {
		margin-bottom: 0;
		list-style: none;
		padding: 0;
		font-size: 12px;
		color: $black;

		.dest-marker-way-point {
			padding: 8px 8px 0 8px;
		}
	}
}
