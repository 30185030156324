.recurrence-ends-at {
	margin-top: 24px;
	display: flex;
	flex-direction: column;

	.recurrence-ends-at-radio-group {
		display: flex;
		flex-direction: column;
		margin-top: 8px;

		.recurrence-ends-at-item-on {
			display: flex;
		}
		.recurrence-ends-at-item {
			display: flex;
			align-items: center;
			width: 200px;
			margin-bottom: 8px;
			font-weight: 400;

			.recurrence-ends-at-item-on-date {
				margin-inline-start: 32px;
			}

			.recurrence-ends-at-item-after-input {
				margin-inline-start: 19px;
				margin-inline-end: 8px;
			}
		}
		.ant-radio-wrapper {
			margin-top: 8px;
			font-weight: 400;
		}
	}
}
