@import '../../../../styles/globals';

.route-optimization-range-picker {
	padding-top: 10px;

	.range-picker-slider {
		width: 448px;
		border-radius: 2px;

		.ant-slider-mark-text-active {
			color: var(--gray-600);
		}
		.ant-slider-track {
			background-color: var(--primary-400);
		}
		.ant-slider-rail {
			background-color: var(--gray-400);
		}
		.ant-slider-dot {
			width: 0px;
			border: none;
		}
		.range-picker-serif {
			border-left: 1px solid var(--gray-500);
			height: 6px;
			transform: translate(-50%, -50%);
			position: fixed;
			left: 50%;
			top: 50%;
			margin-left: 0px;
		}
		.range-picker-text {
			position: fixed;
			white-space: nowrap;
			left: 50%;
			transform: translate(-50%, 25%);
		}

		.ant-slider-mark {
			font-size: 12px;
		}
		.range-picker-serif-borders > div.range-picker-serif {
			height: 12px;
			margin-left: 3.5px;
			font-size: 12px;
		}
	}

	.ant-slider-handle {
		border: none;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		width: 20px;
		height: 20px;
		margin-top: -10px;
		z-index: 1;
	}

	.range-picker-slider {
		.ant-tooltip-content {
			.ant-tooltip-inner {
				padding: 2px 4px;
				border-radius: 4px;
				min-height: 20px;
				transform: translate(1px, 16px);
			}

			.ant-tooltip-arrow {
				display: none;
			}
		}

		.range-picker-serif-borders > div.range-picker-serif {
			margin-left: 0px;
		}
	}
}
