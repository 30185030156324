.body {
	padding-bottom: 8px;
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-right: -33px;
}
