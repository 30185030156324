.bringgGoogleMapContainer {
	height: 350px;
	width: 100%;

	:global(.gmnoprint)[role='menubar'] {
		bottom: 14px !important;
		right: 32px !important;

		ul {
			top: -33px !important;
		}
	}

	:global(.gm-style-mot) {
		margin-top: 40px;
		margin-left: 8px;
	}
}

.trafficButton {
	right: 185px !important;
	left: unset !important;
	bottom: 20px !important;
}

.mapControlButton {
	min-width: 64px;
	padding: 0 16px;
	margin: 10px 5px 0 5px;
	cursor: pointer;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
	font-size: 14px;
	height: 24px;
	line-height: 24px;

	&:hover {
		background-color: var(--gray-200);
	}

	&.first {
		margin-left: 15px !important;
	}
}
