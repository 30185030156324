.company-card {
	@import 'company-card-edit/company-card-edit.scss';
	@import 'company-card-info/company-card-info.scss';

	width: 377px;

	.company-card-label {
		margin-right: 15px;
		display: inline-block;
		min-width: 62px;
		font-size: $font-medium;
		color: $gray;
	}

	.company-card-value {
		width: 100%;
		font-size: $font-medium;
		color: $black;
	}
}
