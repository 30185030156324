@import '../../../../styles/globals.scss';
.run-history-list {
	height: calc(100vh - 330px);
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
	.ant-row {
		margin: 10px 0;
	}
	.ReactVirtualized__Grid__innerScrollContainer {
		div:last-child .history-row {
			border-bottom: none;
		}
	}
}
