.link {
	a {
		color: var(--primary-400);

		&:focus,
		&:hover {
			color: var(--primary-500);
			outline: none;
		}
	}
}
