@import '../../../styles/globals.scss';

.actions-configurations-table {
	.ant-pagination-item {
		border: 1px solid $gray;
		margin: 3px;
	}

	.ant-pagination-item-active {
		background-color: $light-blue;
		border-color: transparent;

		a {
			color: $white;
		}
	}

	.edit-button,
	.delete-button {
		padding: 0 5px;
		cursor: pointer;

		&.temporary-disabled {
			cursor: not-allowed;
			color: $gray;
		}
	}

	.edit-button {
		color: $light-blue;
	}

	.delete-button {
		color: $red;
	}

	.no-delete-button {
		padding-right: 52px;
	}

	.row-buttons {
		float: right;
	}

	.ant-table {
		background-color: $table-header-background-color;
		border: none;

		.ant-table-thead {
			th {
				box-shadow: none;
				border-color: transparent;
				height: 40px;
			}
		}
	}

	.ant-table-tbody tr:nth-child(even) {
		// override table wrapper uses different background color for even rows
		background-color: $white-background-color;
	}
}
