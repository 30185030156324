.bringgMapRoutePlayer {
	display: flex;
	flex-direction: row;
	justify-content: center;

	button {
		all: unset;
		.bringgMapRoutePlayerIcon {
			font-size: 36px;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
