.assign-component-controller {
	.driver-item {
		max-width: 120px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

.assign-component-dropdown {
	.driver-item {
		max-width: unset;
		text-overflow: unset;
		overflow: unset;
		white-space: unset;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
