.emptyStateContainer {
	display: flex;
	background: white;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	padding: 70px 0;

	.emptyStateImage {
		width: 200px;
	}

	.emptyStateTitle {
		font-size: 28px;
		font-weight: 600;
		line-height: 24px;
		margin-top: 31px;
		font-family: 'Open Sans', sans-serif;
	}

	.emptyStateText {
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		margin-top: 16px;
		font-family: 'Open Sans', sans-serif;
		white-space: pre-wrap;
		text-align: center;
	}
}
