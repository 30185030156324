@import '../../styles/globals';

@mixin base-back {
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 51, 80, 0.16);
}

.delivery-catalog-connect {
	.delivery-catalog-connect-tabs {
		.ant-tabs-content {
			height: 100%;
		}

		.bringg-icon-warning {
			color: #f98560;
			margin-right: 3px;
			vertical-align: middle;
		}

		.ant-tabs-content-holder {
			max-height: 100%;
		}
	}

	&.singleton {
		padding: 15px 0;

		.ant-layout {
			height: calc(100vh - 30px);
		}

		.delivery-catalog-connect-tabs {
			& > .ant-tabs-content-holder {
				// 140px - height of bottom section with user hint
				height: calc(100vh - 140px);
			}
		}

		.delivery-catalog-connect-menu {
			height: 100%;
		}
	}

	.ant-tabs {
		overflow: inherit;
	}

	.ant-layout {
		background: transparent;

		&-sider {
			background-color: transparent;
		}

		.conditions-set {
			width: 100%;
		}
	}

	&-tabs {
		& > .ant-tabs-nav {
			display: flex;
			align-items: center;

			height: 92px;
			border-top: 0 !important;
			margin-bottom: 17px !important;
			padding: 0 24px;
			@include base-back;

			.ant-tabs-nav-list {
				display: flex;
				align-items: center;

				.ant-tabs-tab {
					margin: 0;
					color: var(--gray-600);
					font-size: 14px;
					font-family: 'Open Sans', sans-serif;

					&.ant-tabs-tab-disabled {
						color: var(--gray-400);
					}

					&:first-child {
						.ant-tabs-tab-btn {
							border-left: none;
						}
					}

					.ant-tabs-tab-btn {
						height: 22px;
						border-left: 1px solid var(--gray-600);
						padding-left: 12px;
					}

					&.ant-tabs-tab-active .ant-tabs-tab-btn {
						color: var(--primary-400);
					}

					&:after {
						content: '';
						display: block;
						height: 100%;
						width: 1px;
						margin-left: 12px;
						background-color: var(--gray-600);
					}
				}

				.ant-tabs-ink-bar {
					display: none !important;
				}
			}
		}

		& > .ant-tabs-content-holder {
			position: relative;
			height: calc(100vh - 243px);
			@include base-back;
		}
	}

	&-name {
		display: flex;
		align-items: center;

		&-id {
			font-size: 12px;
			color: #a0aec0;
			margin-left: 16px;
			margin-top: 2px;
		}

		&-input {
			input {
				margin: 0;
			}

			margin-right: 16px;
		}

		&-logo {
			width: 52px;
			height: 52px;
			min-width: 52px;
			margin-right: 16px;
			background-size: 52px;
			overflow: hidden;
			border-radius: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-color: white;
			box-shadow: 1px 0 8px rgba(96, 97, 112, 0.08), 1px 0 8px rgba(96, 97, 112, 0.08);
		}

		&-title {
			display: flex;
			align-items: center;

			&:hover {
				svg {
					visibility: visible;
				}
			}

			&-disabled {
				button {
					cursor: default;
				}

				&:hover {
					svg {
						visibility: hidden;
					}
				}
			}

			button {
				display: flex;
				align-items: center;
				padding: 0;
				font-family: 'Open Sans', sans-serif;
				font-size: 18px;
				font-weight: 600;
				color: var(--gray-800);
				margin-right: 16px;

				&:hover {
					background: transparent;
				}
			}

			svg {
				visibility: hidden;
				margin-left: 8px;
			}
		}
	}

	&-menu {
		height: calc(100vh - 213px);
		padding-right: 0 !important;
		margin-right: 16px;
		overflow: auto;

		@include base-back;

		.ant-menu {
			.ant-menu-item {
				display: flex;
				margin: 0;
				height: 48px;
				padding: 0 8px;

				&-selected {
					background-color: #eff7fd;
					border-left: 4px solid var(--primary-400);
					border-radius: 4px;

					.delivery-catalog-connect-menu-name {
						color: var(--primary-400);
						font-weight: 500;
					}
				}

				.ant-menu-title-content {
					display: flex;
					align-items: center;
					width: 100%;
				}
			}
		}

		&-info {
			display: flex;
			align-items: center;
			width: 100%;
			overflow: hidden;
		}

		&-tag {
			display: flex;
			align-items: center;
			height: 16px;
			font-size: 12px;
			border-radius: 2px;
			color: white;
			padding: 0 4px;
			background-color: var(--warning-600);
			margin-left: 12px;

			&-drafts {
				background-color: transparent;
				color: var(--gray-600);
			}

			&-inactive {
				background-color: var(--gray-200);
				color: var(--gray-500);
			}

			&-active {
				background-color: var(--success-400);
			}

			&-failed {
				background-color: var(--color-alert);
			}
		}

		&-logo {
			width: 24px;
			min-width: 24px;
			height: 24px;
			overflow: hidden;
			border-radius: 100%;
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: center;
			background-color: white;
			margin-right: 12px;
		}

		&-name {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		span {
			color: var(--gray-600);
		}

		&-staging {
			font-size: 12px;
		}
	}

	&-more {
		margin: 36px auto;
		text-align: center;
		font-family: 'Open Sans', sans-serif;

		p {
			color: var(--gray-800);
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 0;
		}

		.ant-btn {
			height: 24px;
			font-size: 14px;
			line-height: 24px;
			padding: 0;
		}

		.ant-btn span {
			color: var(--primary-400);
		}
	}

	&-env {
		.ant-radio-wrapper {
			font-weight: normal;
			color: var(--gray-800);
		}
	}

	&-form {
		.ant-divider-vertical {
			height: 100%;
		}

		.ant-divider-horizontal {
			width: auto;
			min-width: auto;
			margin-left: -24px;
			margin-right: -24px;
		}

		.divider-wrapper {
			padding: 24px 0;
		}

		.ant-form-item {
			flex-direction: column;
			margin: 0 0 14px 0;

			&:last-of-type {
				margin: 0;
			}

			.ant-switch-checked {
				background-color: var(--primary-400);
			}

			.ant-radio-inner {
				&:after {
					background-color: var(--primary-400);
				}
			}

			.ant-form-item-label {
				text-align: left;
				padding-bottom: 0;

				& > label {
					display: inline;
					font-weight: normal;
					font-size: 14px;
					height: auto;
					color: var(--gray-700);

					&:after {
						display: inline;
						content: ':';
					}
				}
			}
		}

		.ant-form-item-control {
			min-height: auto;
		}

		/**
    Fixed weird input backgrounds
     */
		input[type='text']:disabled,
		input[type='number']:disabled,
		input[type='password']:disabled,
		.ant-input-number-disabled,
		.ant-input-affix-wrapper-disabled {
			background-color: transparent;
			color: rgba(0, 0, 0, 0.25) !important;
		}

		.ant-input-affix-wrapper-disabled {
			border: 1px solid #e1e7ee;
		}
	}

	&-inputs {
		max-width: 330px;
		padding-right: 30px;

		.ant-form-item-row {
			display: flex;
			flex-direction: column;
		}

		.ant-input-number {
			width: 100%;
			border-radius: 4px;

			&:hover {
				border-color: var(--gray-400);
			}

			.ant-input-number-handler-wrap {
				display: none;
			}

			.ant-input-number-input-wrap {
				input {
					margin: 0;
					border: 0;
					height: 34px;
					color: var(--gray-800);
				}
			}
		}

		.ant-input-password {
			input {
				font-size: 15px;
				margin: 0;
			}
		}
	}

	&-description {
		font-size: 12px;
		line-height: 24px;
		background-color: #eff4f8;
		padding: 10px 13px 10px 13px;
	}

	&-description a {
		color: var(--primary-400);
	}

	&-validated {
		.ant-form-item-has {
			&-error .ant-input {
				border-color: var(--color-alert);
			}
		}
	}

	.ant-tabs .ant-tabs-tab-disabled {
		color: var(--gray-400);

		&:hover {
			color: var(--gray-400);
		}
	}

	&-block {
		h3 {
			color: var(--gray-800);
			font-size: 16px;
			margin-top: 0;
			margin-bottom: 5px;
		}

		p {
			color: var(--gray-700);
			margin-bottom: 24px;
			line-height: 28px;

			.ant-btn-link {
				color: var(--primary-400);
				padding: 0;
				min-width: 0;
			}
		}

		& + & {
			margin-top: 24px;
		}

		.ant-btn-primary {
			&:not([disabled]) {
				background: var(--primary-400);
				border-color: var(--primary-400);
			}

			&.ant-btn-dangerous {
				background: var(--danger-600);
				border-color: var(--danger-600);
			}
		}

		&-vertical-divider {
			height: 100%;
			width: 1px;
			background: #e1e7ee;
		}
	}

	&-body {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		bottom: 72px;
		padding: 24px;
		overflow: auto;
	}

	&-footer {
		box-shadow: 0 -4px 8px 0 rgba(0, 51, 80, 0.08);
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 16px 24px;

		.ant-btn {
			font-family: 'Open Sans', sans-serif;
			font-weight: 600;
			border-radius: 4px;
			height: 40px;
			padding: 0 24px;
			color: $primary;
			border: none;
			box-shadow: none;

			&:disabled {
				color: #fff;
			}
		}

		.ant-btn-primary {
			background-color: $primary;
			color: #fff;

			&:disabled {
				background-color: #cbd5e0;
			}
		}

		.ant-btn-text {
			&:disabled {
				color: rgba(0, 0, 0, 0.25);
			}
		}

		&-loader {
			span {
				font-size: 16px;
				color: $g-600;
				margin-left: 4px;
			}
			.bringg-icon {
				font-size: 16px;
			}
		}
	}

	&-status {
		ul {
			display: flex;
			list-style-type: none;
		}

		span {
			font-family: 'Open Sans', sans-serif;
			font-weight: 600;
			font-size: 12px;
			color: #a0aec0;
		}

		li {
			position: relative;
			margin-top: 10px;

			&:nth-child(1) {
				width: 196px;
			}

			&:nth-child(2) {
				width: 242px;
			}

			&:last-of-type {
				.delivery-catalog-connect-status-circle:after {
					display: none;
				}
			}
		}

		&-active {
			span {
				color: var(--gray-700);
			}

			.delivery-catalog-connect-status-circle {
				background-color: var(--primary-400);
			}
		}

		&-circle {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			color: var(--white-text);
			background-color: #d7e0e9;
			border-radius: 100%;
			font-size: 12px;

			&:after {
				display: block;
				content: '';
				position: absolute;
				background-color: var(--gray-400);
				height: 1px;
				top: 12px;
				left: 32px;
				right: 8px;
			}
		}
	}

	&-progress {
		h3 {
			color: var(--gray-800);
			font-size: 14px;
			margin-top: 5px;
		}

		&-item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 32px;
			height: 32px;
			border-radius: 100%;
			border: 1px solid var(--gray-500);
			color: var(--gray-600);
			margin-right: 8px;
			font-weight: 500;

			&:after {
				display: block;
				content: '';
				width: 1px;
				height: 8px;
				background-color: var(--gray-400);
				position: absolute;
				bottom: -10px;
			}
		}

		&-row {
			display: flex;
			align-items: center;
			color: var(--gray-700);
			font-size: 12px;
			margin-bottom: 10px;
			white-space: nowrap;

			&:last-of-type {
				.delivery-catalog-connect-progress-item:after {
					display: none;
				}
			}

			&-done {
				.delivery-catalog-connect-progress-item {
					border-color: var(--success-50);
					background-color: var(--success-50);
					color: var(--success-400);
				}
			}

			&-in-progress {
				.delivery-catalog-connect-progress-item {
					border-color: var(--primary-400);
					background-color: var(--primary-400);
					color: white;
				}
			}
		}
	}
}
