.deliveryWindowsModalWrapper {
	.deliveryWindowsModal {
		top: 40px;
	}
	:global {
		.ant-modal {
			color: var(--gray-800);
		}
	}
}
