@import '../../styles/globals';

.service-area-info-popup {
	.info-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.bringg-btn {
			font-size: 14px;
			font-weight: 600;
		}
	}

	.info-name {
		font-size: 16px;
		font-weight: 600;
		max-width: 300px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
