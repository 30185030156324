$timeline-axis-color: #494949;
$timeline-axis-box-shadow: 0px 1px 0px #e6e6e6;
$timeline-axis-text-color: #c4c4c4;
$timeline-axis-scale-lines-color: #878787;
$zebra-stripes-grid-light: #ffffff;
$zebra-stripes-grid-dark: #f9f9f9;
$group-rows-text: #000000;
$headers-text-color: #ffffff;
$items-text-color: rgb(255, 255, 255);
$timeline-icons-width: 47px;
$space-between-cells: 8px;

@mixin timeline_font_props() {
	font-family: 'Open Sans', 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

@mixin cell-limit() {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.vis-time-axis.vis-foreground {
	background: $timeline-axis-color;
	box-shadow: $timeline-axis-box-shadow;

	.vis-text.vis-minor {
		border-right: 1px solid $timeline-axis-scale-lines-color;
	}

	.vis-text {
		color: $timeline-axis-text-color;
	}
}

.vis-label {
	// align row content in center
	display: flex;
	align-items: center;
	flex: 1;
}

@mixin late() {
	&::before {
		font-family: 'bringg-font-icons';
		color: #f33;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		left: -6px;
		top: 50%;
		transform: translateY(-6px);
		background: #fff;
		border-radius: 50%;
		width: 10px;
		height: 10px;
	}
}
