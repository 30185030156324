@import '../../styles/globals.scss';

.custom-attributes {
	margin: 46px 9px 0 16px;
	font-family: 'Open Sans', sans-serif;

	&-header {
		h4 {
			margin: 0;
			margin-bottom: 3px;
			font-size: 20px;
			font-weight: $font-weight-semi-bold;
			line-height: 1.2;
			color: $gray-800;
		}

		p {
			line-height: 1.43;
			color: $g-600;
		}
	}

	.read-only-message {
		margin: 32px 0 30px 0;

		.message {
			margin-right: auto;
		}
	}

	.read-only-message ~ .custom-attributes-content {
		//TODO: after merchant-config refactor the table height issue should be fixed other way
		.scroll-wrapper {
			max-height: calc(100vh - 520px);
		}
	}

	&-content {
		&-actions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;

			.custom-attributes-filter {
				width: 100%;
				margin-right: 20px;
			}

			.create-new-attr {
				display: inline-flex;
				align-items: center;
				height: 40px;
				box-shadow: none;

				.bringg-icon.bringg-icon-plus {
					color: $white;
					font-size: 20px;
				}
			}

			.no-attributes-message {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				padding: 8px 16px;
				margin-right: 65px;
				border-radius: 4px;
				background-color: $light-gray;
				color: $g-600;

				p {
					margin: 0;
				}

				span {
					color: $g-700;
					font-weight: $font-weight-medium;
				}
			}
		}

		&-table {
			border-radius: 4px;
			box-shadow: 0px 0px 1px rgba(0, 51, 80, 0.24), 0px 1px 2px rgba(0, 51, 80, 0.16);

			.scroll-wrapper {
				display: flex;
				width: 100%;
				//TODO: after merchant-config refactor the table height issue should be fixed other way
				max-height: calc(100vh - 430px);

				.spinner-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
				}

				.react-table-wrapper {
					width: 100%;
					border: none;
					overflow: auto;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;

					.attribute-marker {
						width: 6px;
						height: calc(100% + 1px);

						&::before {
							content: '';
						}

						&.active {
							background-color: var(--success-400);
						}
						&.suggested {
							background-color: $light-gray-2;
						}
					}

					.table-row {
						.table-data-cell:first-child {
							padding: 0;

							.cell-data {
								height: 100%;
							}
						}

						.required-attribute-icon {
							font-size: 16px;
							color: var(--success-400);
						}
					}

					.text-center {
						width: 100%;
						text-align: center;
					}

					.table-header-cell:first-child {
						padding: 0;
					}

					.table-header-cell:nth-child(2) {
						.header-wrapper {
							justify-content: center;
						}
					}

					.table-row {
						.attribute-actions .hidden-action-item {
							visibility: hidden;
							transition-duration: 0s;
						}

						&:hover {
							.hidden-action-item {
								visibility: visible;
							}
						}
					}
				}
			}

			.table-summary {
				&-divider {
					width: 100%;
					height: 8px;
					background-color: $super-light-blue-1;
				}

				&-text {
					background-color: $white;
					padding: 8px 24px;
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;

					span:first-child {
						color: $g-600;
						margin-right: 4px;
					}

					span {
						line-height: 2;
					}
				}
			}
		}
	}
}
