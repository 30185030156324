@import '../../../../styles/globals.scss';

.delivery-blocks-event-content {
	padding: 5px;
	margin-top: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	.delivery-block-event-content-title {
		font-weight: bold;

		.white-title {
			color: $white;
		}

		.gray-title {
			color: $gray;
		}
	}

	.delivery-blocks-event-content-capacity {
		display: flex;
		padding-bottom: 3px;
		.delivery-block-event-content-capacity-count {
			padding: 1px 6px;
		}

		.capacity-counter-full {
			color: $white;
		}
	}

	.delivery-blocks-event-content-time {
		font-size: $font-xs;
		@include ellipsis_text;
	}
}
