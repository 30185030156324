@import '../../../styles/globals.scss';

.run-table {
	max-height: 300px;
	overflow-y: auto;
	font-size: $font-small;

	.ant-table {
		min-height: 25vh;
	}

	&-task-id {
		width: 80px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
