.service-area-select-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-grow: 4;

	.service-area-select-circle {
		border: 1px solid;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		margin-top: 3px;

		&.service-area-item-selected {
			margin-top: 7px;
		}
	}

	.service-area-select-title {
		font-family: Rubik, sans-serif;
		font-weight: 400;
		font-size: 13px;
		padding-left: 4px;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 230px;
		margin-left: 4px;
	}
}

.team-page-container {
	.service-area-select {
		width: 300px;
	}
}
