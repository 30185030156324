.mapbox-legend-container {
	position: absolute;
	top: 30px;
	right: 230px;
	opacity: 0.7;
	width: 200px;
	height: fit-content;
	z-index: 1;
	transition: opacity 0.3s;
	box-sizing: border-box;
	border-radius: 6px;

	&:hover {
		opacity: 0.9;
	}

	.map-legend-select {
		width: 100%;
	}
}
