.user-types-container {
	@import './user-types-header/user-types-header';
	@import '../../styles/globals.scss';
	@include basic_font_props;
	margin-top: $default-margin;

	.user-types-table-container {
		.user-types-table {
			width: 100%;
		}

		.total-count {
			margin-top: 10px;
		}
	}
}
