@import '../../../styles/globals.scss';

.message-block {
	position: relative;
	margin: 0 15px;

	.user-info {
		position: absolute;
		top: -17px;

		.user-name {
			color: $black;
			font-size: 14px;
		}

		.message-time {
			font-size: 12px;
			color: $gray;
		}
	}

	.message {
		border-radius: 10px;
		display: inline-block;
		padding: 10px;
		margin-bottom: 30px;
		margin-top: 7px;
	}

	&.left {
		text-align: left;

		.user-info {
			left: 0;
		}

		.user-name {
			margin-right: 8px;
		}

		.message {
			background-color: $darker-white;
			color: $dark;
			margin-left: 10px;
		}
	}

	&.right {
		text-align: right;

		.user-info {
			right: 0;
		}

		.user-name {
			margin-left: 8px;
		}

		.message {
			background-color: $dark;
			color: $white;
			margin-right: 10px;
		}
	}

	img {
		width: 30px;
		height: 30px;
		border: solid 5px $white;
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		border-radius: 50px;
	}
}
