// once react-app is no longer under Angular, it's better to import antd.css from here
// @import '~antd/dist/antd.css';
@import './globals.scss';

form {
	.ant-select {
		width: 100%;
	}
}

.radio,
.checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
}

form input[type='checkbox'],
form input[type='radio'] {
	width: 14px;
	height: 14px;
}

.ant-picker-dropdown {
	td,
	th {
		text-align: center;
	}
}

// styles commonly applied to ant components in billing-app project
// https://bringg.atlassian.net/browse/APP-255 designs also use those styles
.ant-dropdown > .ant-menu.ant-menu-root {
	border: 1px solid #e1e1e1;
	border-radius: 4px;
	overflow: auto;
	box-shadow: -1px 7px 13px rgba(black, 0.25);
}

.ant-menu-inline .ant-menu-item:after {
	left: 0;
	right: unset;
}

.ant-table-container {
	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: $primary;
	}

	.ant-checkbox-indeterminate .ant-checkbox-inner {
		background-color: $primary;
	}
	.ant-checkbox-indeterminate .ant-checkbox-inner:after {
		width: 10px;
		height: 3px;
		background-color: white;
	}
}
