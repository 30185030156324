.rateCardCsvHeader {
	padding: 0 24px;
	margin-top: 18px;
	margin-bottom: 12px;

	& + .rate-card-term-wrapper {
		margin-top: -12px !important;
	}
}

.rateCardCsvModal {
	h3 {
		font-size: 20px;
		color: var(--gray-800);
		font-weight: 600;
	}

	h4 {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 600;

		:global {
			.bringg-icon {
				font-size: 24px;
				margin-right: 8px;
				color: var(--gray-600);
			}
		}
	}

	:global {
		.ant-modal-content {
			border-radius: 8px;

			.ant-modal-title {
				font-weight: 600;
				font-size: 20px;
			}

			.ant-modal-header {
				padding-left: 24px;
				padding-right: 24px;
				padding-bottom: 0;
			}

			.ant-modal-body {
				padding-left: 24px;
				padding-right: 24px;
			}

			.ant-modal-footer {
				padding-right: 24px;
			}
		}
	}

	&Loader {
		display: flex;
		font-size: 16px;
		padding-top: 24px;
		padding-bottom: 24px;
		padding-left: 12px;
		color: var(--gray-600);

		:global {
			.spinner-wrapper {
				margin-right: 24px;
			}
		}
	}

	&Body {
		padding: 0 32px;
	}

	&Description {
		color: var(--gray-600);
		font-size: 14px;
	}

	&Dragger {
		margin-top: 16px;
		margin-bottom: 16px;
	}
}
