@import '../../styles/globals.scss';

.vehicle-profiles-page-wrapper {
	font-family: 'Open Sans', 'Rubik', sans-serif;

	.vehicle-profiles-vehicle-category {
		display: flex;
		align-items: baseline;

		&-position {
			margin-left: auto;
		}
		&-tooltip {
			color: $g-600;
		}
	}

	.vehicle-profiles-page--create-button-wrapper {
		display: flex;
		justify-content: flex-end;
		margin: 16px 0;

		.create-new-btn {
			height: 40px;
			width: 84px;
		}
	}

	.table-action-column {
		width: 60px;
	}

	.vehicle-profiles-page--filter-bar-wrapper {
		margin-bottom: 16px;
	}

	.vehicle-profiles-page--bulk-delete-button-wrapper {
		margin-bottom: 8px;

		.vehicle-profiles-page--bulk-delete-button {
			border: 1px solid #e1e9f0;
			background: unset;
			color: var(--gray-700);
			font-weight: 500;
			width: 93px;
			height: 24px;
			border-radius: unset;
		}

		.vehicle-profiles-page--bulk-delete-button:disabled,
		.vehicle-profiles-page--bulk-delete-button[disabled] {
			color: #cbd5e0;
			background: unset;
			border: 1px solid #e1e9f0;
		}
	}

	.vehicle-profiles--delete-title {
		font-weight: 600;
		font-size: 18px;
	}

	.vehicle-profiles--empty-table-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 16px;
		gap: 8px;
		background: var(--primary-10);
		width: fit-content;
		margin: 3rem auto;
		line-height: 38px;
	}

	.vehicle-profiles-page--table {
		.vehicle-profiles-page--actions-button,
		.vehicle-profiles-page--actions-button:hover,
		.vehicle-profiles-page--actions-button[hover] {
			background: unset;
			color: #718096;
			font-size: 25px;
			padding: unset;
			margin: 0 auto;
			padding-left: 16px;
		}

		.vehicle-profiles-page--eco-icon {
			color: #00c364;
			font-size: 22px;
		}
	}

	.vehicle-profiles-page--filter-bar {
		height: 56px;
	}
}
