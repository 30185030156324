@import '../../../../styles/globals';

.available-fleet-form {
	padding: 18px 24px;
	display: flex;
	flex-direction: column;

	.available-fleets {
		background-color: $white;
		padding: 18px 24px;

		.fleet-checkbox {
			color: $light-blue;
			font-weight: normal;
			margin-bottom: 5px;
			font-size: $font-small;
		}

		.available-fleets-header {
			height: 100%;
			height: 30px;
			border-bottom: 1px solid $light-gray;
			margin-bottom: 20px;
		}

		.available-fleets-title {
			font-weight: normal;
		}

		.available-fleets-group {
			width: 100%;
		}

		.select-buttons {
			float: right;
			color: $light-blue;
		}

		.select-all,
		.clear-all {
			cursor: pointer;
		}

		.select-separator {
			color: $gray;
			padding: 0 5px;
		}
	}
}
