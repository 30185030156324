@import '../../../../styles/globals';
@import 'breaks/delivery-block-breaks';

.delivery-block-form {
	overflow: scroll;
	display: flex;

	.delivery-block-form-left-col,
	.delivery-block-form-right-col {
		@include delivery-block-modal-column;
	}

	.delivery-block-form-left-col {
		width: 100%;

		.time-range {
			display: inline-block;
			width: 90px;
		}

		.ant-form-item {
			margin-bottom: 12px;
		}
	}

	.delivery-block-form-right-col {
		border-left: 1px solid #cccccc;
	}

	.ant-form-item-label {
		text-align: left;
		label {
			color: $gray;
		}
	}

	.ant-time-picker {
		width: 90px;
	}

	.ant-form-item-control {
		line-height: 15px;
	}

	.has-error {
		.ant-form-explain {
			font-size: 12px;
		}
	}

	.time-range-separator {
		display: inline-block;
		width: 57px;
		text-align: center;
		height: 32px;
		padding-top: 9px;
	}

	.capacity-container {
		width: 100%;
		input {
			border: none;
			padding: 0px 11px;
		}
	}
}
