.tableContainer {
	display: flex;
	overflow-x: hidden;

	// Only show 10 rows, and then scroll
	max-height: 290px;
}

.failureTable {
	border-collapse: separate;

	font-size: 14px;
	margin-left: 2px;

	width: 100%;

	color: var(--gray-700);
	font-weight: 400;

	position: relative;

	.failureTableHeader {
		font-weight: 600;

		th {
			border-bottom: 1px solid var(--gray-400);
			background: white;
			position: sticky;
			top: 0;

			text-align: start;
			padding-left: 4px;
			white-space: nowrap;

			&:first-child {
				text-align: end;
				padding-right: 8px;

				min-width: 50px;
				width: 50px;
				max-width: 50px;
			}

			&:not(:last-child):after {
				content: ' ';

				height: 16px;
				width: 1px;
				background: var(--gray-400);

				position: absolute;
				right: 0;
				top: calc(50% - 8px);
			}
		}
	}

	tbody {
		overflow: auto;
	}

	tbody tr {
		.failureReasonIcon {
			font-size: 16px;
			color: var(--danger-400);
			margin-right: 4px;
		}

		td {
			white-space: nowrap;
			border: 0;

			&:first-child {
				text-align: end;
				padding-right: 8px;
			}

			&:not(:first-child) {
				padding-left: 8px;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 0;
			}
		}
	}
}
