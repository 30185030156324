.store-app-settings-container {
	padding: 10px;

	.store-app-settings-section {
		margin-bottom: 16px;

		&-title {
			border-bottom: 1px solid #d3d3d3;
			padding: 10px 0;
			font-size: 16px;
			font-weight: 500;
			color: #3f3f3f;
		}

		&-content {
			padding: 10px;
			display: flex;
			flex-direction: column;
			@import './configurations-sections/notifications/notifications-configuration';
		}

		.store-app-settings-checkbox {
			font-weight: 500;
			margin: 10px 0;
		}
	}
}
