.recurring-options-modal {
	top: 260px;
	left: 50px;
	.ant-radio-wrapper {
		font-weight: 400;
	}
	.ant-btn {
		border-radius: 4px;
	}
	.ant-btn-primary {
		background-color: var(--primary-400);
	}
}
