.delivery-block-modal-footer {
	display: flex;
	justify-content: space-between;

	.delivery-block-modal-footer-delete {
		float: left;
		margin-left: 28px;

		&.visibility-hidden {
			visibility: hidden;
		}
	}

	.delivery-block-modal-footer-ok-and-cancel {
		float: right;
		margin-right: 32px;
	}
	&.disabled {
		pointer-events: none;
	}
}
