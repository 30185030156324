@import '../../../styles/globals.scss';

.action-form-data {
	.title-row {
		flex-direction: column;
		margin-top: 5px;
		margin-bottom: 20px;
	}

	.field-panel-header {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.ant-collapse-borderless {
		background-color: transparent;
	}

	.ant-collapse-borderless > .ant-collapse-item {
		background-color: transparent;
		border-bottom: none;
	}

	.ant-collapse-content > .ant-collapse-content-box {
		padding: 9px;
	}

	.delete-form-icon {
		color: $gray;
		float: right;
	}

	.draggable-form-icon {
		color: $gray;
	}

	.header-title {
		display: flex;
		align-items: center;
		background-color: $gray-3;
		color: $black;
		border: 1px solid $light-gray-2;
		border-radius: 5px;
		height: 30px;
		padding: 0 10px;
		white-space: nowrap;
	}

	.add-value {
		margin: 25px 0 10px 50px;
		color: $light-blue;
		cursor: pointer;
		font-size: $font-xs;
	}

	.collapse-icon {
		margin-left: 10px;
		color: $black;
	}
}
