@import '../../styles/globals';

.menu-pencil {
	margin-right: 5px;
}
.menu-trash {
	margin-right: 5px;
}

.service-plans-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 32px;
	margin-bottom: 8px;

	.search-service-plan {
		width: 200px;

		.ant-input {
			margin: 0;
		}
	}
}

.service-plan-edit-button {
	color: var(--gray-700);
}

.no-service-plans-container {
	position: relative;
}

.no-service-plans {
	padding: 24px;
	position: absolute;
	margin-top: 140px;
	top: 41%;
	left: 20%;
	right: 20%;
	text-align: center;
	background: #eff4f8;
	border-radius: 4px;

	span {
		font-size: 18px;
		font-weight: 300;
	}
}

.service-plan {
	.service-plans-table {
		.ant-table-thead > tr > th {
			font-weight: 300;
		}
	}

	.ant-btn.service-plan-action-button {
		color: var(--gray-600);
	}
}
