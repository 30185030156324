@import './styles/mixins.scss';

.draggableListPortal {
	z-index: 10000;
	position: relative;
}

.draggableListSelector {
	display: flex;
	flex-direction: column;
	width: 300px;
	background: var(--colors-whites-bg);
	padding-bottom: 8px;

	.list {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: auto;
	}

	.collapse {
		padding: 4px 8px;
		max-height: 75vh;
		overflow: auto;
	}

	.collapsePanel {
		margin-bottom: 8px;

		.collapseHeader {
			height: $row-height;
			font-size: 10px;
			padding: 8px 12px !important;
			border-radius: 4px !important;
			transition: none;

			&:hover {
				background: var(--gray-100);
			}
		}

		.collapseArrow {
			transition: transform 0.25s;
			font-size: 14px !important;
			margin-top: -4px;
		}
	}
	.glow {
		border-radius: 4px !important;
		animation: glow 0.5s;
	}
}

.divider {
	margin: 4px 8px;
	border-top: 1px solid;
	border-color: var(--gray-200);
}

@keyframes glow {
	40% {
		box-shadow: 0 0 0 2px rgba(6, 126, 186, 0.2);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(6, 126, 186, 0.2);
	}
}
