@import '../../styles/theme.scss';

.group-headers-component {
	&.headers-container {
		background-color: $timeline-axis-color;
		box-sizing: border-box;
		box-shadow: $timeline-axis-box-shadow;

		display: flex;
		flex-direction: row;
		align-items: center;
		border: 0;
	}

	.header {
		@include cell-limit;
		color: $headers-text-color;
		margin-left: $space-between-cells;
	}
}

.vis-panel.vis-top {
	border-left-color: $timeline-axis-color;
}
