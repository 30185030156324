@import '../../../styles/globals.scss';

.run-map {
	border: 1px solid $border-color;

	.gm-style-mtc,
	.gm-fullscreen-control {
		display: none;
	}

	.map-container {
		height: 602px;
		width: 100%;
	}

	.map-element {
		height: 100%;
	}
}
