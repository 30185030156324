@import '../../styles/globals.scss';
@import '../../styles/theme.scss';

.group-row-cells-component {
	&.row-container {
		color: $group-rows-text;
		font-size: $font-small;
		padding: 6px $space-between-cells;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;

		& .cell {
			@include cell-limit;
			margin-right: $space-between-cells;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
