.company-card-info {
	.company-edit {
		display: flex;
		align-items: center;
		cursor: pointer;
		text-align: right;
		color: $light-blue;

		span {
			margin-right: 5px;
			font-size: $font-small;
		}
	}

	.info-block {
		display: flex;
		margin-bottom: 18px;

		.company-edit {
			margin-left: auto;
		}
	}
}
