.bringgMapRoutePlayerSlider {
	z-index: 1;
	margin-left: 40px;
	margin-right: 40px;

	.ant-slider-track {
		background-color: var(--primary-400);
	}

	.ant-slider-handle {
		border: solid 2px var(--primary-400);
	}

	.ant-tooltip-open {
		background-color: var(--primary-400);
	}
}

.bringg-map-container {
	.ant-tooltip-content {
		white-space: nowrap;

		.ant-tooltip-arrow-content {
			background-color: var(--primary-400);
			--antd-arrow-background-color: var(--primary-400);
		}

		.ant-tooltip-inner {
			text-align: center;
			padding: 12px;
			background-color: var(--primary-400);
		}
	}
}
