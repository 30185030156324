@import '../../styles/globals.scss';

.login-page-wrapper {
	background-color: $bringg-background;
	height: 100vh;

	.black-block {
		margin-top: 20px;
		width: 30.7px;
		height: 3px;
		border: solid 8px $black;
		display: inline-block;
	}

	.multi-merchant {
		margin-bottom: 20px;
		border: none;
	}

	.forgot-password-link {
		color: $bringg-orange;
	}

	.ant-input:hover {
		border-color: #cacaca;
	}

	.ant-input {
		color: $black;
	}

	.login-form-wrapper {
		height: 35vh;
		width: 80vh;
		padding-top: 30px;
		background-color: $white;
	}

	.login-button {
		background-color: $bringg-orange;
		border: 1px solid $bringg-orange;
		width: 100%;
		height: 48px;
		border-radius: 0;
	}

	.login-body {
		height: 320px;
	}

	.login-header {
		background: none;
		width: 100%;
		padding-top: 24px;
		color: $black;
		font-family: $default-font;
		line-height: 42px;

		div {
			text-transform: none;
			font-size: 22px;
		}
	}

	.login-footer {
		position: absolute;
		width: 100%;
		bottom: 0;
	}

	.login-title {
		text-align: left;
		font-family: $default-font;
		font-size: 23px;
		margin-bottom: 28px;
	}
}

@media (min-width: 767px) and (min-height: 500px) {
	.login-card {
		padding: 150px;
		max-width: 710px;
		margin: 0 auto;

		.login-title {
			text-align: center;
		}

		.login-body {
			margin-bottom: 80px;
		}

		.ant-card-body {
			height: 507px;
		}
	}
}
