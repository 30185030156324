@import '../../styles/globals';
@import '../../components/language-select/language-select';
@import 'notification-template/notification-template';
@import 'notification-template-preview/notification-template-preview';
@import 'merchant/merchant-customer-notification-container';

#tags-customer-configuration,
#merchant-customer-configuration {
	.fetched-data-spinner {
		margin-top: 20%;
	}
}

.customer-notifications {
	.body {
		@include basic_font_props;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;

		.sharing-mode-select {
			width: 350px;
		}

		.title {
			color: $primary-color;
			font-size: 18px;
			border-bottom: 1px solid $border-color;
			padding: 6px 0;
			display: block;
			margin-top: 10px;
			margin-bottom: 25px;
		}

		.notification-templates-container {
			padding: 4px;
			width: 60%;
			position: relative;
			padding-bottom: 100px;
			background-color: $background-secondary-color;
		}

		.notification-template-container {
			margin: 5px 5px 20px 5px;
			padding: 0 15px 15px 15px;
			background-color: $white;
			border: 1px solid $border-color;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.28);
			-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.28);
			-moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.28);
			border-radius: 4px;
		}

		.language-select {
			width: 200px;
			margin: 25px 0 25px 5px;

			.name {
				padding-top: 3px;
			}
		}

		.customer-notification-settings {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-top: 1px solid $border-color;
			color: $light-gray-3;
			padding: 25px 10px;

			.customer-notification-setting {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0px;
				}

				.setting-checkbox {
					margin-right: 8px;
				}
			}
		}

		.sharing-mode {
			padding-bottom: 25px;
			border-bottom: 1px solid $border-color;
			.select-wrapper {
				margin-left: 5px;
			}
		}

		.update-button {
			@include legacy_button;
			margin-top: 40px;
		}

		.delete-button {
			color: #fff;
			background-color: $light-red-2;
			border-color: $light-red-2;
			margin-left: 5px;
		}
	}

	.enable-options {
		.enable-option {
			.checkbox-template {
				padding: 5px 0;

				.ant-checkbox-wrapper {
					padding: 0;
				}

				.label {
					font-size: 15px;
					font-weight: 400;
					color: $light-gray-3;
					margin-top: 5px;
					padding-left: 7px;
				}
			}

			.radio-button {
				padding: 5px 0;
			}

			.input-number {
				width: 60px;
				margin: 0 10px;
			}
		}
	}

	.header {
		font: inherit;
		margin-bottom: 20px;
		font-weight: 300;
		font-size: 30px;
	}
}

.tags-customer-notifications {
	&.customer-notifications {
		.body {
			.notification-templates-container {
				width: 100%;
				padding-bottom: 10px;

				.notification-template-list {
					padding: 6px 12px;
				}

				.sharing-mode {
					padding-bottom: 0;
					margin: 10px 0 0 18px;
					border: none;

					.select-wrapper,
					.title {
						margin: 0;
						display: inline-block;
						border: none;
					}

					.title {
						color: inherit;
						margin: 0 10px 10px 0;
						border-bottom: none;
						font-size: 14px;
					}
				}

				.select-wrapper {
					border-top: 1px solid $border-color;
					.language-select {
						margin: 15px 0 5px 17px;
					}
				}

				.customized-tracking-url {
					padding-bottom: 10px;
					margin: 10px 0 0 18px;

					input {
						margin-left: 10px;
						max-width: 500px;
						border: 1px solid $border-color;

						&:focus {
							border-color: #40a9ff;
						}
					}
				}

				.update-button,
				.delete-button {
					float: right;
					margin: 20px 10px 0 0;
				}
			}
		}
	}
}
