@import '../../../../styles/globals';

.conditional-time-input {
	padding-top: 10px;
	padding-bottom: 15px;

	.time-input-title {
		span {
			margin-right: 2px;
		}
	}

	.route-optimization-time-picker {
		width: 120px;
		height: 32px;
	}
	.route-optimization-radio {
		margin-top: 5px;
		margin-bottom: 4px;
	}

	span {
		font-size: 12px;
		font-weight: 600;
		line-height: 24px;
		text-align: left;
		color: $g-700;
	}

	.beta-option {
		display: inline-block;
		border-radius: 10px;
		background-color: red;
		padding: 2px 4px;
		line-height: 1;
		color: white;
		font-size: 9px;
		transform: translate(5px, -5px);
	}
}
