@import '../../../styles/globals';

.notification-template-preview {
	font-size: 14px;
	font-weight: 400;
	color: $black;
	white-space: pre-wrap;

	.highlight {
		font-weight: 900;
	}

	.preview-message {
		background-color: #0a7cff;
		padding: 5px;
		margin-bottom: 6px;
	}

	.preview {
		color: white;
		padding: 10px;
		background-color: #0a7cff;
		border-radius: 0 10px 10px 10px;
	}

	.messages-counter {
		padding-top: 5px;
		float: right;
	}

	.label {
		display: flex;
		justify-content: flex-start;
		color: white;
		font-size: 13px;
		padding-left: 5px;
	}
}
