.companies-table {
	margin-top: $default-margin;

	.company-dispatcher-table,
	.company-drivers-table {
		margin-top: $default-margin;
		width: 100%;
	}

	.ant-row {
		margin-bottom: $default-margin;
	}
}
