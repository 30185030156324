@import '../../../styles/globals.scss';

.attribute-type.suggested-attribute,
.attribute-data-type .suggested-attribute {
	color: var(--gray-500);
}

.attribute-data-type {
	padding: 4px 0;

	span {
		line-height: 1.67;
		@include ellipsis_text;
		border-radius: 4px;
		border: solid 1px $super-light-blue-2;
		padding: 0 8px;
	}
}

.attribute-actions {
	display: flex;
	justify-content: space-between;

	.ant-btn-link.bringg-btn {
		padding-left: 0;
	}

	&.align-to-right {
		justify-content: flex-end;
	}

	.remove-button {
		color: $light-gray-5;
	}
}

.actions-popover {
	display: flex;
	flex-direction: column;
	font-family: 'Open Sans', sans-serif;

	.bringg-btn {
		text-align: left;

		.bringg-icon {
			color: $gray-3;
		}
		span {
			color: $gray-800;
		}

		&:hover {
			background-color: #eff4f8;

			.bringg-icon,
			span {
				color: inherit;
			}
		}
	}
}

.delete-attribute-modal {
	.ant-modal-title {
		display: flex;
		align-items: center;

		.bringg-icon-info-filled {
			color: $primary;
			margin-right: 10px;
			font-size: 24px;
		}
	}

	.ant-modal-body {
		padding-left: 74px;
	}
}
