@import '../../../styles/globals.scss';

.ticketModalCollapsePanel {
	.panelHeader {
		color: var(--gray-800);
		font-size: 16px;
		font-weight: 600;
	}
	.panelContent {
		padding: 0 16px;

		:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
			border: 1px solid $gray-400;
			box-sizing: border-box;
			border-radius: 4px;
		}

		:global(.bringg-input:disabled) {
			background-color: $disabled-background;
			color: $disabled-input-color;
		}
	}

	:global(.bringg-collapse) {
		padding-top: 0;
	}
}

.formItemLabel {
	label {
		font-size: 12px;
		color: var(--gray-700);
		font-weight: 600;

		:global(.bringg-icon) {
			color: var(--gray-600);
			margin-left: 2px;
		}
	}
}

.labelTooltip {
	font-family: 'Open Sans', sans-serif;
	word-break: break-word;

	:global(.ant-tooltip-content) {
		:global(.ant-tooltip-arrow-content),
		:global(.ant-tooltip-inner),
		:global(.ant-tooltip-arrow-content) {
			background-color: var(--white-bg);
		}

		:global(.ant-tooltip-arrow-content) {
			--antd-arrow-background-color: unset;
		}

		:global(.ant-tooltip-inner) {
			padding: 16px;
			color: var(--gray-700);
		}
	}
}

.collapsePanelHeader {
	color: var(--gray-800);
	font-size: 16px;
	font-weight: 600;
}
