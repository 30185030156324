@import '../../styles/globals.scss';

.planned-routes-overlay {
	max-height: 250px;
	overflow-y: auto;
	border: 1px solid #e1e1e1;
	z-index: 1061;

	.ant-dropdown-menu-root {
		border: none;
	}
}

.run-creator {
	font-size: 15px;
	margin: 15px 0;
	color: var(--color-brand-gray);

	.run-creator-title {
		margin-bottom: 20px;
	}

	.run-creator-subtitle {
		font-size: 13px;
		margin-bottom: 20px;
	}

	.disabled {
		pointer-events: none;
		opacity: 0.6;
	}

	.route-identifier-input {
		cursor: pointer;
	}

	.route-identifier-caret {
		margin-inline-start: 4px;
	}

	.run-creator-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		margin-top: 10px;

		.ant-radio-wrapper {
			font-weight: normal;
			margin: 0;
		}
	}

	.run-creator-info {
		margin-inline-start: 7px;
	}
	.ant-picker {
		border: 1px solid $gray-400;
		border-radius: 4px;
	}
}

.run-editor-start-date-picker-dropdown,
.run-editor-end-date-picker-dropdown {
	z-index: 1060;
}

.run-creator-select {
	.ant-select {
		width: 100%;
	}
}

.run-editor-planned-route-title {
	&.is-disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}
