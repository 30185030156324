@import '../../../styles/globals.scss';

.run-details {
	float: right;
	font-size: $font-medium;
	margin-top: 5px;

	div {
		margin-left: 24px;
		display: inline-block;
	}
	span:last-child {
		font-weight: 600;
	}
}
