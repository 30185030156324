@mixin disabledFontColor {
	color: var(--color-gray6);
}

@mixin hoverFontColor {
	color: var(--primary-450);
}

.icon-button {
	border: none;
	background-color: transparent;
	vertical-align: middle;

	&:disabled {
		@include disabledFontColor;

		.svg-inline--fa {
			@include disabledFontColor;
		}
	}

	&:not(:disabled) {
		&:hover {
			@include hoverFontColor;

			.svg-inline--fa {
				@include hoverFontColor;
			}
		}
	}
}
