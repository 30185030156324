.fleet-tasks-table {
	margin-bottom: 17px;
}

.confirm-assign-btn {
	background: #3091ce;
	&:hover {
		background: var(--primary-500);
	}
}

.cancel-assign-btn {
	color: var(--primary-400);
}

.confirm-assign-modal {
	.ant-modal-confirm-title {
		font-size: 22px;
		font-width: 500;
	}

	.ant-modal-confirm-content {
		font-size: 16px;
	}
}
