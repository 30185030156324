@import '../../../styles/globals';

.service-area-form {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	button {
		min-width: initial;
	}

	.ant-input-borderless {
		border: none;
	}

	.service-area-external-id {
		display: flex;
		font-size: 12px;
		font-weight: 400;

		.external-id-label {
			text-wrap: nowrap;
			line-height: 30px;
			margin-right: 4px;
		}
		.ant-input {
			padding-left: 0;
			font-size: 12px;
		}
	}

	.ant-input-lg {
		font-size: 20px;
		font-weight: 600;
		padding-left: 0;
	}

	.ant-form-item {
		margin: 0;
		width: 100%;
	}

	.flex {
		display: flex;
	}

	.header-info {
		font-size: 20px;
		margin-top: 32px;
	}

	.bringg-icon-close {
		font-size: 18px;
		color: $g-600;
	}

	.info-icon {
		margin-left: 10px;
		cursor: pointer;
	}

	.header-description {
		margin-bottom: 24px;
		font-size: 12px;
	}

	.textarea-input {
		min-height: 100px;
		margin-bottom: 16px;
	}

	.radio-group {
		margin-bottom: 20px;
	}

	.ant-radio-wrapper {
		font-weight: normal;
		font-size: 14px;
	}

	.form-label {
		font-weight: 600;
	}

	.buttons-container {
		margin-bottom: 32px;
	}

	.submit-buttons {
		margin-top: auto;
	}

	.ant-select {
		margin-bottom: 16px;
		width: 100%;
	}
}

.service-area-popover {
	pre {
		white-space: pre-wrap;
		background-color: $gray-50;
		font-size: 12px;
		color: $gray-700;
		border: none;
		border-radius: 4px;
		margin-bottom: 24px;
	}

	.info-header {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 2px;
	}

	.info-description {
		font-size: 12px;
		margin-bottom: 16px;
	}

	.info-label {
		margin-bottom: 2px;
	}
}
