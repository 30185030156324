.templatesHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	width: 100%;

	[class~='filter-bar-2'] {
		width: 100%;
		margin-right: 20px;
	}
}

.templatesTable {
	display: flex;
	// 310px - avg height of tabs
	max-height: calc(100vh - 350px);
	width: 100%;

	[class~='react-table-wrapper'] {
		overflow: auto;
	}
}
