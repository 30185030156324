@import '../../../styles/globals.scss';

.actions-configurations-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 5px;
	padding-top: 10px;

	.add-actions-configuration {
		height: 25px;
		padding: 0 15px;

		&:not([disabled]) {
			background-color: $blue;
			color: $white;
		}
	}

	.action-search-input {
		width: unset;
	}
}
