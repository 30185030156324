@import '../../../styles/globals.scss';

.setting-page-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 22px;
	margin: 0;
	border-bottom: 1px solid var(--gray-400);
	background-color: $white;
	padding: 16px 40px;

	h2 {
		font-size: 22px;
		margin: 0;
		font-weight: 600;
	}
}
