@import '../../styles/globals.scss';

$tag-color: #e1f0fd;

.configurations-audit {
	&-antd-table-overides {
		thead tr th {
			background-color: #f2f4f8;
			cursor: unset;
		}
	}
}
