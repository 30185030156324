.workflowList {
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 16px;

	.workflowGroupHeader {
		height: 24px;
		line-height: 24px;
		padding-inline-start: 16px;
		margin-top: 24px;
		margin-bottom: 4px;
		color: var(--gray-800);
		text-transform: lowercase;
		font-size: 16px;
		font-weight: 500;

		&::first-letter {
			text-transform: uppercase;
		}

		&:first-child {
			margin-top: 8px;
		}
	}
}
