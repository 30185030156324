.skills-page-actions {
	max-width: $max-width;
	display: flex;
	justify-content: space-between;

	.create-skill {
		display: none;
	}

	.skills-search {
		width: 250px;
		padding: 4px;
		margin-top: 6px;
		margin-bottom: 18px;
		font-size: $font-small;
		display: inline-block;

		.input-icon {
			color: $gray3;
		}
	}

	.ant-btn {
		font-size: $font-small;
	}
}
