@import '../../../styles/globals.scss';

.chat-list {
	@import '../chat-list-item/chat-list-item';

	border: 1px solid $border-color;
	background-color: $white;
	height: 100%;
	overflow: scroll;
}
