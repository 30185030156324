@import '../../../styles/globals';

.remove-teams-modal {
	.title {
		.rule-name {
			font-size: 30px;
			margin-right: 8px;
		}

		.rule-type {
			font-size: 16px;
		}
	}

	.teams-list {
		background-color: var(--gray-200);
		max-height: 200px;
		overflow: auto;
		padding: 10px;

		.team-row {
			margin-bottom: 8px;
			display: flex;
			flex-direction: row;
			align-items: center;

			.team-name {
				margin-right: 8px;
			}
		}

		.rule-tag {
			background-color: $indicator-color;
			color: var(--color-brand-gray);
			margin-left: 6px;
			padding: 2px 4px;
			border-radius: 2px;
			font-size: 13px;
		}
	}

	.indicators {
		position: relative;

		.indicator {
			position: absolute;
			width: 14px;
			height: 14px;
			left: 0;
			top: 5px;
			background: $indicator-color;
			border-radius: 2px;
		}

		.indicator-text {
			font-size: 10px;
			margin-left: 20px;
		}
	}
}
