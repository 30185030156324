@import '../../../styles/globals';

.zipcodes-input {
	.service-area-zipcode-duplicated {
		.filter-bar-2-tag {
			background-color: $indicator-color;
			border-color: $warning;
			color: $warning;
		}

		.ant-tag-close-icon {
			color: $warning;
		}
	}
}

.service-area-warning-title {
	color: $warning;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
}
