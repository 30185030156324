.timeoff {
	.add-timeoff-btn {
		display: block;
		margin-top: 10px;
		margin-left: auto;
	}

	.actions-container {
		display: flex;
		justify-content: center;

		.trash-icon {
			color: #ff4422;
		}
	}
}
