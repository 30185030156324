@import '../../styles/globals';

.delivery-provider-assigner {
	min-height: 600px;

	.delivery-provider-assigner-description {
		color: var(--gray-500);
		margin-bottom: 16px;
	}

	.search-row-input {
		margin-bottom: 16px;
		width: 40%;
	}
}
