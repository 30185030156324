.skills-page {
	$max-width: 434px;
	margin-top: 15px;

	@import '../skills-page-actions/skills-page-actions';

	.delete-button {
		color: $red;
		cursor: pointer;
	}
}
