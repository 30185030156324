@import '../../../../styles/globals';

.attribute-list-item {
	word-break: break-all;

	.attribute-list-item-name {
		color: var(--gray-600);
		font-size: $font-xs;
		font-weight: $font-weight-medium;
	}

	.attribute-list-item-value {
		color: var(--gray-800);
	}

	&:first-child {
		margin-top: 10px;
	}
}
