.toolbar {
	padding: 16px 0px;
	display: grid;
	grid-template-columns: 0.5fr 1fr 5fr;

	.weekPickerWrapper {
		position: relative;
		display: flex;
		margin-inline-start: 5px;

		.weekPicker {
			font-size: 14px;
			margin-inline-end: 5px;

			.weekDatePicker {
				width: 280px;
			}
			.weekPickerText {
				position: absolute;
				z-index: 1;
				margin: 6px 0 0 10px;
				pointer-events: none;
			}

			.weekNumber {
				color: var(--gray-800);
				font-weight: 700;
			}

			.weekRangeDates {
				color: var(--gray-600);
			}

			:global {
				.ant-picker-input {
					input {
						visibility: hidden;
					}
				}
			}
		}
	}

	.actions {
		display: flex;
		justify-content: flex-end;

		.calendarViewMode {
			margin: 0 20px;
		}
	}
	.addButton {
		display: flex;
	}
}

.teamSelectionWrapper {
	display: flex;
	align-items: baseline;

	.teamSelect {
		min-width: 150px;
	}
	.teamTimezone {
		margin-inline-start: 10px;
		margin-inline-end: 5px;
	}
}
