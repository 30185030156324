@import '../../styles/globals.scss';

.route-optimization-settings-wrapper {
	font-family: 'Open Sans', 'Rubik', sans-serif;
}

.route-optimization-nav-header-empty {
	background-color: $light-gray-background;
	width: 100%;

	margin-bottom: 16px;
	font-size: 20px;
	padding: 8px 40px;

	.route-optimization-description {
		margin-top: 4px;
		font-size: 12px;
	}
}

.route-optimization-nav-header {
	background-color: $light-gray-background;
	width: 100%;
	height: 56px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	padding: 15px 40px;

	font-size: 20px;
	line-height: 24px;

	& .route-optimization-back-button {
		margin-right: 10px;
		background-color: var(--gray-100);
		font-size: 12px;
		color: $gray-800;
		height: 24px;

		&:hover {
			background-color: darken(#eff4f8, 5%);
			color: $gray-800;
		}
	}
}

.route-optimization-nav-header-back {
	width: 100%;
}

.optimization-configuration-view {
	background-color: $disabled-background;
	padding: 0 !important;
}
