.tabTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 4px;

	:global(.ant-btn) {
		&.removeLanguageBtn {
			opacity: 0.4;
			background-color: transparent;
		}
	}

	&:hover {
		.removeLanguageBtn {
			opacity: 1;
			visibility: visible;
			background-color: transparent;
		}
	}
}
