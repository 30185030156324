@import 'styles/antd.scss';
@import '~@bringg/bringg-icons/dist/bringg-font-icons.css';
@import './styles/globals.scss';

.react-app,
#root,
.react-component {
	@include basic_font_props; // hotfix - this should be set to each feature by the need.
	@import 'features/features';
	.text-align-end {
		text-align: end;
	}
	.text-center {
		text-align: center;
	}
	.text-left {
		text-align: left;
	}
	.text-right {
		text-align: right;
	}

	&.exceptions {
		display: flex;
		height: 100%;
	}

	&.full-height-component {
		height: 100%;
	}

	&.no-side-spacing {
		margin: 0 -15px;
	}
}

// to revert main.css - aligned with antd
.editable-title {
	line-height: 1.5715;

	.editable-input {
		padding: 4px 11px;
		padding-inline-start: 0;
		line-height: 1.5715;
	}

	.ant-input {
		font-family: $font-stack;
		margin: 0;
		line-height: 1.5715;
	}
}

//this is for angularJS dotted border ruining react beautiful css :/
.ant-modal {
	input[type='date'],
	input[type='time'],
	input[type='datetime-local'],
	input[type='month'],
	input[type='text'],
	input[type='number'],
	input[type='email'],
	input[type='phone'],
	input[type='password'],
	input[type='text']:disabled,
	input.text {
		border: 1px solid $gray-400;
		border-radius: 4px;
	}

	input[type='text']:disabled {
		background-color: #f5f5f5;
	}
	.ant-input-affix-wrapper {
		.ant-input {
			border: none;
		}
	}
}
.vehicle-circle {
	font-size: 10px;
	display: inline-block;
	&.available {
		color: #76c258;
	}

	&.unavailable {
		color: red;
	}
}
.mapboxgl-map .mapboxgl-canvas {
	object-fit: cover;
}

.spinner-wrapper.flex-centered {
	display: flex;
	justify-content: center;
	margin-top: 20vh;
}

@import 'components/portals';
@import 'features/portals';
@import 'styles/form';
