@import '../../styles/globals.scss';

.companies-container {
	@include basic_font_props;

	margin-top: 15px;

	.ant-table-small {
		border: none;

		table {
			border: 1px solid $border-color;
		}
	}
}
