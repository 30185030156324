@import '../../styles/globals';
@import 'modal/card/delivery-block-card';
@import 'modal/drivers-panel/delivery-blocks-drivers-panel';
@import 'modal/flex-break-message/flex-break-message';
@import 'modal/form/delivery-block-form';
@import 'modal/delivery-block-modal';
@import 'toolbar/navigator/delivery-blocks-navigator';
@import 'calendar/delivery-blocks-calendar';
@import 'toolbar/delivery-blocks-toolbar';
@import 'timeline-calendar/delivery-blocks-timeline-calendar';
@import '../../styles/globals';
@import 'move-block-confirmation-content/move-block-confirmation-content';
@import 'delivery-block-timline-tooltip/delivery-block-timeline-tooltip';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles.css';

.delivery-blocks-container {
	display: flex;
	flex-direction: column;
	height: calc(100vh - #{$angular-header-height} - 32px);
	padding-top: 30px;

	.delivery-blocks-spinner {
		height: 85%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.delivery-blocks-teams-select {
		width: 240px;
	}

	.selected-team {
		display: flex;
		.selected-team-timezone {
			margin: 8px 0 0 10px;
		}
	}

	.delivery-block-add-block-button {
		margin-inline-start: 10px;
	}

	.delivery-blocks-add-block {
		width: 140px;
		height: 32px;
		font-weight: bold;
	}
}
