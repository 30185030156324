@import '../../styles/globals';

.fleets-table {
	overflow: auto;
	width: 100%;
	border: 1px solid $gray-4;

	// Removing antd selection column (without this the first column will be a radio button)
	.ant-table-selection-col,
	.ant-table-selection-column,
	.ant-table-measure-row > td:first-child {
		display: none;
	}

	.ant-table-row {
		cursor: pointer;
	}

	.fleet-icon-capable {
		font-size: $font-pre-medium;
		color: var(--success-400);
	}
	.fleet-icon-incapable {
		font-size: $font-pre-medium;
		color: var(--gray-600);
	}

	.fleet-eco-icon {
		color: #00983a;
	}

	.center-in-cell {
		display: inline-flex;

		height: 100%;
		width: 100%;

		text-align: center;
		align-items: center;
		justify-content: space-evenly;
	}

	// Because ETA text is added the column width change, so we make sure it won't (most of the time - it depends on the format)
	.eta {
		min-width: 60px;
	}
}
