.dialog {
	:global {
		.ant-modal-content {
			border-radius: 4px;
		}

		.ant-modal-header.ant-modal-header {
			padding: 24px;
		}

		.ant-modal-body.ant-modal-body {
			padding: 24px;
		}

		.ant-modal-footer.ant-modal-footer {
			padding: 24px;
		}
	}
}

.small {
	width: 480px !important;
}

.medium {
	width: 592px !important;
}

.large {
	width: 750px !important;
}

.xlarge {
	width: 960px !important;
}

.xxlarge {
	width: 1100px !important;
}

.title {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}

.subtitle {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}
