.service-plan-form {
	.sub-header {
		color: #718096;
		font-size: 14px;
		font-weight: 300;
	}

	.service-plan-select-input {
		border-radius: 4px;

		.ant-select-selector,
		.ant-select-selector .ant-select-selection-search-input {
			height: 35px;
		}
	}
}

.service-plan-modal-divider {
	border-top: 1px solid #b7b7b7;
	padding-right: 0;
	margin-bottom: 32px;
	margin-top: 8px;
}

.service-plan-cancel-btn {
	float: left;
}

.service-plan-form > .ant-modal-content {
	width: 600px;
}

.service-plan-form > div > div > h2 {
	color: #2d3748;
	font-size: 20px;
}

.service-plan-form > div.ant-modal-content > button > span > span > svg {
	height: 16px;
	width: 16px;
	margin-top: 26px;
}

.service-plan-fields > div {
	color: #4a5568;
	margin-bottom: 24px;

	label {
		font-weight: 400;
	}
}

.service-plan-header {
	margin-top: 20px;
	height: 24px;
	margin-bottom: 16px;
}
