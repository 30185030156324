@import '../../../styles/globals';

.rate-cards-global-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 300px;
	text-align: center;

	h3 {
		white-space: pre;
	}
}

.rate-cards {
	position: relative;
	// Fix margin collapse when read-only message appear
	padding-top: 1px;
	margin-top: -1px;

	.term-header,
	.rate-card-term-rate {
		.editable-block .title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
	}

	&-tab-base,
	&-tab-surcharge {
		position: relative;
	}

	.rate-cards-pending-update {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 20;
		background-color: rgba($white, 0.5);
	}

	.see-rate-summary-button:not([disabled]) {
		color: var(--primary-400);
	}
	.see-rate-summary-button {
		@include button-without-feedback;
		font-size: $font-pre-medium;
		display: flex;
		align-items: center;

		.bringg-icon-chevron-right {
			font-size: 18px;
		}
	}

	.bringg-tabs .rate-cards-content .ant-tabs-content-holder {
		padding: 3px 0 0;
		max-height: initial;
	}

	.rate-cards-content > .ant-tabs-nav {
		padding: 0 24px;
	}

	& .read-only-message ~ .bringg-tabs > .rate-cards-content {
		// 110(connection name) + 85(rates footer) + 108(readonly message bar)
		height: calc(100vh - 312px);
	}

	&-content {
		position: relative;
		padding: 2px 0;
		// 110(connection name) + 58(page header) + 61(main tabs nav) + 85(rates footer)
		// find a better way to solve tabs content scroll + height issue
		height: calc(100vh - 204px);
		overflow-y: auto;

		&.ant-tabs .ant-tabs-tab {
			color: $g-600;
		}
	}

	.add-term-container {
		padding: 16px;
		box-shadow: 0 0 2px 2px var(--gray-500-a16);
	}

	.rate-card-term-wrapper {
		margin: 24px 0;
		box-shadow: 1px 1px 4px 0 $box-shadow-color;
	}

	.rate-card-term-wrapper ~ .add-term-container {
		margin-top: 25px;
	}

	.add-term-button {
		@include button-without-feedback;
		color: var(--primary-400);
		padding: 0;

		&:disabled {
			color: rgba($black, 0.25);
		}
	}

	&-footer {
		box-shadow: 0px -4px 8px rgba(0, 51, 80, 0.08);
		padding: 16px 24px;
		background-color: #ffffff;
		display: flex;
		justify-content: space-between;
		align-items: center;

		label {
			font-weight: normal;
		}

		.discard-button {
			margin-right: 8px;
		}

		.save-button[disabled] {
			cursor: not-allowed;
			pointer-events: none;
		}

		.quotes-wrapper {
			display: flex;
			align-items: center;
		}

		.bringg-icon-help {
			font-size: 24px;
			color: var(--gray-600);
		}
	}

	.read-only-message {
		margin: 24px;
	}
}

.bringg-modal.delete-term-modal {
	.ant-modal-content {
		.ant-modal-header {
			padding-bottom: 16px;
		}
		.ant-modal-body {
			padding-top: 0;
		}
	}
}
