.flex {
	display: flex;
	flex-direction: column;
}
.okButton {
	margin: 24px 4px 0;
	width: 70px;
	align-self: flex-end;
}

.popoverBody {
	margin-top: 16px;
}

.title {
	font-weight: 600;
}

.description {
	color: var(--gray-600);
}
