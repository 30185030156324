.run-container {
	@import '../run-history/run-history';
	@import '../run-details/run-details';
	@import '../run-table/run-table';
	@import '../run-map/run-map';
	@import '../../../styles/globals.scss';
	@include basic_font_props;
	padding: 0 18px;
	.run-data .ant-divider-horizontal {
		margin: 10px 0;
	}
	.run-header {
		margin-bottom: 14px;
		margin-top: 18px;
		display: flex;

		.run-details-wrapper {
			margin-left: auto;
		}
	}
	.run-table-loading {
		min-height: 22vh;
		@include basic_border;
	}
	.run-driver-loading {
		@include basic_border;
		min-height: 13vh;
	}
	.run-history-loading {
		@include basic_border;
		min-height: 68vh;
	}
	.run-details-spinner {
		text-align: right;
	}
	.driver-card-spinner {
		text-align: center;
		margin-top: 30px;
	}
	.run-history-spinner {
		text-align: center;
		margin-top: 100px;
	}
	.run-tasks-spinner {
		text-align: center;
		padding-top: 100px;
	}
	.run-map-spinner {
		text-align: center;
		margin-top: 250px;
	}
	.run-container-map {
		position: relative;
		margin-top: 15px;
		min-height: 635px;
		border: 1px solid $border-color;
	}
}
