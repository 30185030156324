.task-reject-reasons-config {
	display: flex;
	flex-direction: row;
	justify-content: center;
	.task-reject-reasons-config-content {
		width: 70%;
		min-width: 600px;
		max-width: 1100px;
	}
}
