.move-block-confirmation-content {
	.title {
		font-style: normal;
		font-weight: normal;
		font-size: 13.5px;
		color: #000000;
	}

	.drivers-list {
		padding: 24px 0;
		font-size: 13.5px;
		color: #595959;

		.driver-conflict {
			display: flex;
			justify-content: space-between;
			width: 330px;

			.driver-info {
				display: flex;
				align-items: center;

				.dot {
					color: #c4c4c4;
					margin-right: 5px;
				}
			}
		}
	}

	.message {
		font-style: normal;
		font-weight: normal;
		font-size: 13.5px;
		color: #000000;
	}
}
