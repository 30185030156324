.driver-marker-tasks {
	margin-bottom: 0;
	list-style: none;
	padding: 0;
	font-size: 12px;
	color: $black;

	.driver-marker-task {
		padding: 8px 8px 0 8px;
	}
}

.driver-marker-separator {
	widht: 100%;
	height: 1px;
	background-color: $gray;
}
