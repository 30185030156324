@import '../../../../styles/globals';

.workflowsByView {
	margin: 24px 0;

	:global(.bringg-collapse) {
		padding: 8px 0;
		background: var(--white-bg);
		border-radius: 8px;
		box-shadow: 0 1px 2px rgba(0, 51, 80, 0.16);

		:global(.ant-collapse) {
			border-radius: 8px;

			:global(.ant-collapse-header) {
				background: inherit !important;
				display: flex;
				align-items: center;
			}
		}
	}

	.collapseHeader {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 16px;

		.collapseHeaderTitle {
			margin-right: 8px;
			font-size: 16px;
		}
	}

	.collapseBody {
		padding: 16px;
	}

	.description {
		font-size: 14px;
	}

	.goToButton {
		padding: 0 4px;
	}

	.emptyState {
		text-align: center;
		display: flex;
		flex-direction: column;
		font-size: 14px;
		white-space: pre-line;
	}
}
