@import '../../../styles/globals.scss';
.run-history {
	border: 1px solid $border-color;
	padding: $padding;
	min-height: 70vh;
	background-color: $white-background-color;
	@import './run-history-filter/run-history-filter.scss';
	@import './run-history-list/run-history-list.scss';
	@import './run-history-row/run-history-row.scss';
}
