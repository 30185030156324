.titleWithIcon {
	line-height: 24px;
	display: flex;
	align-items: center;

	.title {
		font-size: 18px;
		color: var(--gray-800);
		font-weight: 600;
		margin-inline-start: 12px;
	}

	.icon {
		font-size: 22px;
		color: var(--gray-600);
	}
}
