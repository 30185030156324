.companies-header {
	margin-bottom: 15px;
	text-align: right;
}

.create-company-modal {
	.status-icon {
		right: 10px;
		bottom: 3px;
	}
}

.company-address-input {
	width: 100%;
}
