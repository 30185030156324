.spinner {
	position: absolute;
	z-index: 5;
	top: 50%;
	right: 50%;
}
.disabledMode {
	opacity: 0.5;
	pointer-events: none;
}
