.assign-driver-select-item {
	.assign-driver-select-item-disabled {
		color: gray;
		cursor: not-allowed;
	}

	.assign-driver-select-item-text {
		margin-inline-start: 4px;
	}

	.assign-driver-select-item-never-logged-in {
		color: black;
	}

	.available_inventories_icon {
		margin-inline-start: 5px;
		color: var(--primary-400);
	}
}

.one-line {
	width: 100%;
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;

	&:not(:hover) {
		text-overflow: ellipsis;
	}

	&:hover,
	&:focus {
		.text-container {
			display: inline-block;
			animation-name: scroll-text;
			animation-duration: 7s;
			animation-timing-function: linear;
			animation-delay: 0s;
			animation-iteration-count: infinite;
			animation-direction: normal;
			color: white;
		}
	}

	@keyframes scroll-text {
		0% {
			transform: translateX(0%);
		}
		90% {
			transform: translateX(-100%);
		}
		95% {
			transform: translateX(0%);
		}
		100% {
			transform: translateX(0%);
		}
	}
}

.circle-container {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.text-container {
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
}
