@import '../../../styles/globals';

.notification-template {
	display: block;
	color: $primary-color;
	@include basic_font_props;

	.edit-area {
		@include disable_ant_outline;
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		.input {
			width: 48%;
			resize: none;
		}

		.preview-component {
			width: 48%;
			@include break_line;
		}
	}
}
