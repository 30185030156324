.multipleEditHeader {
	display: flex;
	flex-direction: column;

	.firstLine {
		display: flex;
		align-items: baseline;

		.lengthDeliverySlots {
			font-size: 20px;
			font-weight: 600;
		}

		.basedOn {
			margin-inline-start: 8px;
			font-size: 12px;
		}
	}

	.secondLine {
		font-size: 12px;

		.time {
			margin-inline-start: 8px;
		}

		.effectiveDatesValue,
		.timeValue {
			margin-inline-start: 2px;
			font-weight: 600;
		}
	}
}
