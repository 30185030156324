.sso-configuration-form {
	margin-top: 30px;

	.sso-configuration-container,
	.related-links-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0 20px;
		gap: 8px;
	}

	.sso-configuration-container {
		div {
			.indexed-label {
				background: inherit;
				color: #a0aec0;
				border: solid 1px #a0aec0;
			}
		}

		.sub-header {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #4a5568;
		}

		.steps {
			div {
				margin: 6px 0;
			}

			.step-header {
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.71;
				letter-spacing: normal;
				text-align: left;
				color: #1e2634;
				margin: auto 16px;

				Link {
					font-size: 14px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.71;
					letter-spacing: normal;
				}
			}
		}

		div.float-right {
			button {
				margin: 0 10px;

				span {
					margin-inline-start: 0;
				}
			}
		}
	}

	.form-header {
		border-bottom: inherit;
		padding: 0;

		h3 {
			flex-grow: 0;
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: -0.32px;
			color: #1e2634;
			margin-top: 0;
		}
	}

	.first-step,
	.second-step,
	.helpful-pane {
		display: flex;
		flex-direction: row;
	}

	.related-links-container {
		display: flex;
		flex-direction: column;
		max-width: 250px;

		button {
			margin: 0;
			padding: 0;
			height: auto;
		}

		.convert-cert-to-fingerprint {
			margin-top: 8px;
		}
	}

	.delete-configuration-button {
		padding-left: 5px;
		color: #ff4d4f;
	}

	.delete-configuration-button:hover {
		color: #ff4d4f;
	}

	.float-right {
		float: right;
	}

	.drop-border {
		border: none;
		background: inherit;
		box-shadow: none;
	}

	.light-button {
		color: #3091ce;
		font-size: 14px;
		font-weight: 500;
		background: inherit;
		padding-right: 9px;
	}

	.ant-form-item-label {
		label {
			flex-grow: 0;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #1e2634;
		}
	}

	.panels-divider {
		border-left: 2px solid rgba(0, 0, 0, 0.06);
		color: #e1e9f0;
		height: 100%;
		margin: 0 4px;
	}

	span.copy-to-clipboard {
		background: inherit;
		padding: 0;
	}

	div > span.copy-to-clipboard > span.copy-button {
		margin-right: 0;
	}

	.external-link {
		font-size: 24px;
		position: relative;
		top: 5px;
	}

	.saml-url-input.valid {
		.bringg-icon-selected {
			font-size: 8px;
			padding: 0 4px;
			font-weight: bold;
		}

		.ant-input-suffix {
			height: 16px;
			margin-top: 3px;
			background: var(--success-50);
			color: var(--success-400);
			border-radius: 100%;
		}
	}

	.ant-input-affix-wrapper-status-success {
		border-color: var(--success-400);
	}
}
