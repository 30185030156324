.exclusionWindowsView {
	background: #fff;
	height: 95vh;
	display: flex;
	flex-direction: column;
	align-items: center;

	.allSchedulesToolbar,
	.exclusionWindowsTable {
		width: 80%;
	}
	.exclusionWindowsTable {
		height: 100%;
	}
}
