@import '../../../../styles/globals.scss';

.attribute-definition {
	padding-top: 8px;

	&.ant-modal {
		.ant-input-affix-wrapper-disabled {
			input[type='text'] {
				border: none;
			}
		}

		.ant-form-item-has-error .ant-input,
		.ant-form-item-has-error .ant-input-affix-wrapper,
		.ant-form-item-has-error .ant-input-affix-wrapper:hover,
		.ant-form-item-has-error .ant-input:hover {
			border-color: $red-danger;
		}
	}

	&-input-label {
		@at-root label#{&} {
			font-size: 12px;
			color: $g-600;
			margin-bottom: 4px;
			font-weight: $font-weight-semi-bold;
		}

		&.required {
			&::before {
				margin: 0 2px;
				content: '*';
				color: $light-red;
			}
		}
	}

	.ant-input-wrapper.ant-input-group {
		border-color: var(--success-100);

		.ant-input-group-addon {
			background-color: var(--primary-10);
			color: $gray-800;
		}
	}

	.bringg-icon-suffix {
		display: none;
	}

	.ant-input-clear-icon {
		font-size: 16px;
	}

	.ant-form-item.ant-form-item-has-success {
		.bringg-icon-suffix {
			display: initial;
		}

		.bringg-icon-close-circle {
			display: none;
		}

		.bringg-input .ant-input-affix-wrapper,
		.bringg-input.ant-input-affix-wrapper {
			border-color: var(--success-100);

			.bringg-icon {
				color: var(--success-100);
			}
		}

		&:hover {
			.bringg-icon-suffix {
				display: none;
			}

			.bringg-icon-close-circle {
				display: initial;
			}
		}
	}

	&-header {
		display: flex;
		flex-direction: column;
		font-weight: normal;

		&-title {
			color: $g-700;
			font-size: 20px;
			font-weight: $font-weight-semi-bold;
		}

		&-subtitle {
			color: $g-600;
			font-size: 14px;

			.attribute-name {
				font-weight: $font-weight-medium;
			}
		}
	}

	&.bringg-modal .ant-modal-footer {
		justify-content: space-between;
		align-items: center;
		padding: 16px 24px 24px 16px;

		.bringg-checkbox {
			padding-left: 0;
			font-weight: $font-weight-semi-bold;

			span:nth-child(2) {
				color: $g-700;
			}
		}

		.modal-actions {
			display: flex;
			justify-content: end;
			align-items: center;
			margin-right: 16px;
		}
	}

	.ant-row.ant-form-item {
		margin-bottom: 12px;
	}

	.bringg-icon.bringg-icon-info {
		margin: 0 4px;
	}

	.enum-definition {
		margin-bottom: 16px;
	}
}

.checkbox-element {
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
	margin-left: 8px;
}

.show-on-mobile-checkbox {
	margin-left: 8px;
	margin-bottom: 8px;
}

.checkbox-mandatory {
	display: flex;
	margin-left: 16px;
}

.bringg-tooltip-overlay.attribute-input-tooltip {
	font-family: 'Open Sans', sans-serif;
	word-break: break-word;

	.ant-tooltip-content {
		.ant-tooltip-arrow-content,
		.ant-tooltip-inner,
		.ant-tooltip-arrow-content {
			background-color: $white;
		}

		.ant-tooltip-arrow-content {
			--antd-arrow-background-color: unset;
		}

		.ant-tooltip-inner {
			padding: 16px;
			color: $g-700;
		}
	}

	&.data-type-examples {
		max-width: unset;

		p,
		pre {
			font-size: 12px;
		}

		p {
			margin-bottom: 6px;
		}
	}
}
