@import '../../../../styles/globals';

.delivery-blocks-scheduler-navigator {
	display: flex;
	$height: 32px;
	$padding: 11px;
	height: $height;
	width: 240px;
	border-radius: 4px;
	justify-content: center;
	align-items: center;

	.current-week {
		position: relative;
		height: 100%;
		width: 160px;
		text-align: center;

		.week-text,
		.day-text {
			pointer-events: none;
			position: absolute;
			top: calc($height / 2);
			transform: translateY(-50%);
			left: $padding;
			z-index: 1;
		}
	}

	.delivery-blocks-time-picker {
		display: block;
		cursor: text;

		.ant-picker-input {
			input {
				visibility: hidden;
			}
		}
	}

	.angles {
		width: 40px;
		height: 100%;
		border: 1px solid $light-gray-2;
		display: flex;
		align-items: center;
	}

	.toolbar-navigation-button-left {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: none;
	}

	.toolbar-navigation-button-right {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-left: none;
	}
	.toolbar-chevron {
		color: var(--gray-600);
		&:hover {
			color: var(--gray-800);
		}
	}
}
