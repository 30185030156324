.cluster-item-component {
	&.cluster-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 30px;
	}

	.editable-false {
		opacity: 0.5;
	}
}

// global:

// little hack for hiding cluster's dot-line. why? - because vis.js has bug when cluster.showStipes = false vis.js throw exceptions
.vis-cluster-dot,
.vis-cluster-line {
	display: none !important;
}

.vis-item.vis-cluster {
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-flex;

	.vis-item-content {
		justify-content: center;
	}
}
