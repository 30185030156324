.eventContentItem {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 8px 8px 4px;

	.title {
		font-weight: bold;
	}

	.cutoff {
		margin: 8px 8px 4px;
	}
}
