.modalHeader {
	font-size: 20px;

	.infoIcon {
		color: var(--primary-400);
	}
	.warningIcon {
		color: var(--danger-500);
	}
	.title {
		margin-inline-start: 14px;
		color: var(--gray-800);
		font-weight: 600;
	}
}

.modalBody {
	font-size: 14px;
	padding-left: 20px;

	.title {
		color: var(--gray-700);
	}
	.radioOptions {
		padding-left: 8px;
		margin-top: 10px;
		.radioGroup {
			display: flex;
			flex-direction: column;
			.radioOption {
				font-weight: 400;
				color: var(--gray-800);
			}
		}
	}
}
