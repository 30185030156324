@import './available-fleets/available-fleets-form/availavle-fleets-form';
@import '../../styles/globals';
@import '~@bringg/react-pages/dist/components/components.css';

.fleet-router {
	@include basic_font_props;
	height: 100vh;
	background-color: $very-light-gray-background;

	.fleet-router-title {
		font-weight: 500;
		font-size: $font-large;
		padding-bottom: 30px;
	}
}
