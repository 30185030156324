.titleMainContainer {
	.titleContainer {
		display: flex;
		flex-direction: column;

		.deliveryBlockTemplateTitleStar {
			color: var(--danger-600);
		}

		.deliveryBlockTemplateTitle {
			font-size: 20px;
			color: var(--gray-500);
			margin-left: 4px;
		}

		.deliveryBlockTemplateDescription {
			font-size: 12px;
			color: var(--gray-500);
		}

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&.disabled {
		cursor: not-allowed;
	}
}
