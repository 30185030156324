@import '~@bringg/react-components/dist/styles/css-styles/colors.css';

$font-stack: 'Rubik', sans-serif;
$font-large: 24px;
$font-big-2: 20px;
$font-big: 17px;
$font-medium: 15px;
$font-pre-medium: 14px;
$font-small: 13px;
$font-xs: 12px;
$angular-header-height: 66px;
$primary-color: #333;
$second-color: #448fff;
$border-color: #e8e8e8;
$white-background-color: var(--white-bg);
$white: white;
$red: red;
$light-red: #ff6a4d;
$bringg-background: #efefef;
$bringg-orange: #ff4e26;
$light-red-2: #d9534f;
$red-danger: #ff4d4f;
$offline-color: #a5a5a5;
$gray: #737373;
$gray3: #929292;
$gray-50: #f8fafb;
$gray-700: #4a5568;
$disabled-background: #f5f5f5;
$disabled-input-color: rgba(0, 0, 0, 0.25);
$background-secondary-color: #fafafa;
$light-gray-background: #ffffff;
$table-header-background-color: #efefef;
$ok-color: #68c205;
$padding: 15px;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-small: 300;
$delivery-blocks-warning: #ff6a4d;
$primary: #3091ce;
$super-light-blue-1: #f1f5f9;
$super-light-blue-2: #dee5ed;
$blue: #598ad9;
$light-blue: #4a90e2;
$darker-blue-border: #4a90e2;
$blue-bright: #008bff;
$light-gray: #e5e5e5;
$light-gray-2: #d6d6d6;
$light-gray-3: #888888;
$light-gray-4: #a0aec0;
$light-gray-5: #aeb7c4;
$gray: #9b9b9b;
$gray-2: #e4e4e4;
$gray-3: #e5e5e5;
$gray-3: #718097;
$gray-5: #cbd5e0;
$create-button-color: #65acfe;
$black: #000;
$crew-cta-color: #598ad9;
$crew-cta-danger-color: #ff1d1f;
$crew-cta-bgc-color: #2e6da4;
$default-font: Rubik;
$dark: #4a4a4a;
$darker-white: #ececec;
$background-icon-color: #d8d8d8;
$default-margin: 15px;
$very-light-gray-background: #f6f8fb;
$active-color: #35cd7c;
$dark-gray: #494949;
$warning-background: #ffc0c0;
$warning-text: #b61010;
$warning-sign: #ffb400;
$indicator-color: #ffeeb3;
$icon-button-color: #90a1b5;
$gray-8: #3b3f49;
$gray-4: #e9edf3;
$gray-400: #cdd6e4;
$secondary-text: #5b6771;
$box-shadow-color: #90a1b529;
$box-shadow-dark-color: #28293d;
$badge-background: #1890ff40;
$title-border-color: #dbe1eb;
$gray-200: #e8ecf3;
$gray-800: #2d3748;
$warning: #ff7001;
$danger-500: #f98560;
$danger-600: #d93a17;
$success-500: #00c364;
$success-50: #e5f8ec;
$emphasized: #eff4f8;

// https://app.zeplin.io/styleguide/61b1c84607482e4a6ebd3161/components?coid=620216468d46b6af06516f2f
$p-50: #e1f0fd;
$p-400: #3091ce;
$p-500: #067eba;
$g-600: #718096;
$g-700: #4a5568;
$g-200: #e1e9f0;

// Add to RC palette or replace with existing colors
:root {
	// colors are missing in react-components palette
	--color-free: #76c258;
	--color-busy: #658cd2;

	// move to danger palette section
	--color-alert: #e65963;
	--color-alert-light: #dfa9ad;

	// present in scss rc vars
	--primary-450: #3d8deb;
	--color-brand-gray: #5b6771;
	--color-n500: #42526e;
	--color-gray6: #c4c4c4;

	--navbar-left-side-width: 190px;
	--merchant-navigation-container-width: 264px;
	--Color-Text-Subtle: #718096;
	--Color-Neutrals-600: #718096;
	--Color-Neutrals-400: #cbd5e0;
	--Color-Primary-400: #3091ce;
	--Color-Neutrals-700: #4a5568;
	--Color-Success-500: #00c364;
	--Color-Text-Secondary: #4a5568;
}

@mixin basic_font_props() {
	font-family: $font-stack;
	-webkit-font-smoothing: antialiased;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

@mixin basic_border() {
	border: 1px solid $border-color;
}

@mixin break_line() {
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
}

@mixin disable_ant_outline {
	.ant-input:focus {
		outline: none;
		box-shadow: none;
		-webkit-box-shadow: none !important;
	}
}

@mixin legacy_button {
	color: white;
	font-weight: 500;
	background: $blue;
	border-color: $darker-blue-border;
}

@mixin ellipsis_text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin max-width {
	width: 100%;
}

@mixin delivery-block-modal-column {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0 15px;
}

@mixin row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

@mixin column {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@mixin button-without-feedback {
	background: transparent;
	transition: none;
	border: none;
}

.bringg-tooltip-overlay.white-body {
	.ant-tooltip-content {
		.ant-tooltip-arrow-content,
		.ant-tooltip-inner,
		.ant-tooltip-arrow-content {
			background-color: $white;
		}

		.ant-tooltip-arrow-content {
			--antd-arrow-background-color: unset;
		}

		.ant-tooltip-inner {
			padding: 16px;
			color: var(--gray-700);
		}
	}
}

.bringg-tooltip-overlay.break-text {
	font-family: 'Open Sans', sans-serif;
	word-break: break-word;
}

.bringg-vertical-divider {
	width: 2px;
	height: 32px;
	background-color: var(--gray-150);
	border-radius: 1px;
	margin: 0 8px;
}
