@import '../../../styles/globals';
@import 'recurring-options-modal/recurring-options-modal';

.delivery-block-modal {
	@include basic_font_props;

	.delivery-block-form,
	.delivery-block-card {
		width: 55%;
	}

	.delivery-blocks-drivers-panel {
		width: 45%;
	}

	input {
		color: $black;
	}

	.ant-modal-title {
		color: $gray;
	}

	.ant-modal-content {
		width: 705px;
	}

	.ant-modal-body {
		padding: 11px 0 0 0;
	}

	.delete-button {
		float: left;
	}

	.ant-btn-danger {
		background-color: $white;
		color: $red-danger;

		&:hover,
		&:focus {
			background-color: $red-danger;
			color: $white;
		}
	}

	.delivery-block-modal-container {
		display: flex;
		height: 60vh;
	}

	.ant-picker {
		width: 100%;

		input {
			margin-bottom: 0px;
		}
	}

	.ant-input {
		height: 32px;
	}

	h4 {
		margin: 0;
	}
}

.delivery-block-modal-with-break {
	.ant-modal-content {
		width: 1100px;
	}

	.delivery-block-card {
		width: 36%;
	}

	.delivery-block-form {
		width: 68%;

		.delivery-block-form-left-col {
			width: 53%;
		}
		.delivery-block-form-right-col {
			width: 47%;
		}
	}

	.delivery-blocks-drivers-panel {
		width: 32%;
	}

	.breaks-card {
		width: 32%;
	}
}
