@import '../../../../styles/globals.scss';
.history-row {
	border-bottom: 1px solid $border-color;
	.history-title {
		font-size: $font-small;
		font-weight: $font-weight-medium;
		color: $second-color;
		text-transform: uppercase;
	}
	.history-date {
		font-size: $font-small;
		color: $primary-color;
		padding-right: 25px;
	}
	.history-text {
		padding-top: 6px;
		font-size: $font-small;
		color: $primary-color;
	}
	.run-history-hour {
		color: $primary-color;
		font-weight: $font-weight-bold;
	}
}
