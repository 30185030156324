.timeSlots {
	.timeSlotsBody {
		padding: 8px 32px;
	}

	.timeSlotsDescription {
		display: flex;
		flex-direction: column;

		.descriptionText {
			font-size: 12px;
			max-width: 680px;
			margin: 0;
		}
	}

	.effectiveDateRangePicker {
		display: flex;
		flex-direction: column;
		margin: 20px 0;
		.label {
			font-size: 12px;
			color: var(--gray-800);
			font-weight: 600;
		}
	}

	.daysInWeek {
		display: flex;
		flex-direction: column;

		.title {
			font-weight: 600;
			font-size: 14px;
			margin-bottom: 16px;
		}
	}
}
