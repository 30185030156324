.submitTicketDrawer {
	transition-duration: 0.3s;
	transition-property: width, right, top, bottom, font-size, padding, margin, background-color, border-radius,
		border-bottom;
	transition-timing-function: ease-in-out;

	// there is no tother way to override this class without pointing to antd class
	:global(.ant-drawer-body) {
		padding: 0;
	}

	&.collapsed {
		// there is no tother way to override this class without pointing to antd class
		:global(.ant-drawer-content-wrapper) {
			width: 240px !important; // overriding inline styles
			bottom: 0;
			top: calc(100vh - 40px);
			min-height: 40px;
			right: 16px;

			.submitTicket {
				.modalContainer {
					background-color: var(--gray-50);
					border-radius: 8px 8px 0px 0px;

					.modalHeader {
						padding: 8px;
						margin: 0;
						border-bottom: none;

						.title {
							.text {
								p {
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
	}

	.submitTicket {
		font-family: Open Sans;
		width: 100%;
		height: 100%;

		.modalContainer {
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: column;
			background-color: var(--white-bg);
			border-radius: 0;
			box-shadow: 0px -4px 24px 0px rgba(0, 51, 80, 0.16), 0px -2px 8px 0px rgba(0, 51, 80, 0.04);

			.modalHeader {
				display: flex;
				transition-delay: 0.1s;
				padding-bottom: 8px;
				margin: 16px 16px 0;
				border-bottom: 1px solid var(--gray-150);

				.title {
					display: flex;
					flex-direction: column;
					margin-right: auto;

					.text {
						display: flex;
						align-items: center;
						font-weight: 600;

						p {
							color: var(--gray-800);
							text-align: center;
							font-size: 20px;
							font-weight: 600;
							margin-right: 8px;
							margin-bottom: 0;
						}

						.helpBadge {
							color: var(--white-text);
							background-color: var(--brand-red);
							font-size: 12px;
							padding: 2px 4px;
						}
					}

					a {
						font-size: 12px;
					}
				}

				.headerAction {
					display: flex;

					.headerButton {
						display: inline-flex;
						align-items: center;
						color: var(--gray-600);
						height: 24px;
						padding: 0;

						:global(.bringg-icon) {
							font-size: 24px;
						}
					}
				}
			}

			.modalBody {
				display: flex;
				flex-direction: column;
				position: relative;
				flex: 1;

				.ticketFormContainer {
					padding-bottom: 16px;
					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: 0px;
					overflow: auto;
				}

				.loaderContainer {
					position: absolute;
					top: 0;
					bottom: 0;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 10;
					background: rgba(0, 0, 0, 0.1);
				}

				.footer {
					display: flex;
					justify-content: flex-end;
					padding: 16px;
					background-color: var(--white-bg);
					box-shadow: 0px -4px 38px 0px rgba(0, 51, 80, 0.16), 0px 0px 1px 0px rgba(0, 51, 80, 0.08);
				}
			}
		}
	}
}
