@import '../../styles/globals';

.language-select-option {
	display: flex;
	justify-content: space-between;
	width: 165px;
	height: 23px;
	line-height: 23px;

	[ant-click-animating-without-extra-node]:after {
		//remove antd button animation
		animation: 0s !important;
	}

	&.select-bold {
		font-weight: bold;
	}

	&.select-weak {
		font-style: italic;
		color: #9b9b9b;
		padding-left: 15px;
		text-align: center;
	}
}

.ant-select-selection-selected-value {
	.language-select-option {
		&.select-bold,
		&.select-weak {
			font-weight: normal;
			font-style: normal;
			color: inherit;
			padding-left: 0;
		}
	}
}
