@import '../../../../styles/globals.scss';

.route-optimization-input-container {
	.input-title {
		margin-left: 4px;
		margin-right: 2px;
		color: var(--gray-700);
	}

	.route-optimization-input-error-icon {
		color: $red-danger;
		margin-right: 4px;
	}
	.route-optimization-input-error-text {
		color: $red-danger;
	}
}

.route-optimization-input {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	width: 200px;
	height: 32px;
	background: $light-gray-background;
	border: 1px solid $gray-200;
	border-radius: 4px;
	flex: none;
	order: 1;
	flex-grow: 0;
}
