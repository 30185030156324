.blocked-rollout-modal-body {
	.blocked-rollout-content {
		padding: 32px 64px;
		display: grid;
		gap: 16px;
		justify-content: center;

		.blocked-rollout-title {
			text-align: center;
			font-size: 32px;
			color: black;
			font-weight: 500;
		}
		.blocked-rollout-description {
			text-align: center;
			font-size: 13.5px;
			font-weight: 400;
			color: #828282;
		}
		.blocked-rollout-modal-image {
			width: 253px;
			height: 183px;
			margin: 0 auto;
		}
	}
	.blocked-rollout-buttons-bar {
		background: #f6f6f6;
		height: 64px;
		display: grid;
		place-items: center;

		.confirm-button {
			width: 131px;
			height: 32px;
			background-color: #65acfe;
			border-radius: 4px;
			font-size: 14px;
			font-weight: 500;
			color: white;
			display: grid;
			place-items: center;
		}
	}
}
