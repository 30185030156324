@import '../../../styles/globals';

.crews-card-container {
	@include basic_font_props;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
	border: solid 1px #e0e0e0;
	background-color: #ffffff;
	padding: 30px 15px 25px 15px;

	.card-input-widths {
		.ant-select {
			width: 90%;
		}
	}

	.crews-card-id {
		font-weight: bold;
		margin-right: 10px;
		display: inline-block;

		.crew-card-id-text {
			margin-left: 5px;
			color: #939393;
		}
	}

	.crews-card-separator {
		margin-right: 10px;
	}

	.crews-primary-driver {
		position: relative;
	}

	.crews-title {
		position: absolute;
		top: -15px;
		left: 0;
		color: $crew-cta-color;
		font-size: 12px;
		width: 120px;
	}

	.crews-secondary-drivers {
	}

	.card-delete-btn {
		margin-left: 5px;
		background-color: $crew-cta-danger-color;
		border: none;
		color: white;

		&:hover {
			background-color: lighten($crew-cta-danger-color, 10);
		}
	}

	.card-cancel-btn {
		margin-left: 5px;
	}

	.card-apply-btn,
	.card-edit-btn {
		background-color: $crew-cta-color;
		border: none;
		color: white;

		&:hover {
			background-color: lighten($crew-cta-color, 10);
		}
	}

	.card-buttons {
		float: right;
	}
}
