.customDropdownSelect {
	display: flex;
	flex-direction: column;

	.label {
		font-size: 12px;
		color: var(--gray-800);
		font-weight: 600;
	}

	.tag {
		position: relative;
		box-sizing: border-box;
		max-width: 100%;
		height: 24px;
		margin: 2px 0;
		line-height: 22px;
		border: 1px solid #f5f5f5;
		margin-inline-end: 4px;
		padding-inline-start: 8px;
		padding-inline-end: 4px;
		background-color: var(--gray-100);
		color: var(--gray-800);
		font-size: 12px;
		border-radius: var(--radius-sl);
		display: flex;
		align-items: center;
	}
	.tagCount {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		color: var(--gray-700);
		font-size: 10px;
		background: var(--gray-300);
		margin-inline-end: 4px;
		font-weight: 600;
	}

	.treeSelect {
		width: 300px;
	}
}

.customCheckbox {
	display: grid;
	grid-template-columns: 16px auto;
	grid-template-rows: 0;

	.customCheckboxData {
		display: flex;
		justify-content: space-between;
	}
}
